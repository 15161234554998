import React from "react";
import CustomHeadingOne from "../custom-heading-one/CustomHeadingOne";
import { Container, Row, Col } from "react-bootstrap";
import collectionOne from "../../../assets/images/10.png";
import collectionTwo from "../../../assets/images/20.png";
import collectionThree from "../../../assets/images/30.png";
import collectionFour from "../../../assets/images/40.png";
import "../how-it-works/howitworks.css";

function HowItWorks(props) {
  const works = [
    {
      id: 1,
      title: "Connect Your Wallet",
      paragraph:
        "Register your MutoPlace account by connecting your selected wallet from the list of crypto wallet providers to hold your NFTs.",
      image: collectionOne,
    },
    {
      id: 2,
      title: "Setup Your Store",
      paragraph:
        "Open a fully customized store at MutoPlace and upload your work to build the portfolio of masterpieces you want to exhibit and sell for profit.",
      image: collectionTwo,
    },
    {
      id: 3,
      title: "List Your NFTs",
      paragraph:
        "To start minting, enlist the assets you want to promote and sell. Likewise, specify the payment tokens you wish to receive and set fees.",
      image: collectionThree,
    },
    {
      id: 4,
      title: "Put NFTs for Sale",
      paragraph:
        "Start earning by putting your exclusive NFT collections for sale on MutoPlace. You can also bid on appreciative works of the art auction.",
      image: collectionFour,
    },
  ];

  return (
    <>
      <CustomHeadingOne heading="How it works">
      How to store, mint, and trade your NFTs on MutoPlace?   
      </CustomHeadingOne>
      <section className="how-it-works">
        <Container fluid className="create-container ">
          <Row>
            {works.map((i, index) => (
              <Col key={index} xl={3} md={6}>
                <div className="setup-card">
                  <div>
                    <img
                      src={i.image}
                      className="card-img img-fluid"
                      alt="card"
                    />
                  </div>
                  <h2>{i.title}</h2>
                  <p>{i.paragraph}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HowItWorks;
