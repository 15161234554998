/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFollowers, getFollowings, beforeUser, beforeFollower, beforeFollowing } from './../user/user.action';
import { ENV } from './../../config/config';
const { userDefaultImg } = ENV

const Follow = (props) => {
    const [listFor, setListFor] = useState('')
    const [pagination, setPagination] = useState(null)
    const [artists, setArtists] = useState([])
    const [loadMoreBtn, setLoadMoreBtn] = useState(false)

    useEffect(() => {
        window.scroll(0, 0)
        if (props.setLoader)
            props.setLoader(true)

        getList(props.creatorId)

        setListFor(props.action)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.users.followersAuth || props.users.followingsAuth) {

            if (props.action === 'followers') {
                setArtists(props.followers)
                setPagination(props.pagination)
                props.beforeFollowing()
            }

            if (props.action === 'followings') {
                setArtists(props.followings)
                setPagination(props.pagination)
                props.beforeFollower()
            }

            props.beforeUser()

            if (props.setLoader)
                props.setLoader(false)

        }

    }, [props.users.followersAuth, props.users.followingsAuth])

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((artists && pagination.total > 0 && pagination.total > artists.length && artists.length > 0))
    }, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        const { page } = pagination
        if (props.setLoader)
            props.setLoader(false)

        // get more creators
        if (listFor === 'followers')
            getList(props.creatorId, page + 1)

        if (listFor === 'followings')
            getList(props.creatorId, page + 1)
    }

    const getList = (creatorId, page = 1, limit = 12) => {
        let creatorQS = { page, limit }
        const qs = ENV.objectToQueryString(creatorQS)

        if (props.action === 'followers') {
            props.getFollowers(creatorId, qs)

        }
        if (props.action === 'followings') {
            props.getFollowings(creatorId, qs)
        }

    }


    return (
        <section className="popular-collections-area">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">

                    </div>
                </div>
                <div className="row items ">
                    {
                        artists && artists.length ?
                            artists.map((author, key) => {
                                return (
                                    <div key={`author_${key}`} className=" d-flex align-items-center justify-content-sm-between  item">
                                        <div className="card text-center following-card">
                                            <div className="image-over">
                                                <Link to={`/artist/${author.username}`}>
                                                    <img className="card-img-top" src={author.profileImage ? author.profileImage : userDefaultImg} alt="Author profile" />
                                                </Link>
                                                <div className='overlay'>
                                                    <Link to={`/artist/${author.username}`}>
                                                        <h5>{author.username ? author.username : author.address} </h5>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                            :
                            <div className="no-data border"><p className="text-center">No {props.action.charAt(0).toUpperCase() + props.action.slice(1)} Found</p></div>
                    }
                </div>
                {
                    loadMoreBtn &&
                    <div className="row">
                        <div className="col-12 text-center">
                            <a id="load-btn" className="btn btn-danger offer mt-5" onClick={() => loadMore()}>Load More</a>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}

const mapStateToProps = state => ({
    followers: state.user.followers,
    followings: state.user.followings,
    users: state.user,
    totalRecords: state.user.count,
    pagination: state.user.pagination
});

export default connect(mapStateToProps, { beforeUser, getFollowers, getFollowings, beforeFollower, beforeFollowing })(Follow);