import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
import moment from 'moment';
import checked from '../../assets/images/yellow-checked.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tikIcon from '../../assets/images/tik.png';
import { useWeb3React } from '@web3-react/core';
import { ENV } from '../../config/config'

import Ethereum from '../../assets/images/arow-icon.png';
import Binance from '../../assets/images/binancelogo.png';
import Fantom from '../../assets/images/fantomlogo.png';
import Cronos from '../../assets/images/cronoslogo.png';



import {
    faTwitter as twitter, faInstagram as instagram, faDiscord as discord,
    faYoutube as youtube, faMedium as medium, faGithub as github,
    faTelegram as telegram, faReddit as reddit
    , faEdge as branding, faGoogle as portfolio

} from '@fortawesome/free-brands-svg-icons';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

import { getSettings, beforeSettings } from "../home/footer/footer.action";

import './about-sidebar.css';
import { connect } from "react-redux";

let icons = {
    1: Ethereum,
    2: Binance,
    3: Cronos,
    4: Fantom
}


let chainsVsIcons = {
    5: Ethereum,
    97: Binance,
    338: Cronos,
    4002: Fantom,
    1: Ethereum,
    56: Binance,
    25: Cronos,
    250: Fantom
}

const AboutSidebar = (props) => {
    const [data, setData] = useState(null);
    const { chainId } = useWeb3React();
    const navigate = useNavigate();

    //its will work for authors detail page rendering
    useEffect(() => {
        if (props.authorProfile)
            setData(props.authorProfile);
    }, [props.authorProfile])

    //its will work for collections detail page rendering
    useEffect(() => {
        if (props.collection) {
            let collData = props.collection
            const { discordLink, mediumLink, instaLink, telegramLink } = collData;
            if (discordLink)
                collData.discordLink = 'https://discord.gg/' + discordLink;
            if (instaLink)
                collData.instaLink = 'https://www.instagram.com/' + instaLink;
            if (mediumLink)
                collData.mediumLink = 'https://www.medium.com/@' + mediumLink;
            if (telegramLink)
                collData.telegram = 'https://t.me/' + telegramLink;
            setData({ ...collData, isCollection: true });
        }

    }, [props.collection])

    const copy = (address) => {
        navigator.clipboard.writeText(address)
        toast.success("Address Copied To Clipboard.")
    }


    return (
        <div className='about-sidebar'>
            <div className='follow-section d-flex justify-content-between align-items-center'>
                {data?.isFeaturedCollection && <div className="d-flex align-items-center">
                    <span className='banner-checked'><img className="img-fluid" src={checked} /></span>
                    <span className='yellow-text ml-1'>Featured Collection</span>
                </div>}
                <div className="follow-button" style={{ 'display': 'none' }}>
                    <button className='gradient-button btn w-100' >Follow</button>
                </div>
            </div>
            {
                data?.adminVerified === 1
                &&
                <>
                    <div className='d-flex align-items-center mb-4'>
                        {tikIcon && <img className='tik-icon img-fluid' src={tikIcon} alt="card" />}
                        <div className="user-verified-text pl-2">
                            Verified
                        </div>
                    </div>
                </>
            }
            <>
                <div className='sidebar-about d-flex justify-content-between align-items-center'>
                    <div className="about-head">
                        About
                    </div>
                </div>
                {data?.description ? <div className='about-para'>
                    {data?.description}
                </div> : <div className='about-para'> No Information Exist.</div>
                }
            </>

            <div className='sidebar-values'>
                {data?.address && <div className='d-flex justify-content-between align-items-center mb-4'>
                    <div className="about-head">
                        Wallet
                    </div>
                    <div className="text-end">
                        <div className="input-group">
                            <input type="text" className="form-control cursor-pointer" id="author-add-field" data-effect="float" data-tip="address" placeholder="0x000000000000000000" readOnly value={ENV.formatAddress(data?.address)} />
                            <span className='ethirem-icon'><img className="img-fluid" src={data.blockChain ? icons[Number(data.blockChain)] : chainsVsIcons[Number(chainId)]} /></span>
                            <div className="input-group-append"
                                style={{
                                    position: "absolute",
                                    height: "100%",
                                    top: "0",
                                    right: "0",
                                    borderRadius: "100px",
                                    overflow: "hidden",
                                }}
                                onClick={() => ENV.copy(data?.address)}
                            >
                                <button style={{
                                    marginRight: "5px",
                                    padding: "12px 16px",
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    border: "0",
                                    outline: "none",
                                }}><i className="icon-docs" data-effect="float" data-tip="Copy" /></button>
                            </div>
                        </div>
                    </div>
                </div>}
                {data?.followersCount >= 0 && <div className='d-flex justify-content-between align-items-center mb-4'>
                    <div className="about-head">
                        Followers
                    </div>
                    <div className="text-end end-vales">
                        {data?.followersCount}
                    </div>
                </div>}
                {data?.followingsCount >= 0 && <div className='d-flex justify-content-between align-items-center mb-4'>
                    <div className="about-head">
                        Followings
                    </div>
                    <div className="text-end end-vales">
                        {data?.followingsCount}
                    </div>
                </div>}
                {data?.createdAt && <div className='d-flex justify-content-between align-items-center mb-4'>
                    <div className="about-head">
                        Created
                    </div>
                    {data?.createdAt && <div className="text-end end-vales">
                        {moment(new Date(data?.createdAt)).format("MMM Do YYYY")}
                    </div>}
                </div>}
            </div>
            <div className='sidebar-links'>
                <div className="about-head mb-5">
                    Links
                </div>
                {<ul className="d-flex align-items-center justify-content-center list-unstyled sidebar-custom-icons">
                    {data?.twitterLink && < li >
                        <span onClick={() => { navigate("/redirect-link", { state: { value: data?.twitterLink, label: 'twitter' } }) }} target="_blank" rel="noreferrer" className="twitter">
                            <FontAwesomeIcon icon={twitter} />
                        </span>
                    </li>}
                    {data?.personalPFLink && < li >
                        <span onClick={() => { navigate("/redirect-link", { state: { value: data?.personalPFLink, label: 'portfolio' } }) }} target="_blank" rel="noreferrer" className="portfolio">
                            <FontAwesomeIcon icon={faBriefcase} />
                        </span>
                    </li>}
                    {data?.brandedURLLink && < li >
                        <span className="twitter social-elumnt-logo">
                            <a href={data?.brandedURLLink} className="brand" target="_blank">
                                [M]
                            </a>
                        </span>
                    </li>}

                    {!data?.brandedURLLink && !data?.twitterLink && !data?.discordLink && !data?.instaLink && !data?.mediumLink && !data?.telegramLink
                        && <li>No Link Exist</li>}
                </ul>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: state.settings
})
export default connect(mapStateToProps, { getSettings, beforeSettings })(AboutSidebar)