import React, { useEffect } from 'react';
import AOS from 'aos';
import './full-page-loader.css';
import gifLoader from '../../assets/images/elumntloadingicon.gif'
import $ from 'jquery'
import Portal from '../../Portal';
function FullPageLoader(props) {
    useEffect(() => {
        AOS.init();
        $("#portal-root").addClass("loader-is-visible")

        return () => {
            console.log("UNMOUNT")
            $("#portal-root").removeClass("loader-is-visible")
        }
    }, []);

    return (
        <Portal>
            <div className="fullpage-loader-holder">
                <div className="fullpage-loader loader-image loading">
                    <div class="outer">
                        <div class="loader">
                            <span></span>
                            <p>Loading</p>
                        </div>
                    </div>
                </div>
                {
                    props.loadingMessage &&
                    <div>
                        <h3 className='text-after-loader'>{props.loadingMessage}</h3>
                    </div>
                }
            </div>
        </Portal>
    );
};

export default FullPageLoader;