import React, { useState, useEffect } from "react";
import Welcome from "./Welcome";
import './chat.css';
import { ENV } from './../../config/config';
import moment from "moment";
const { userDefaultImg } = ENV

export default function Contacts({ contacts, changeChat, currentUser, currentChat, notiMsg, notiSign }) {
  
  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserImage, setCurrentUserImage] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const [check, setCheck] = useState(false);
  
  useEffect(() => {
    if(currentUser !== undefined){
      const data = currentUser
      setCurrentUserName(data.username);
      setCurrentUserImage(data.profileImage);
    }
    if(currentChat !== undefined){
      setCurrentSelected(currentChat._id)
    }
  }, []);

  useEffect(() => {
    if(currentChat !== undefined){
      setCurrentSelected(currentChat._id)
    }
  }, [currentChat])
  
  const changeCurrentChat = (index, contact) => {
    changeChat(contact);
  };

 const showMessage = (contact) => {
    if(contact?.recivedMsgs && contact?.sendMessages)
      return moment(contact.recivedMsgs?.createdAt).isAfter(moment(contact.sendMessages?.createdAt)) ? contact.recivedMsgs?.message : contact.sendMessages?.message;
    else if(!contact?.recivedMsgs && contact?.sendMessages)
      return contact.sendMessages?.message;
    else if(contact?.recivedMsgs && !contact?.sendMessages)
      return contact.recivedMsgs?.message;
    else return;
  }

  const showTime = (contact) => {
    if(contact?.recivedMsgs && contact?.sendMessages)
      return moment(contact.recivedMsgs?.createdAt).isAfter(moment(contact.sendMessages?.createdAt)) ? moment(contact.recivedMsgs?.createdAt).fromNow() : moment(contact.sendMessages?.createdAt).fromNow();
    else if(!contact?.recivedMsgs && contact?.sendMessages)
      return moment(contact.sendMessages?.createdAt).fromNow();
    else if(contact?.recivedMsgs && !contact?.sendMessages)
      return moment(contact.recivedMsgs?.createdAt).fromNow()
    else return;
  }

  const showUnreadSign = (contact) => {
    if(contact?.recivedMsgs){
      return contact.recivedMsgs?.read;
    } 
    else return true;
  }
  
  return (
    <>
      {currentUser === undefined ? (
        <Welcome currentUser={currentUser}/>
      ) : (
        <div>
         
          {currentUserName && (
            <section className="contact-container">
              {contacts.length > 0 ? (
                <div>
                <div className="contacts">
                  {contacts.map((contact, index) => {
                    return (
                      <div
                      
                        key={contact._id}
                        className={`contact p-0 m-0 d-flex justify-content-start ${
                          
                          contact._id === currentSelected ? "selected" : ""
                        }`}
                        
                        onClick={() => changeCurrentChat(index, contact)} 
                        
                      >
                       
                        <div className="avatar">
                          <img
                            src={contact.profileImage ? contact.profileImage : userDefaultImg}
                            alt="avatar"
                          />
                        </div>
                        <div className="username">
                          <div>
                            <h5>{contact.username}</h5>
                          </div>
                          { notiMsg && notiMsg.length > 0  
                              ?
                              notiMsg?.map((item, index) => {
                                  return (
                                    <>
                                      {item.from === contact._id  ?
                                        <p key={index}>{item.msg}</p> : <p key={index}>{showMessage(contact)}</p>}
                                    </>
                                  )
                                })
                              : <p>{showMessage(contact)}</p>
                          }
                        </div>
                        <div className="d-flex align-items-center justify-content-end chat-moment-area">
                          { notiMsg && notiMsg.length > 0  
                                ?
                                notiMsg?.map((item, index) => {
                                    return (
                                      <>
                                        {item.from === contact._id  ?
                                          <>
                                            <div key={index} className="chat-time"><span>{moment(item.date).fromNow()}</span></div>

                                            {notiSign && notiSign.length > 0
                                              ?
                                              notiSign?.map((signItem, signIndex) => {
                                                return (
                                                  <>
                                                      {signItem.from === contact._id  ?
                                                        <>
                                                          <span key={signIndex} className="unread-dot ml-2"></span>
                                                        </>
                                                        : null
                                                      }
                                                  </>
                                                )
                                              })
                                              : null
                                            }
                                          </>
                                           : <div key={index} className="chat-time"><span>{showTime(contact)}</span></div>
                                        }
                                      </>
                                    )
                                  })
                                :
                                <div className="chat-time"><span>{showTime(contact)}</span></div>
                          }
                          {!showUnreadSign(contact) && 
                            <span className="unread-dot ml-2"></span>
                          }
                        </div> 
                      </div>
                    );
                  })}
                </div>
                </div>
              ) : (
                <div className="brand">
                  <h3>You do not have any chats yet.</h3>
                </div>
              )}
            </section>
          )}
        </div>
      )}
    </>
  );
}
