import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, InputGroup, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { connect } from "react-redux";
import { beforeNFT, getNFT, upsertNFT } from "./nfts.action";
import {
  beforeSettings,
  getSettings,
  getTokenSettings,
} from "../home/footer/footer.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import NFTPreview from "../nft-preview/nft-preview";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ethIcon from "../../assets/images/icon-ethereum.png";
import moment from "moment";
import validator from "validator";
import {
  changeSellingStatusWeb3,
  getPercentagesWeb3,
  getChainId,
  checkPixulBalance,
  getAddressWithChain,
} from "./../../utils/web3";
import { ENV } from "../../config/config";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { useWeb3React } from "@web3-react/core";
import { useParams, useNavigate } from "react-router-dom";
import Ethereum from "../../assets/images/arow-icon.png";
import Binance from "../../assets/images/binancelogo.png";
import Fantom from "../../assets/images/fantom.png";
import Cronos from "../../assets/images/cronos.png";
const {
  decimalNumberValidator,
  dateRangeInitialSettings,
  objectToQueryString,
  integerNumberValidator,
  chainsConfigs,
  numberToChainId,
} = ENV;

const config1 = {
  price: {
    blockChain: "",
    currency: "",
    amount: "",
  },
  listingSchedule: {
    startDate: moment(),
    endDate: moment().add(6, "months"),
    startTime: moment(new Date()).format("HH:mm"),
    endTime: "23:59",
  },
  reserveFor: "", // if user selects reserve buyer
  quantity: 1,
};
const config2 = {
  method: 1, // 1 = Sell to the highest bidder or 2 = Sell with the declining price
  startPrice: {
    currency: "",
    amount: "",
    blockChain: "",
  },
  endPrice: {
    currency: "",
    amount: "",
    blockChain: "",
  },
  duration: {
    startDate: moment(),
    endDate: moment().add(6, "months"),
    startTime: moment(new Date()).format("HH:mm"),
    endTime: "23:59",
  },
  // if user includes reserve price
  reservePrice: {
    currency: "",
    amount: "",
    blockChain: "",
  },
};

const currenciesImages = {
  1: Ethereum,
  5: Ethereum,
  4002: Fantom,
  205: Fantom,
  338: Cronos,
  25: Cronos,
  97: Binance,
  56: Binance,
};

const blockChainVsTokens = {
  1: "ethTokens",
  2: "bnbTokens",
  3: "ftmTokens",
  4: "croTokens",
};

const SellNFT = (props) => {
  const [sharePercentages, setSharePercentages] = useState({});
  const [sellingMethod, setSellingMethod] = useState(1); // 1 = Fixed Price, 2 = Timed Auction
  const [fixedPriceConfig, setFixedPriceConfig] = useState(config1);
  const [timedAuctionConfig, setTimedAuctionConfig] = useState(config2);
  const [nft, setNft] = useState(null);
  const [loader, setLoader] = useState(true); // NFT loader
  const [tokenLoader, setTokenLoader] = useState(true); // loader for converting tokens prices to required tokens
  const reserveBuyer = false;
  const [reservePrice, setReservePrice] = useState(false);
  const [submitCheck, setSubmitCheck] = useState(false);
  const [paymentTokens, setPaymentTokens] = useState([]);
  const [connectedAddress, setConnectedAddress] = useState(null);
  const [seletedNetwork, setSeletedNetwork] = useState(null);
  // const [blockChains, setBlockChains] = useState([])
  const [settings, setSettings] = useState(null);
  const [tokenSettings, setTokenSettings] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [serviceFeePercent, setServiceFeePercent] = useState(null);
  const [chainRate, setChainRate] = useState(null);
  const [serviceFeeInUsd, setServiceFeeInUsd] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [earningsInUsd, setEarningsInUsd] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const { _id } = ENV.getUserKeys("_id");
  const { itemId } = useParams();
  const parmas = useParams();
  const navigate = useNavigate();
  const { chainId } = useWeb3React();

  // useEffect(() => {
  // 	getChainId().then(setChainId);

  // const { connectedAddress, selectedNetwork } = await getAddressWithChain()
  // if (connectedAddress && selectedNetwork) {
  // 	this.setState({
  // 		connectedAddress, selectedNetwork
  // 	})
  // }

  // }, [])
  useEffect(() => {
    const fetchChainInfo = async () => {
      const { connectedAddress, selectedNetwork } = await getAddressWithChain();
      setConnectedAddress(connectedAddress);
      setSeletedNetwork(selectedNetwork);
    };
    fetchChainInfo();
  }, [chainId]);

  const [priceMsg, setPriceMsg] = useState({
    blockChain: "",
    currency: "",
    amount: "",
    reserveFor: "",
    startTimeMsg: "",
    endTimeMsg: "",
    quantityMsg: "",
  });

  const [auctionMsg, setAuctionMsg] = useState({
    blockChain: "",
    currency: "",
    amount: "",
    price: "",

    ecurrency: "",
    eamount: "",
    eprice: "",

    rcurrency: "",
    ramount: "",
    rprice: "",

    startTimeMsg: "",
    endTimeMsg: "",
  });

  useEffect(() => {
    if (localStorage.getItem("encuse")) {
      window.scroll(0, 0);
      if (itemId) {
        const qs = objectToQueryString({ page: "sell" });
        props.getNFT(window.atob(itemId), qs);
        props.getSettings();
      } else navigate("/explore-all");
    } else {
      navigate("/login");
    }
  }, []);

  // when settings are retrieved
  useEffect(() => {
    if (props.settings?.settingsAuth) {
      const { settings, tokenSettings } = props.settings;
      if (!settings || !settings.paymentTokens) {
        let fPConfig = { ...fixedPriceConfig };
        fPConfig.price.currency = "";
        // fPConfig.price.currency = chainsConfigs[numberToChainId[this.blockChainInUrl]].nativeCurrency.symbol
        setFixedPriceConfig({ ...fPConfig });

        let tAConfig = { ...timedAuctionConfig };
        fPConfig.price.currency = ""; //chainsConfigs[numberToChainId[this.blockChainInUrl]].nativeCurrency.symbol
        tAConfig.endPrice.currency = "";
        tAConfig.reservePrice.currency = "";
        setTimedAuctionConfig({ ...tAConfig });
      } else if (settings) {
        setSettings(settings);

        let fPConfig = { ...fixedPriceConfig };
        setFixedPriceConfig({ ...fPConfig });

        let tAConfig = { ...timedAuctionConfig };
        setTimedAuctionConfig({ ...tAConfig });
      }
      props.beforeSettings();
    }

    //set DropDown Currency Values
    if (props?.settings?.tokenSettingsAuth) {
      let tokens = props.settings.tokenSettings;
      tokens = tokens.map((item) => {
        return item.value;
      });
      setPaymentTokens(tokens);
      props.beforeSettings();
    }
  }, [props.settings.settingsAuth, props?.settings?.tokenSettingsAuth]);

  // when NFT data is received
  useEffect(() => {
    if (!tokenSettings && props.nft?.nftsData?.blockChain) {
      let tokenQA = ENV.objectToQueryString({
        chain: props.nft.nftsData.blockChain,
      });
      props.getTokenSettings(tokenQA);
    }

    console.log("why nft is not loading", props.nft?.nftsData?.blockChain);
    if (props.nft.nftsAuth && settings) {
      const nft = props.nft.nftsData;

      // let tokensName = blockChainVsTokens[nft.blockChain]
      // let tokens = settings.paymentTokens[tokensName]
      console.log("211111111111111117", tokenSettings);
      // setPaymentTokens(tokens);

      setNft(nft);
      getPercentages(nft);
      props.beforeNFT();
      if (!nft) navigate("/explore-all");
      setLoader(false);
    }
  }, [props.nft.nftsAuth, settings]);

  // when NFT data is updated
  useEffect(() => {
    if (props.nft.upsertAuth) {
      const { nft } = props.nft.nftsData;
      const pathname = nft.sellingMethod && "/explore-all"; //=== 1 ? '/explore-all' : '/auctions'
      setLoader(false);
      props.beforeNFT();
      navigate(pathname);
    }
  }, [props.nft.upsertAuth]);

  // when an error is received
  useEffect(() => {
    if (props.error) {
      setLoader(false);
    }
  }, [props.error]);

  // when rates for tokens are received
  useEffect(() => {
    if (tokenLoader) {
      const { rateAuth, ethRateAuth, fantomRateAuth, croRateAuth } = props.app;
      if (rateAuth && ethRateAuth && fantomRateAuth && croRateAuth)
        setTokenLoader(false);
    }
  }, [props.app, tokenLoader]);

  const getPercentages = async (nft) => {
    let percentages = await getPercentagesWeb3(0.001);
    let percentObject = {
      platformShare: parseInt(percentages[0]),
      royaltyShare: parseInt(nft.royalty || 0),
    };
    setSharePercentages(percentObject);
    setLoader(false);
  };

  const handlePrice = async (e, field) => {
    const { value } = e.target;

    if (e.target.value) {
      setPriceMsg({ ...priceMsg, amount: "" });
    } else {
      setPriceMsg({ ...priceMsg, amount: "Amount Is Required." });
    }

    const { app } = props;
    // const rates = {
    // 	1: app.ethRate,
    // 	2: app.rate,
    // 	3: app.fantomRate,
    // 	4: app.croRate
    // }
    const rates = props.app.allRates;
    let currency =
      sellingMethod == 1
        ? fixedPriceConfig.price.currency
        : timedAuctionConfig.startPrice.currency;

    console.log("currency", currency);
    let percentages = await getPercentagesWeb3(0.001);
    let percentageSettings = settings.percentageSettings;
    const pixulBalance = await checkPixulBalance(ENV.pixulToken);

    percentageSettings?.sort(function (a, b) {
      return a.maxPixulLimit - b.maxPixulLimit;
    });

    let serviceFee = 0,
      earnings = 0,
      serviceFeeInUsd = 0,
      earningsInUsd = 0,
      chainRate = 0,
      serviceFeePercent = 0;
    // const currentRates = rates[parseInt(nft.blockChain)]
    console.log("rates", rates);
    const currentRates = rates[`${currency}toUSD`];

    const pixulBalanceInUSD = app.pixulRate * pixulBalance;

    if (value && currentRates) {
      const seletecedSetting = percentageSettings.find(
        (percent) => pixulBalanceInUSD <= percent.maxPixulLimit
      );

      serviceFeePercent = seletecedSetting.serviceFeePecentage;
      serviceFee = (serviceFeePercent * parseFloat(value)) / 100;
      serviceFeeInUsd = ENV.convertRateToUsd(serviceFee, currentRates);

      chainRate = ENV.convertChainRate(serviceFeeInUsd, currentRates);

      earnings = parseFloat(value) - parseFloat(serviceFee);
      earningsInUsd = ENV.convertRateToUsd(earnings, currentRates);

      let percentObject = {
        platformShare: serviceFeePercent,
        royaltyShare: parseInt(nft.royalty || 0),
      };

      setSelectedCurrency(currency);
      setSharePercentages(percentObject);
      setServiceFee(serviceFee);
      setServiceFeePercent();
      setChainRate(chainRate);
      setServiceFeeInUsd(serviceFeeInUsd);
      setEarnings(earnings);
      setEarningsInUsd(earningsInUsd);

      // if (field === "minBid") {
      // 	const data = timedAuctionConfig;
      // 	data.startPrice.amount = value;
      // 	setTimedAuctionConfig(data)
      // }

      // if (field === "fPrice") {
      // 	const data = fixedPriceConfig;
      // 	data.price.amount = value;
      // 	setFixedPriceConfig(data)
      // }
    }
  };

  useEffect(() => {
    if (sellingMethod == 1) {
      console.log("selling 0001", sellingMethod, props);
      // settings?.paymentTokens?.find((item, index) => {
      // 	// if (item.blockChain == config1.price.blockChain) {
      // 	setPaymentTokens(settings.paymentTokens[index].tokens);
      // 	// }
      // })
    } else if (sellingMethod == 2) {
      console.log("selling 002", sellingMethod);
      // settings?.paymentTokens?.find((item, index) => {
      // 	// if (item.blockChain == config2.startPrice.blockChain) {
      // 	setPaymentTokens(settings.paymentTokens[index].tokens);
      // 	// }
      // })
    }
  }, [config1.price.blockChain, config2.startPrice.blockChain]);

  const onChange = (e) => {
    const { name, value } = e.target;
    let data = null;
    if (sellingMethod === 1) data = fixedPriceConfig;
    else data = timedAuctionConfig;

    const keys = name.split("."); // fixedPriceConfig, price, currency
    if (keys && keys[2]) data[keys[1]][keys[2]] = value;
    else if (keys && keys[1]) data[keys[1]] = value;

    if (sellingMethod === 1) {
      const quantity = Number(data.quantity);
      const startTime = moment(data.listingSchedule.startTime, "HH:mm");
      const endTime = moment(data.listingSchedule.endTime, "HH:mm");
      let isEndDateBeforeOrSame = moment(
        data.listingSchedule.endDate.format("YYYY-MM-DD")
      ).isSameOrBefore(moment(new Date()).format("YYYY-MM-DD"));
      let validCheck = true;

      if (submitCheck) {
        let startTimeMsg = "",
          endTimeMsg = "",
          quantityMsg = "";
        if (nft.copies > 1) {
          if (!quantity && quantity !== 0)
            quantityMsg = "Please enter a quantity";
          else if (quantity === 0)
            quantityMsg = "The amount must be greater than 0";
          else if (quantity < 0)
            quantityMsg = "The amount must be a positive integer";
          else if (quantity > nft.copies)
            quantityMsg = `The quantity cannot exceed ${nft.copies}`;

          validCheck = false;
        }

        if (startTime.isBefore(moment(new Date(), "HH:mm"))) {
          startTimeMsg = "Please provide valid Start Time";
          validCheck = false;
        }

        if (
          isEndDateBeforeOrSame &&
          endTime.isBefore(moment(new Date(), "HH:mm"))
        ) {
          endTimeMsg = "Please provide valid End Time";
          validCheck = false;
        }

        setPriceMsg({ ...priceMsg, quantityMsg, startTimeMsg, endTimeMsg });
      }
      if (validCheck) {
        data.listingSchedule.startDate.set({
          hour: startTime.get("hour"),
          minute: startTime.get("minute"),
          second: startTime.get("second"),
        });
        data.listingSchedule.endDate.set({
          hour: endTime.get("hour"),
          minute: endTime.get("minute"),
          second: endTime.get("second"),
        });
        setFixedPriceConfig({ ...data });
      }
    } else {
      const startTime = moment(data.duration.startTime, "HH:mm");
      const endTime = moment(data.duration.endTime, "HH:mm");
      let validCheck = true;
      let isEndDateBeforeOrSame = moment(
        data.duration.endDate.format("YYYY-MM-DD")
      ).isSameOrBefore(moment(new Date()).format("YYYY-MM-DD"));
      let startTimeMsg = "",
        endTimeMsg = "";

      if (submitCheck) {
        if (startTime.isBefore(moment(new Date(), "HH:mm")) && submitCheck) {
          startTimeMsg = "Please provide valid Start Time";
          validCheck = false;
        }

        if (
          isEndDateBeforeOrSame &&
          endTime.isBefore(moment(new Date(), "HH:mm")) &&
          submitCheck
        ) {
          endTimeMsg = "Please provide valid End Time";
          validCheck = false;
        }

        setAuctionMsg({ ...auctionMsg, startTimeMsg, endTimeMsg });
      }
      if (validCheck) {
        data.duration.startDate.set({
          hour: startTime.get("hour"),
          minute: startTime.get("minute"),
          second: startTime.get("second"),
        });
        data.duration.endDate.set({
          hour: endTime.get("hour"),
          minute: endTime.get("minute"),
          second: endTime.get("second"),
        });

        if (parseInt(timedAuctionConfig.method) === 1) {
          timedAuctionConfig.reservePrice.currency =
            timedAuctionConfig.startPrice.currency;
          timedAuctionConfig.endPrice = {
            currency: "",
            amount: "",
          };
        } else if (parseInt(timedAuctionConfig.method) === 2) {
          timedAuctionConfig.endPrice.currency =
            timedAuctionConfig.startPrice.currency;
          timedAuctionConfig.reservePrice = {
            currency: "",
            amount: "",
          };
          setReservePrice(false);
        }

        setTimedAuctionConfig({ ...data });
      }
    }
  };

  const handleDateChange = (e, picker) => {
    const { name } = e.target;
    const { startDate, endDate } = picker;

    // set start date
    onChange({
      target: {
        name: `${name}.startDate`,
        value: startDate,
      },
    });

    // set end date
    onChange({
      target: {
        name: `${name}.endDate`,
        value: endDate,
      },
    });
  };

  const submit = async () => {
    setSubmitCheck(true);
    if (sellingMethod === 1) {
      let blockChain = "";
      let currency = "";
      let amount = "";
      let reserveFor = "";
      let startTimeMsg = "";
      let endTimeMsg = "";
      let quantityMsg = "";

      // if (validator.isEmpty(fixedPriceConfig.blockChain)) {
      // 	blockChain = 'BlockChain Is Required.'
      // }

      if (nft.copies > 1) {
        const { quantity } = fixedPriceConfig;
        if (!quantity && quantity !== 0)
          quantityMsg = "Please enter a quantity";
        else if (quantity === 0)
          quantityMsg = "The amount must be greater than 0";
        else if (quantity < 0)
          quantityMsg = "The amount must be a positive integer";
        else if (quantity > nft.copies)
          quantityMsg = `The quantity cannot exceed ${nft.copies}`;
      }

      if (validator.isEmpty(fixedPriceConfig.price.currency)) {
        currency = "Currency Is Required.";
      }
      if (validator.isEmpty(fixedPriceConfig.price.amount)) {
        amount = "Amount is required";
      }
      if (reserveBuyer) {
        if (validator.isEmpty(fixedPriceConfig.reserveFor)) {
          reserveFor = "Address Is Required.";
        }
      }

      const startTime = moment(
        fixedPriceConfig.listingSchedule.startTime,
        "HH:mm"
      );
      const endTime = moment(fixedPriceConfig.listingSchedule.endTime, "HH:mm");
      let isEndDateBeforeOrSame = moment(
        fixedPriceConfig.listingSchedule.endDate.format("YYYY-MM-DD")
      ).isSameOrBefore(moment(new Date()).format("YYYY-MM-DD"));

      if (startTime.isBefore(moment(new Date(), "HH:mm"))) {
        startTimeMsg = "Please provide valid Start time";
      }
      if (
        isEndDateBeforeOrSame &&
        endTime.isBefore(moment(new Date(), "HH:mm"))
      ) {
        endTimeMsg = "Please provide valid End time";
      }

      setPriceMsg({
        blockChain,
        quantityMsg,
        currency,
        amount,
        reserveFor,
        startTimeMsg,
        endTimeMsg,
      });
      if (
        blockChain ||
        quantityMsg ||
        currency ||
        amount ||
        reserveFor ||
        startTimeMsg ||
        endTimeMsg
      ) {
        return;
      }
    } else {
      let blockChain = "";
      let currency = "";
      let amount = "";
      let price = "";
      let ecurrency = "";
      let eamount = "";
      let eprice = "";
      let rcurrency = "";
      let ramount = "";
      let rprice = "";
      let startTimeMsg = "";
      let endTimeMsg = "";
      // if (validator.isEmpty(timedAuctionConfig.startPrice.blockChain)) {
      // 	blockChain = 'BlockChain Is Required.'
      // }
      const startTime = moment(timedAuctionConfig.duration.startTime, "HH:mm");
      const endTime = moment(timedAuctionConfig.duration.endTime, "HH:mm");
      let isEndDateBeforeOrSame = moment(
        timedAuctionConfig.duration.endDate.format("YYYY-MM-DD")
      ).isSameOrBefore(moment(new Date()).format("YYYY-MM-DD"));

      if (startTime.isBefore(moment(new Date(), "HH:mm"))) {
        startTimeMsg = "Please provide valid Start time";
      }
      if (
        isEndDateBeforeOrSame &&
        endTime.isBefore(moment(new Date(), "HH:mm"))
      ) {
        endTimeMsg = "Please provide valid End time";
      }

      if (validator.isEmpty(timedAuctionConfig.startPrice.amount)) {
        amount = "Amount is required";
      }
      if (validator.isEmpty(timedAuctionConfig.startPrice.currency)) {
        currency = "Currency Is Required.";
      }
      if (parseInt(timedAuctionConfig.method) === 2) {
        if (validator.isEmpty(timedAuctionConfig.endPrice.amount)) {
          eamount = "Amount is required";
        }
        if (validator.isEmpty(timedAuctionConfig.endPrice.currency)) {
          ecurrency = "Currency Is Required.";
        }
      }
      if (reservePrice) {
        if (validator.isEmpty(timedAuctionConfig.reservePrice.amount)) {
          ramount = "Amount is required";
        } else if (timedAuctionConfig.reservePrice.amount) {
          const MYNTtoBNB = props.app.myntToBnbRate || 0;

          const reqAmount =
            timedAuctionConfig.reservePrice.currency === "BNB" &&
            parseFloat(timedAuctionConfig.reservePrice.amount) >= 1
              ? true
              : timedAuctionConfig.reservePrice.currency === "MYNT" &&
                parseFloat(timedAuctionConfig.reservePrice.amount) *
                  MYNTtoBNB >=
                  1
              ? true
              : false;

          if (!reqAmount)
            if (timedAuctionConfig.reservePrice.currency === "BNB")
              ramount = `Auctions can't have a reserve price lower than 1 ${timedAuctionConfig.reservePrice.currency}`;
            else if (timedAuctionConfig.reservePrice.currency === "MYNT")
              ramount = `Auctions can't have a reserve price lower than ${(
                1 / MYNTtoBNB
              ).toFixed(7)} ${
                timedAuctionConfig.reservePrice.currency
              } (i.e. ≡ 1 ${ENV.currency})`;
        }
        if (validator.isEmpty(timedAuctionConfig.reservePrice.currency)) {
          rcurrency = "Currency Is Required.";
        }
      }

      setAuctionMsg({
        blockChain,
        currency,
        amount,
        price,
        ecurrency,
        eamount,
        eprice,
        rcurrency,
        ramount,
        rprice,
        startTimeMsg,
        endTimeMsg,
      });
      if (
        blockChain ||
        currency ||
        amount ||
        price ||
        ecurrency ||
        eamount ||
        eprice ||
        rcurrency ||
        ramount ||
        rprice ||
        startTimeMsg ||
        endTimeMsg
      ) {
        return;
      }
    }
    const payload = {
      _id: nft._id,
      collectionId: nft.collection?._id,
      sellingMethod,
      blockChain: nft.blockChain,
      sellingConfig: JSON.stringify(
        sellingMethod === 1 ? fixedPriceConfig : timedAuctionConfig
      ),
      tokenStandard: nft.tokenStandard,
    };

    setFixedPriceConfig(config1);
    setTimedAuctionConfig(config2);
    setLoader(true);
    let sellingPrice = 0;
    if (sellingMethod === 1) {
      let sellingConfig = JSON.parse(payload.sellingConfig);
      sellingPrice = sellingConfig.price.amount;
    } else {
      let sellingConfig = JSON.parse(payload.sellingConfig);
      sellingPrice = sellingConfig.startPrice.amount;
    }
    let _nftData = {
      tokenId: nft.tokenId || 0,
      selling: sellingMethod,
      price: sellingPrice,
      nft: nft.address,
      nftId: nft.autoNftId,
      creator: nft.creator.address,
      ...(nft.copies && { copies: nft.copies }),
    };

    let sellingStatus = await changeSellingStatusWeb3(
      _nftData,
      nft._id,
      nft.tokenStandard
    );
    if (!sellingStatus) {
      setLoader(false);
      return false;
    }
    var formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    props.upsertNFT("edit", formData, "PUT");
  };

  const getPrice = () => {
    let currency =
      sellingMethod == 1
        ? fixedPriceConfig.price.currency
        : timedAuctionConfig.startPrice.currency;
    let amount =
      sellingMethod == 1
        ? fixedPriceConfig.price.amount
        : timedAuctionConfig.startPrice.amount;
    let quantity = 1;
    console.log("saqib", currency, amount, quantity);
    currency = currency?.toUpperCase();
    if (props.app.allRatesAuth && currency) {
      let result = ENV.convertXtoY(
        amount,
        props.app.allRates[`${currency}toUSD`],
        quantity
      );
      console.log("result", result);
      return result;
    } else return "0.00";
  };
  return (
    <section className="sell-nft-section">
      <div className="padding-wrapper">
        {(loader || tokenLoader) && <FullPageLoader />}
        {nft && (
          <div className="container">
            <ReactTooltip />
            <div className="d-flex flex-column flex-md-row flex-wrap">
              <div className="col-lg-7 col-md-12  sell-nft-content sell-area mb-sm-5">
                <span className="head-wrapper">List of Bundle for Sale</span>
                <div className="sell-nft-area">
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                      {nft?.copies > 1 ? (
                        <div className="w-100 mb-4">
                          <div className="d-flex justify-content-between">
                            <div className="mb-2">
                              <b>Quantity</b>
                            </div>
                          </div>
                          <input
                            type="text"
                            name="fixedPriceConfig.quantity"
                            placeholder="Quantity"
                            onChange={(e) => onChange(e)}
                            defaultValue={fixedPriceConfig.quantity}
                            onKeyDown={(e) => integerNumberValidator(e)}
                            required
                          />
                          <div className="d-flex justify-content-between mt-2 dollar-wrapper">
                            <span>
                              {submitCheck && priceMsg?.quantityMsg && (
                                <div>
                                  <label
                                    className={`text-danger pl-1 ${
                                      priceMsg.quantityMsg ? `` : `d-none`
                                    }`}
                                  >
                                    {priceMsg.quantityMsg}
                                  </label>
                                </div>
                              )}
                            </span>
                            <span>{nft.copies} available</span>
                          </div>
                        </div>
                      ) : (
                        <Nav className="flex-row justify-content-start flex-column mb-4 mb-sm-0">
                          <div className="d-flex flex-column flex-sm-row justify-content-start">
                            <button
                              onClick={() => setSellingMethod(1)}
                              className={`nft-category ${
                                sellingMethod === 1 && "active"
                              }`}
                            >
                              <div className="content">
                                <div className="nft-category-image">
                                  <i className="fas fa-dollar-sign" />
                                </div>
                                <span>Fixed price</span>
                              </div>
                            </button>

                            <button
                              onClick={() => setSellingMethod(2)}
                              className={`nft-category ${
                                sellingMethod === 2 && "active"
                              }`}
                            >
                              <div className="content">
                                <div className="nft-category-image">
                                  <i className="fas fa-dollar-sign" />
                                </div>
                                <span>Timed Auction</span>
                              </div>
                            </button>
                          </div>
                        </Nav>
                      )}
                    </Col>
                  </Row>
                  <div className="market-tab-content">
                    <Container fluid>
                      {sellingMethod === 1 && (
                        <div className="fixed-price-tab">
                          {/* <div className="price-wrapper pt-0">
													<div className="price-text position-relative">
														<div className="d-flex justify-content-between mb-3">
															<div className="mb-2 text-white"><b>Blockchain</b></div>
															<div className="d-flex align-items-center cursor-pointer" data-effect="float" data-tip="List price and listing schedule cannot be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price and dates.">
																<i className="fas fa-exclamation-circle ml-2" />
															</div>
														</div>
														<div className="d-flex justify-content-between flex-column flex-sm-row">
															<select id="blockChain" name="fixedPriceConfig.price.blockChain" value={fixedPriceConfig.price.blockChain} onChange={(e) => onChange(e)}>
																<option className="" value="">Select Blockchain</option>
																{
																	blockChains && blockChains.map((blockChain, index) => {
																		return (
																			<option key={index} value={blockChain}>{blockChain}</option>
																		)
																	})
																}
															</select>
														</div>
														 
														{
															submitCheck && priceMsg?.blockChain && //&& fixedPriceConfig.price.amount &&
															<div>
																<label className={`text-danger pl-1 ${priceMsg.blockChain ? `` : `d-none`}`}>{priceMsg.blockChain}</label>
															</div>
														}
													</div>
												</div> */}
                          <div className="price-wrapper pt-0">
                            <div className="price-text position-relative">
                              <div className="d-flex justify-content-between">
                                <div className="mb-2">
                                  <b>Price {nft.copies > 1 && "per unit"}</b>
                                </div>
                                <div
                                  className="d-flex align-items-center cursor-pointer"
                                  data-effect="float"
                                  data-tip="List price and listing schedule cannot be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price and dates."
                                >
                                  <i className="fas fa-exclamation-circle ml-2" />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between flex-column flex-sm-row">
                                <select
                                  className="form-control sell-nft-select"
                                  id="currency"
                                  name="fixedPriceConfig.price.currency"
                                  value={fixedPriceConfig.price.currency}
                                  onChange={(e) => {
                                    onChange(e);
                                    handlePrice(e, "fPrice");
                                    fixedPriceConfig.price.amount = 0;
                                  }}
                                >
                                  <option className="" value="">
                                    Select Currency
                                  </option>
                                  {paymentTokens &&
                                    paymentTokens.map((token, index) => {
                                      return (
                                        <option key={index} value={token}>
                                          {token}
                                        </option>
                                      );
                                    })}
                                </select>
                                <div className="ethereum-input">
                                  <span className="input-wrap">
                                    <input
                                      type="text"
                                      name="fixedPriceConfig.price.amount"
                                      placeholder="Amount"
                                      className="amount-btn flex-fill"
                                      style={{ borderRadius: "15px" }}
                                      onChange={(e) => {
                                        onChange(e);
                                        handlePrice(e, "fPrice");
                                        if (submitCheck) {
                                          if (e.target.value) {
                                            setPriceMsg({
                                              ...priceMsg,
                                              amount: "",
                                            });
                                          } else {
                                            setPriceMsg({
                                              ...priceMsg,
                                              amount: "Amount Is Required.",
                                            });
                                          }
                                        }
                                      }}
                                      onKeyDown={(e) =>
                                        decimalNumberValidator(e)
                                      }
                                      value={fixedPriceConfig.price.amount}
                                      required
                                    />
                                  </span>
                                  <div className="text-right dollar-wrapper custom-price-usd">
                                    {nft?.copies > 1 &&
                                    Number(fixedPriceConfig?.quantity) > 1 ? (
                                      <>
                                        {"$"}

                                        {
                                          getPrice()

                                          // props.app.rateAuth && fixedPriceConfig.price.currency === 'BNB' && Number(fixedPriceConfig.price.amount) ?
                                          // 	ENV.convertXtoY(fixedPriceConfig.price.amount * fixedPriceConfig.quantity, props.app.rate)
                                          // 	:
                                          // 	props.app.myntRateAuth && fixedPriceConfig.price.currency === 'MYNT' && Number(fixedPriceConfig.price.amount) ?
                                          // 		ENV.convertMyntToUsd(fixedPriceConfig.price.amount * fixedPriceConfig.quantity, props.app.myntRate)
                                          // 		: '0.00'
                                        }
                                        {" Total ($"}
                                        {
                                          getPrice()

                                          // props.app.rateAuth && fixedPriceConfig.price.currency === 'BNB' && Number(fixedPriceConfig.price.amount) ?
                                          // 	ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.rate)
                                          // 	: props.app.ethRateAuth && fixedPriceConfig.price.currency === 'ETH' && Number(fixedPriceConfig.price.amount) ?
                                          // 		ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.ethRate)
                                          // 		: props.app.fantomRateAuth && fixedPriceConfig.price.currency === 'FTM' && Number(fixedPriceConfig.price.amount) ?
                                          // 			ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.fantomRate)
                                          // 			: props.app.croRateAuth && fixedPriceConfig.price.currency === 'CRO' && Number(fixedPriceConfig.price.amount) ?
                                          // 				ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.croRate)
                                          // 				: '0.001'
                                        }
                                        {" each)"}
                                      </>
                                    ) : (
                                      <>
                                        ${" "}
                                        {
                                          getPrice()

                                          // props.app.rateAuth && fixedPriceConfig.price.currency === 'BNB' && Number(fixedPriceConfig.price.amount) ?
                                          // 	ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.rate)
                                          // 	: props.app.ethRateAuth && fixedPriceConfig.price.currency === 'ETH' && Number(fixedPriceConfig.price.amount) ?
                                          // 		ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.rate)
                                          // 		: props.app.fantomRateAuth && fixedPriceConfig.price.currency === 'FTM' && Number(fixedPriceConfig.price.amount) ?
                                          // 			ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.fantomRate)
                                          // 			: props.app.croRateAuth && fixedPriceConfig.price.currency === 'CRO' && Number(fixedPriceConfig.price.amount) ?
                                          // 				ENV.convertXtoY(fixedPriceConfig.price.amount, props.app.croRate)
                                          // 				: '0.00'
                                        }
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  className={`text-danger pl-1 ${
                                    priceMsg.amount ? `` : `d-none`
                                  }`}
                                >
                                  {priceMsg.amount}
                                </label>
                              </div>
                              {submitCheck &&
                                priceMsg?.currency &&
                                fixedPriceConfig.price.amount && (
                                  <div>
                                    <label
                                      className={`text-danger pl-1 ${
                                        priceMsg.currency ? `` : `d-none`
                                      }`}
                                    >
                                      {priceMsg.currency}
                                    </label>
                                  </div>
                                )}
                            </div>
                            {serviceFee > 0 && (
                              <div className="fees-earning mt-4">
                                <div className="d-flex content custom-border justify-content-between align-items-center">
                                  <h5>MetaMotu Service fee</h5>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      ${parseFloat(serviceFeeInUsd).toFixed(10)}
                                    </span>
                                    <span className="eth-val ml-4">
                                      {parseFloat(chainRate).toFixed(10)}{" "}
                                      {` ` + selectedCurrency}
                                    </span>
                                    {sharePercentages &&
                                    sharePercentages.platformShare ? (
                                      <span className="eth-val ml-4">
                                        {parseFloat(
                                          sharePercentages.platformShare
                                        ).toFixed(2)}
                                        %
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {(sharePercentages &&
                                    sharePercentages.platformShare) ||
                                  (sharePercentages &&
                                    sharePercentages.royaltyShare) ? (
                                    <div className="d-flex justify-content-between my-3">
                                      <ReactTooltip />
                                      <div
                                        className="text-right mb-2 cursor-pointer"
                                        data-effect="float"
                                        data-tip="Listing is free. Once sold, the following fees will be deducted."
                                      >
                                        <i className="fas fa-exclamation-circle" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="d-flex justify-content-between my-3"></div>
                                  )}
                                </div>

                                <div className="d-flex content justify-content-between align-items-center">
                                  <h5>Your earnings</h5>
                                  <div>
                                    <span>
                                      ${parseFloat(earningsInUsd).toFixed(10)}
                                    </span>
                                    {/* {chainsConfigs[numberToChainId[this.state.nft.blockChain]]} */}
                                    <span className="eth-val ml-4">
                                      {parseFloat(earnings).toFixed(10)}
                                      {`${selectedCurrency}`}
                                    </span>
                                  </div>
                                </div>
                                {nft?.collection?.royalty ? (
                                  <div className="d-flex content justify-content-between align-items-center">
                                    <h5>Creator Royalty</h5>
                                    <div>
                                      <span>
                                        ${parseFloat(earningsInUsd).toFixed(10)}
                                      </span>
                                      {/* {chainsConfigs[numberToChainId[this.state.nft.blockChain]]} */}
                                      <span className="eth-val ml-4">
                                        {nft.collection.royalty || 0.0}%
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                          <div className="price-wrapper">
                            <div className="price-text">
                              <div className="text-white">
                                <b>Schedule Listing</b>
                              </div>
                              <div className="date-picker">
                                <DateRangePicker
                                  initialSettings={dateRangeInitialSettings}
                                  onApply={handleDateChange}
                                >
                                  <button
                                    name="fixedPriceConfig.listingSchedule"
                                    className="calender-btn"
                                  >
                                    {fixedPriceConfig.listingSchedule.startDate.format(
                                      "MMMM D, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {fixedPriceConfig.listingSchedule.endDate.format(
                                      "MMMM D, YYYY"
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-calendar-week" />
                                  </button>
                                </DateRangePicker>
                              </div>

                              <div className="d-flex auction-time-handlers justify-content-between">
                                <div className="budle-wrapper">
                                  <input
                                    type="time"
                                    name="fixedPriceConfig.listingSchedule.startTime"
                                    placeholder="Start Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      fixedPriceConfig.listingSchedule.startTime
                                    }
                                    required
                                  />
                                </div>
                                <div className="budle-wrapper ml-3">
                                  <input
                                    type="time"
                                    name="fixedPriceConfig.listingSchedule.endTime"
                                    placeholder="End Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      fixedPriceConfig.listingSchedule.endTime
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label
                                    className={`text-danger pl-1 ${
                                      priceMsg.startTimeMsg ? `` : `d-none`
                                    }`}
                                  >
                                    {priceMsg.startTimeMsg}
                                  </label>
                                </div>
                                <div>
                                  <label
                                    className={`text-danger pl-1 ${
                                      priceMsg.endTimeMsg ? `` : `d-none`
                                    }`}
                                  >
                                    {priceMsg.endTimeMsg}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CODE COMMENTED TEMPORARILY */}
                          {/* <div className="price-wrapper d-flex">
						<div className="price-text">
							<div className="text-white">
							<b>
								Reserve for
								specific buyer
							</b>
							</div>
						</div>
						<div>
							<label className="switch">
							<input
								type="checkbox"
								checked={
								reserveBuyer
								}
								onChange={() =>
								setReserveBuyer(
									!reserveBuyer
								)
								}
							/>
							<span className="slider round"></span>
							</label>
						</div>
						</div> */}
                          {reserveBuyer && (
                            <div
                              className="mb-3 budle-wrapper"
                              style={{
                                borderBottom: "1px solid",
                                paddingBottom: "2rem",
                              }}
                            >
                              <span>
                                <input
                                  type="text"
                                  placeholder="0xf45a189..."
                                  required
                                  defaultValue={fixedPriceConfig.reserveFor}
                                  onChange={(e) => {
                                    onChange(e);
                                    handlePrice(e, "fPrice");
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setPriceMsg({
                                          ...priceMsg,
                                          reserveFor: "",
                                        });
                                      } else {
                                        setPriceMsg({
                                          ...priceMsg,
                                          reserveFor: "Address Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  name="fixedPriceConfig.reserveFor"
                                />
                              </span>
                              <div>
                                <label
                                  className={`text-danger pl-1 ${
                                    priceMsg.reserveFor ? `` : `d-none`
                                  }`}
                                >
                                  {priceMsg.reserveFor}
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {sellingMethod === 2 && (
                        <div className="auction-tab">
                          <div className="form-group">
                            <div className="d-flex justify-content-between mb-3">
                              <div className="mb-2 text-white">
                                <b>Method</b>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                data-effect="float"
                                data-tip="Sell to the highest bidder or sell with the declining price, which allows the listing to reduce the price until a buyer is found."
                              >
                                <i className="fas fa-exclamation-circle ml-2" />
                              </div>
                            </div>
                            <select
                              className="form-control"
                              id="timed-auction-method"
                              name="timedAuctionConfig.method"
                              value={timedAuctionConfig.method}
                              onChange={(e) => onChange(e)}
                            >
                              <option value={1}>
                                Sell to the highest bidder
                              </option>
                              <option value={2}>
                                Sell with the declining price
                              </option>
                            </select>
                          </div>
                          {/* <div className="price-text position-relative">
													<div className="d-flex justify-content-between mb-3">
														<div className="mb-2 text-white"><b>Blockchain</b></div>
														<div className="d-flex align-items-center cursor-pointer" data-effect="float" data-tip="List price and listing schedule cannot be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price and dates.">
															<i className="fas fa-exclamation-circle ml-2" />
														</div>
													</div>
													<div className="d-flex justify-content-between flex-column flex-sm-row">
														<select className="form-control" id="blockChain" name="timedAuctionConfig.startPrice.blockChain" value={timedAuctionConfig.startPrice.blockChain} onChange={(e) => onChange(e)}>
															<option className="" value="">Select Blockchain</option>
															{
																blockChains && blockChains.map((blockChain, index) => {
																	return (
																		<option key={index} value={blockChain}>{blockChain}</option>
																	)
																})
															}
														</select>
													</div>
													 
 													{
														submitCheck && priceMsg?.blockChain && //timedAuctionConfig.startPrice.amount &&
														<div>
															<label className={`text-danger pl-1 ${priceMsg.currency ? `` : `d-none`}`}>{priceMsg.currency}</label>
														</div>
													}
												</div> */}
                          <div className="price-wrapper d-flex">
                            <div className="price-text position-relative">
                              <div className="d-flex justify-content-between mb-3">
                                <b className="text-white">Starting Price</b>
                              </div>
                              <div className="d-flex nft-sell-select">
                                <select
                                  className="form-control mr-3"
                                  id="currency"
                                  name="timedAuctionConfig.startPrice.currency"
                                  value={timedAuctionConfig.startPrice.currency}
                                  onChange={(e) => {
                                    handlePrice(e, "minBid");
                                    onChange(e);
                                    timedAuctionConfig.startPrice.amount = 0;
                                  }}
                                >
                                  <option className="" value="">
                                    Select Currency
                                  </option>
                                  {paymentTokens &&
                                    paymentTokens.map((token, index) => {
                                      return (
                                        <option key={index} value={token}>
                                          {token}
                                        </option>
                                      );
                                    })}
                                </select>
                                <input
                                  type="text"
                                  name="timedAuctionConfig.startPrice.amount"
                                  placeholder="Amount"
                                  className="amount-btn"
                                  style={{
                                    borderRadius: "15px",
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handlePrice(e, "minBid");
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setAuctionMsg({
                                          ...auctionMsg,
                                          amount: "",
                                        });
                                      } else {
                                        setAuctionMsg({
                                          ...auctionMsg,
                                          amount: "Amount Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  onKeyDown={(e) => decimalNumberValidator(e)}
                                  value={timedAuctionConfig.startPrice.amount}
                                  required
                                />
                                <div className="text-right mb-2 dollar-wrapper">
                                  ${" "}
                                  {
                                    getPrice()
                                    // props.app.rateAuth && (timedAuctionConfig.startPrice.currency === 'BNB') && Number(timedAuctionConfig.startPrice.amount) ?
                                    // 	ENV.convertXtoY(timedAuctionConfig.startPrice.amount, props.app.rate)
                                    // 	: props.app.ethRateAuth && (timedAuctionConfig.startPrice.currency === 'ETH') && Number(timedAuctionConfig.startPrice.amount) ?
                                    // 		ENV.convertXtoY(timedAuctionConfig.startPrice.amount, props.app.ethRate)
                                    // 		: props.app.fantomRateAuth && (timedAuctionConfig.startPrice.currency === 'FTM') && Number(timedAuctionConfig.startPrice.amount) ?
                                    // 			ENV.convertXtoY(timedAuctionConfig.startPrice.amount, props.app.fantomRate)
                                    // 			: props.app.croRateAuth && timedAuctionConfig.startPrice.currency === 'CRO' && Number(timedAuctionConfig.startPrice.amount) ?
                                    // 				ENV.convertXtoY(timedAuctionConfig.startPrice.amount, props.app.croRate)
                                    // 				: '0.00'
                                  }
                                </div>
                              </div>
                              <div>
                                <label
                                  className={`text-danger pl-1 ${
                                    auctionMsg.amount ? `` : `d-none`
                                  }`}
                                >
                                  {auctionMsg.amount}
                                </label>
                              </div>
                              {submitCheck &&
                                auctionMsg?.currency &&
                                timedAuctionConfig.startPrice.amount && (
                                  <div>
                                    <label
                                      className={`text-danger pl-1 ${
                                        auctionMsg?.currency ? `` : `d-none`
                                      }`}
                                    >
                                      {auctionMsg.currency}
                                    </label>
                                  </div>
                                )}
                            </div>
                          </div>
                          {serviceFee > 0 && (
                            <div className="fees-earning mt-4">
                              <div className="d-flex content custom-border justify-content-between align-items-center">
                                <h5>MetaMotu Service fee</h5>
                                <div className="d-flex align-items-center">
                                  <span>
                                    ${parseFloat(serviceFeeInUsd).toFixed(10)}
                                  </span>
                                  <span className="eth-val ml-4">
                                    {parseFloat(chainRate).toFixed(10)}{" "}
                                    {` ` + selectedCurrency}
                                  </span>
                                  {sharePercentages &&
                                  sharePercentages.platformShare ? (
                                    <span className="eth-val ml-4">
                                      {parseFloat(
                                        sharePercentages.platformShare
                                      ).toFixed(2)}
                                      %
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {(sharePercentages &&
                                  sharePercentages.platformShare) ||
                                (sharePercentages &&
                                  sharePercentages.royaltyShare) ? (
                                  <div className="d-flex justify-content-between my-3">
                                    <ReactTooltip />
                                    <div
                                      className="text-right mb-2 cursor-pointer"
                                      data-effect="float"
                                      data-tip="Listing is free. Once sold, the following fees will be deducted."
                                    >
                                      <i className="fas fa-exclamation-circle" />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-between my-3"></div>
                                )}
                              </div>

                              <div className="d-flex content justify-content-between align-items-center">
                                <h5>Your earnings</h5>
                                <div>
                                  <span>
                                    ${parseFloat(earningsInUsd).toFixed(10)}
                                  </span>
                                  {/* {chainsConfigs[numberToChainId[this.state.nft.blockChain]]} */}
                                  <span className="eth-val ml-4">
                                    {parseFloat(earnings).toFixed(10)}
                                    {`${selectedCurrency}`}
                                  </span>
                                </div>
                              </div>
                              {nft?.collection?.royalty ? (
                                <div className="d-flex content justify-content-between align-items-center">
                                  <h5>Creator Royalty</h5>
                                  <div>
                                    <span>
                                      ${parseFloat(earningsInUsd).toFixed(10)}
                                    </span>
                                    {/* {chainsConfigs[numberToChainId[this.state.nft.blockChain]]} */}
                                    <span className="eth-val ml-4">
                                      {nft.collection.royalty || 0.0}%
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          <div className="price-wrapper">
                            <div className="price-text">
                              <div className="text-white">
                                <b>Duration</b>
                              </div>
                              <div className="date-picker">
                                <DateRangePicker
                                  initialSettings={dateRangeInitialSettings}
                                  onApply={handleDateChange}
                                >
                                  <button
                                    name="timedAuctionConfig.duration"
                                    className="calender-btn"
                                    data-toggle="tooltip"
                                    title="Date"
                                  >
                                    <i className="fas fa-calendar-week" />
                                    &nbsp;&nbsp;&nbsp;
                                    {timedAuctionConfig.duration.startDate.format(
                                      "MMMM D, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {timedAuctionConfig.duration.endDate.format(
                                      "MMMM D, YYYY"
                                    )}
                                  </button>
                                </DateRangePicker>
                              </div>

                              <div className="row">
                                <div className="mr-auto my-3 budle-wrapper col">
                                  <input
                                    type="time"
                                    name="timedAuctionConfig.duration.startTime"
                                    placeholder="Start Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      timedAuctionConfig.duration.startTime
                                    }
                                    required
                                  />
                                </div>

                                <div className="ml-auto my-3 budle-wrapper col">
                                  <input
                                    type="time"
                                    name="timedAuctionConfig.duration.endTime"
                                    placeholder="End Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      timedAuctionConfig.duration.endTime
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                <div>
                                  <label
                                    className={`text-danger pl-1 ${
                                      auctionMsg.startTimeMsg ? `` : `d-none`
                                    }`}
                                  >
                                    {auctionMsg.startTimeMsg}
                                  </label>
                                </div>
                                <div>
                                  <label
                                    className={`text-danger pl-1 ${
                                      auctionMsg.endTimeMsg ? `` : `d-none`
                                    }`}
                                  >
                                    {auctionMsg.endTimeMsg}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {parseInt(timedAuctionConfig.method) === 2 && (
                            <div className="price-wrapper d-flex">
                              <div className="price-text position-relative">
                                <div className="d-flex justify-content-between mb-3">
                                  <b>Ending Price</b>
                                </div>
                                <div className="d-flex">
                                  <button
                                    type="button"
                                    className="ethereum-btn mb-3 mb-sm-0"
                                  >
                                    <span className="ml-2">
                                      {timedAuctionConfig.endPrice.currency}
                                    </span>
                                  </button>
                                  <input
                                    type="text"
                                    name="timedAuctionConfig.endPrice.amount"
                                    placeholder="Amount"
                                    className="amount-btn ml-3"
                                    style={{
                                      borderRadius: "15px",
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      if (submitCheck) {
                                        if (e.target.value) {
                                          setAuctionMsg({
                                            ...auctionMsg,
                                            eamount: "",
                                          });
                                        } else {
                                          setAuctionMsg({
                                            ...auctionMsg,
                                            eamount: "Amount Is Required.",
                                          });
                                        }
                                      }
                                    }}
                                    onKeyDown={(e) => decimalNumberValidator(e)}
                                    value={timedAuctionConfig.endPrice.amount}
                                    required
                                  />
                                  <div className="text-right mb-2 dollar-wrapper">
                                    ${" "}
                                    {
                                      getPrice()
                                      // props.app.rateAuth && (timedAuctionConfig.endPrice.currency === 'BNB') && Number(timedAuctionConfig.endPrice.amount) ?
                                      // 	ENV.convertXtoY(timedAuctionConfig.endPrice.amount, props.app.rate)
                                      // 	:
                                      // 	props.app.ethRateAuth && (timedAuctionConfig.endPrice.currency === 'ETH') && Number(timedAuctionConfig.endPrice.amount) ?
                                      // 		ENV.convertXtoY(timedAuctionConfig.endPrice.amount, props.app.ethRate)
                                      // 		: props.app.fantomRateAuth && (timedAuctionConfig.endPrice.currency === 'FTM') && Number(timedAuctionConfig.endPrice.amount) ?
                                      // 			ENV.convertXtoY(timedAuctionConfig.endPrice.amount, props.app.fantomRate)
                                      // 			: props.app.croRateAuth && timedAuctionConfig.endPrice.currency === 'CRO' && Number(timedAuctionConfig.endPrice.amount) ?
                                      // 				ENV.convertXtoY(timedAuctionConfig.endPrice.amount, props.app.croRate)
                                      // 				: '0.001'
                                    }
                                  </div>
                                </div>
                                <div>
                                  <label
                                    className={`text-danger pl-1 ${
                                      auctionMsg.eamount ? `` : `d-none`
                                    }`}
                                  >
                                    {auctionMsg.eamount}
                                  </label>
                                </div>
                                {submitCheck &&
                                  auctionMsg?.ecurrency &&
                                  timedAuctionConfig.endPrice.amount && (
                                    <div>
                                      <label
                                        className={`text-danger pl-1 ${
                                          auctionMsg?.ecurrency ? `` : `d-none`
                                        }`}
                                      >
                                        {auctionMsg.ecurrency}
                                      </label>
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                          {parseInt(timedAuctionConfig.method) === 1 && (
                            <div className="reverse-price d-flex">
                              <div className="price-text">
                                <div className="text-white">
                                  <b>Include reserve price</b>
                                </div>
                              </div>
                              <div>
                                <span
                                  className="text-right mb-2 cursor-pointer mr-3"
                                  data-effect="float"
                                  data-tip="If you do not receive any bids equal to or greater than your reserve, the auction will end without a sale."
                                >
                                  <i className="fas fa-exclamation-circle ml-2" />
                                </span>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={reservePrice}
                                    onChange={() =>
                                      setReservePrice(!reservePrice)
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          )}
                          {reservePrice && (
                            <>
                              <div className="d-flex">
                                <button
                                  type="button"
                                  className="ethereum-btn mb-3 mb-sm-0"
                                >
                                  <span className="ml-2">
                                    {timedAuctionConfig.reservePrice.currency}
                                  </span>
                                </button>
                                <input
                                  type="text"
                                  name="timedAuctionConfig.reservePrice.amount"
                                  placeholder="Amount"
                                  className="amount-btn ml-3"
                                  style={{
                                    borderRadius: "15px",
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setAuctionMsg({
                                          ...auctionMsg,
                                          ramount: "",
                                        });
                                      } else {
                                        setAuctionMsg({
                                          ...auctionMsg,
                                          ramount: "Amount Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  onKeyDown={(e) => decimalNumberValidator(e)}
                                  defaultValue={
                                    timedAuctionConfig.reservePrice.amount
                                  }
                                  required
                                />
                              </div>

                              <div className="text-right mb-2">
                                ${" "}
                                {
                                  getPrice()
                                  // props.app.rateAuth && (timedAuctionConfig.reservePrice.currency === 'BNB') && Number(timedAuctionConfig.reservePrice.amount) ?
                                  // 	ENV.convertXtoY(timedAuctionConfig.reservePrice.amount, props.app.rate)
                                  // 	:
                                  // 	props.app.ethRateAuth && (timedAuctionConfig.reservePrice.currency === 'ETH') && Number(timedAuctionConfig.reservePrice.amount) ?
                                  // 		ENV.convertXtoY(timedAuctionConfig.reservePrice.amount, props.app.ethRate)
                                  // 		: props.app.fantomRateAuth && (timedAuctionConfig.reservePrice.currency === 'FTM') && Number(timedAuctionConfig.reservePrice.amount) ?
                                  // 			ENV.convertXtoY(timedAuctionConfig.reservePrice.amount, props.app.fantomRate)
                                  // 			: props.app.croRateAuth && timedAuctionConfig.reservePrice.currency === 'CRO' && Number(timedAuctionConfig.reservePrice.amount) ?
                                  // 				ENV.convertXtoY(timedAuctionConfig.reservePrice.amount, props.app.croRate)
                                  // 				: '0.001'
                                }
                              </div>
                            </>
                          )}
                          <div>
                            <label
                              className={`text-danger pl-1 ${
                                auctionMsg.ramount ? `` : `d-none`
                              }`}
                            >
                              {auctionMsg.ramount}
                            </label>
                          </div>
                          {submitCheck &&
                            auctionMsg?.rcurrency &&
                            timedAuctionConfig.reservePrice.amount && (
                              <div>
                                <label
                                  className={`text-danger pl-1 ${
                                    auctionMsg.rcurrency ? `` : `d-none`
                                  }`}
                                >
                                  {auctionMsg.rcurrency}
                                </label>
                              </div>
                            )}
                        </div>
                      )}

                      <div className="complete-listing-btn">
                        <button
                          className="btn btn-danger offer p-3"
                          onClick={() => submit()}
                        >
                          Complete Listing
                        </button>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 sell-nft-content ">
                <div className="connect-wallet mb-5">
                  <h4>Connected Wallet</h4>
                  <div className="connect d-flex align-items-center ">
                    {/* currenciesImages */}
                    <div>
                      <img
                        src={currenciesImages[Number(seletedNetwork)]}
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="content flex-grow-1">
                      <h3>{ENV.formatAddress(connectedAddress)}</h3>
                      <p>{ENV.requiredChainName[Number(seletedNetwork)]}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        className="fa-gradient"
                        icon={faCircleCheck}
                      />
                    </div>
                  </div>
                </div>
                <div className="nft-priview">
                  <h4>Preview of your NFT</h4>
                </div>
                <div>{nft && <NFTPreview {...nft} hideCloseBtn={true} />}</div>
                {
                  <div className="about-nft d-flex  align-items-center justify-content-between pt-4">
                    <div>
                      <h3>{nft.collection.name}</h3>
                      <p>{nft.name}</p>
                    </div>
                    <div className="nft-category-data custom-price-input ">
                      <InputGroup>
                        <Form.Control
                          placeholder="Price: "
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          readOnly
                        />
                        <InputGroup.Text
                          className="text-light p-3 text-start"
                          id="basic-addon2"
                        >
                          <span className="nft-category-price pr-2">
                            {sellingMethod === 1
                              ? fixedPriceConfig?.price?.amount
                              : timedAuctionConfig?.startPrice.amount}
                          </span>
                          <span className="nft-category-price">
                            {nft.currency}
                          </span>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  nft: state.nft,
  error: state.error,
  app: state.app,
  settings: state.settings,
});

export default connect(mapStateToProps, {
  beforeNFT,
  getNFT,
  upsertNFT,
  beforeSettings,
  getSettings,
  getTokenSettings,
})(SellNFT);
