import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { beforeHome, getTopCollection } from '../../homepage/home.action';
import { ENV } from "../../../config/config";
import { Col, Row, Container, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import FullPageLoader from "../../../components/full-page-loader/full-page-loader";
import CustomHeading from "../custom-heading/CustomHeading";
import TopCard from "../top-card/TopCard";
import CustomButton from "../custom-button/CustomButton";
import "../top-collections/top-collections.css";
const itemPlaceholderImg = ENV.collectionFeaturedImg

const TopCollections = (props) => {
  const [collection, setCollection] = useState(null)
  const [pagination, setPagination] = useState({})
  const blockChain = [
    {value: 'eth', label: 'Ethereum'},
    {value: 'bnb', label: 'Binance'},
    {value: 'ftm', label: 'Fantom'},
    {value: 'cro', label: 'Cronos'}
  ]
  const sort = [
      { value: 'v24h', label: '24 Hours' },
      { value: 'v7d', label: '7 Days' },
      { value: 'v30d', label: '30 Days' }
  ]
  // for filters only
  const [filter, setFilter] = useState({
    sortByVol: "v24h",
    blockchain: "eth"
  })

  const [volume, setVolume] = useState({
    ETH: 0.0,
    BNB: 0.0,
    CRO: 0.0,
    FTM: 0.0
  })

  const [usdVolume, setUsdVolume] = useState(0.0)

	useEffect(() => {
	  getTopCollection(filter);
	}, [])

	useEffect(() => {
		if(props.home.topCollectionAuth){
      if(props.home.topCollection.stats === null){
        setCollection(null)
      }else{
        setCollection(props.home.topCollection.stats)
      }      
      setPagination(props.home.topCollection.pagination)
			props.beforeHome()
		}

	}, [props.home.topCollectionAuth])

  useEffect(() => {
    let eth = 0.0
    let bnb = 0.0
    let cro = 0.0
    let ftm = 0.0
    
    collection?.map((i, index) => {
      if(i.currency === 'ETH'){
        eth += i.totalValue
      }else if(i.currency === 'BNB'){
        bnb += i.totalValue
      }else if(i.currency === 'CRO'){
        cro += i.totalValue
      }else if(i.currency === 'FTM'){
        ftm += i.totalValue
      }
    })

    setVolume({
      ETH: eth,
      BNB: bnb,
      CRO: cro,
      FTM: ftm
    })

  }, [collection])

  useEffect(() => {
    if(props.app){
      if(volume.ETH !== 0.0 && props.app.ethRateAuth){
        let convertToUsd = ENV.convertRateToUsd(volume.ETH, props.app.ethRate)
        setUsdVolume(convertToUsd)
      }
      else if(volume.BNB !== 0.0 && props.app.rateAuth){
        let convertToUsd = ENV.convertRateToUsd(volume.BNB, props.app.rate)
        setUsdVolume(convertToUsd)
      }
      else if(volume.FTM !== 0.0 && props.app.fantomRateAuth){
        let convertToUsd = ENV.convertRateToUsd(volume.FTM, props.app.fantomRate)
        setUsdVolume(convertToUsd)
      }
      else if(volume.CRO !== 0.0 && props.app.wcroRateAuth){
        let convertToUsd = ENV.convertRateToUsd(volume.CRO, props.app.wcroRate)
        setUsdVolume(convertToUsd)
      }
    }
  }, [volume, props.app])
  
  

  const getTopCollection = (filter) => {
    const qs = ENV.objectToQueryString(filter)
    props.getTopCollection(qs)
  }

  const handleSortChange = (eventKey) => {
    let selectedOption = eventKey;
    const filterData = {
        ...filter,
    }
    if (selectedOption) {
        filterData['sortByVol'] = selectedOption
    }
    else {
        delete filterData['sortByVol']
    }
    setFilter(filterData)
    getTopCollection(filterData)
  }

  const handleCurrencyChange = (selectedOption) => {
    const filterData = {
        ...filter,
    }
    if (selectedOption) {
        filterData['blockchain'] = selectedOption
    }
    else {
        delete filterData['blockchain']
    }
    setFilter(filterData)
    getTopCollection(filterData)
  }

  return (
    <>
      <section className="top-collection">
        <Container fluid className="custom-container">
          <Row className="align-items-start">
            <Col lg={4} sm={12} >
              <CustomHeading heading="Top" collection="collections" />
            </Col>
            <Col lg={4} md={6} className="pt-3 text-sm-center">
              <div className="tab-area  d-flex align-items-center justify-content-lg-center ">
                <Nav variant="pills" className="bg-gray text-white" defaultActiveKey="v24h" onSelect={handleSortChange}>
                  {sort.map((item, index) => {
                    return (
                      <Nav.Item key={index}>
                        <Nav.Link eventKey={item.value}>{item.label}</Nav.Link>
                      </Nav.Item>
                    )
                  })}
                </Nav>
              </div>
              { collection && <div><h4 className="text-center total-volumn pt-3">Total Volume - ${parseFloat(usdVolume).toFixed(2)} USD</h4></div>}
            </Col>
            <Col lg={4} md={6} className="pt-3 d-flex align-items-center justify-content-xl-end justify-content-lg-end   ">
             {filter.blockchain && blockChain && <div className="top-dropdown bg-gray  text-sm-center">
                <DropdownButton 
                  variant="none" 
                  id="custom-dropdown" 
               
                  title={blockChain.filter(item => item.value === filter.blockchain)[0]?.label}
                  onSelect={handleCurrencyChange}
                 
                  
                >
                  {blockChain.map((item, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={item.value}>{item.label}</Dropdown.Item>
                    )
                  })}
                  
                </DropdownButton>
              </div>}
            </Col>
     
         
            <Col md={12}>
              <TopCard filter={filter} stats={collection}/>
            </Col>
            <Col md={12}>
              <CustomButton pagination={pagination}/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

const mapStateToProps = state => ({
	home: state.home,
  app: state.app
});

export default connect(mapStateToProps, { beforeHome, getTopCollection })(TopCollections);