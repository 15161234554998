import React, { useEffect } from "react";
import Web3 from "web3";
import Header from '../../components/home/header/Header';
import Footer from '../../components/home/footer/Footer';
import ModalSearch from '../../components/modal-search/modal-search';
import ModalMenu from '../../components/modal-menu/modal-menu';
import Scrollup from '../../components/scroll-up/sroll-up';
import FullPageLoader from '../../components/full-page-loader/full-page-loader';
import $, { data } from 'jquery'
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import connectors from '../../components/wallet/connector';
// import { isFocusable } from "@testing-library/user-event/dist/types/utils";
const Layout2 = (props) => {
    useEffect(() => {
        if (localStorage.getItem("theme")) {
            const selectedTheme = localStorage.getItem("theme")
            document.body.classList.toggle(selectedTheme == "light" ? 'light-mode' : 'dark-mode');
        }
    }, [])

    const { account, activate, library } = useWeb3React();
    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            const connector = connectors[provider].provider;
            activate(connector, undefined, true)
                .then(async (res) => {
                    const web3Provider = await connector.getProvider();
                    const web3 = new Web3(web3Provider);
                    window.walletPO = web3
                })
                .catch((error) => {
                    //error
                });
        }
    }, [])
    useEffect(() => {
        if (account) {
            localStorage.setItem('connectedAddress', account);
        }
    }, [account])

    useEffect(() => {
        if (library) {
            window.library = library;
        }
    }, [library])



    return (
        <div className="main">
            <Header />
            {props.children}
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        wallet: state.wallet,
    }
}
export default connect(mapStateToProps, null)(Layout2);
