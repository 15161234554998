import "../banner/banner.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { beforeNFT, searchNft } from "../../nfts/nfts.action";
import { Col, Container, Row, Form } from "react-bootstrap";
import { ENV } from "../../../config/config";
import arrowImage from "../../../assets/images/arrow.svg";
import arrowImageHover from "../../../assets/images/arrow-hover.svg";
import gradientImage from "../../../assets/images/gardient.png";
import CustomCard from "../custom-card/Custom-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TypeAnimation } from "react-type-animation";

import "../banner/banner.css";
const collectionPlaceholderImg = ENV.userDefaultImg;
const authorPlaceholderImg = ENV.userDefaultImg;

const Banner = (props) => {
  const [keyword, setKeyword] = useState("");
  const [searchData, setSearchData] = useState({});
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  const search = () => {
    if (keyword !== "") props.searchNft(keyword.replace("#", ""));
  };

  useEffect(() => {
    if (props.nft.searchAuth) {
      setSearchData(props.nft.searchData);
      setCheck(true);
    }
  }, [props.nft.searchAuth]);

  const handleOnChange = (event) => {
    setCheck(false);
    setKeyword(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    search();
  };

  const CloseSearchDiv = () => {
    setCheck(false);
  };

  return (
    <>
      <section className="banner">
        <Container fluid className="custom-container">
          <Row>
            <Col lg={6}>
              <div className="banner-content">
                <TypeAnimation
                  sequence={["", 1000, " Be in your", 2000, () => {}]}
                  wrapper="span"
                  cursor={true}
                  repeat={Infinity}
                />
                <TypeAnimation
                  sequence={["", 1000, "Mutoplace", 2000, () => {}]}
                  wrapper="h1"
                  cursor={true}
                  repeat={Infinity}
                />
                {/* <span>Be in your</span> */}
                {/* <h1> Mutoplace</h1> */}
                <p id="typed">
                  Explore, mint, sell and auction blue chip art and{" "}
                  <strong className="custom-border-bottom">NFTs</strong>{" "}
                  worldwide.
                </p>
                <Form onSubmit={submitHandler} className="custum-header-input">
                  <button
                    className="dropdown-toggle custom-drop-down-toggle"
                    onClick={search}
                    type="button"
                    id="dropdownMenuButton"
                    // data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                  </button>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      onChange={handleOnChange}
                      placeholder="Search Collections, Artists, NFTs or Users"
                    />
                    {check && (
                      <>
                        {keyword !== "" && (
                          <>
                            {searchData.nfts?.length > 0 ||
                            searchData.collections?.length > 0 ||
                            searchData.creators?.length > 0 ? (
                              <div className="dropdown">
                                <div
                                  className="dropdown-menu custom-dropdown-menu-2 show"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div className="banner-search-close-icon">
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      onClick={CloseSearchDiv}
                                    />
                                  </div>

                                  {searchData.nfts?.length > 0 ? (
                                    <>
                                      <p>NFTs</p>

                                      {searchData.nfts.map((item, index) => {
                                        return (
                                          <>
                                            <div
                                              className="d-flex align-items-center mb-3 px-3"
                                              key={index}
                                            >
                                              <div className="search-image">
                                                <img
                                                  src={
                                                    item.image
                                                      ? item.image
                                                      : collectionPlaceholderImg
                                                  }
                                                  className="img-fluid"
                                                  alt="image"
                                                />
                                              </div>
                                              <a
                                                className="dropdown-item"
                                                href={`/item-details/${window.btoa(
                                                  item._id
                                                )}`}
                                              >
                                                {item.name}
                                              </a>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : null}
                                  {searchData.collections?.length > 0 ? (
                                    <>
                                      {/* <li className="dropdown-divider"></li> */}
                                      <p className="dropdown-category-heading">
                                        Collections
                                      </p>
                                      {searchData.collections.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <div
                                                className="d-flex align-items-center mb-3 px-3"
                                                key={index}
                                              >
                                                <div className="search-image">
                                                  <img
                                                    src={
                                                      item.logo
                                                        ? item.logo
                                                        : collectionPlaceholderImg
                                                    }
                                                    className="img-fluid"
                                                    alt="image"
                                                  />
                                                </div>
                                                <a
                                                  className="dropdown-item"
                                                  href={`/collection/${item.url}`}
                                                >
                                                  {item.name}
                                                </a>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                  {searchData.creators?.length > 0 ? (
                                    <>
                                      {/* <li className="dropdown-divider"></li> */}
                                      <p>Authors</p>
                                      {searchData.creators.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <div
                                                className="d-flex align-items-center mb-3 px-3"
                                                key={index}
                                              >
                                                <div className="search-image">
                                                  <img
                                                    src={
                                                      item.profileImage
                                                        ? item.profileImage
                                                        : authorPlaceholderImg
                                                    }
                                                    className="img-fluid"
                                                    alt="image"
                                                  />
                                                </div>
                                                <a
                                                  className="dropdown-item"
                                                  href={`/artist/${item.username}`}
                                                >
                                                  {item.username}
                                                </a>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <div className="dropdown">
                                <div
                                  className="dropdown-menu custom-dropdown-menu-1 show"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <h6 className="text-center no-search-found">
                                    No Search Found
                                  </h6>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Form.Group>
                </Form>

                <div className="d-flex align-items-center justify-content-center justify-content-lg-start explore">
                  <Link to="/explore-all" className="explore-now">
                    {" "}
                    Explore Now{" "}
                    <img
                      src={arrowImage}
                      className="img-fluid banner-arrow "
                      alt="Arow image"
                    />
                    <img
                      src={arrowImageHover}
                      className="img-fluid banner-arrow-hover "
                      alt="Arow image"
                    />
                  </Link>
                  <Link to="/how-it-works" className="how-works">
                    {" "}
                    How it works
                  </Link>
                </div>
                <div className="supported-blocks">
                  <p>View Supported Blocks</p>
                  <div className="d-flex justify-content-md-center justify-content-sm-center justify-content-lg-start blockchain-patforms">
                    <div
                      className="custom-blockchain-link"
                      onClick={() => {
                        navigate("/explore-all", {
                          state: { value: 1, label: "Ethereum" },
                        });
                      }}
                    >
                      Ethereum
                    </div>
                    <div
                      className="custom-blockchain-link"
                      onClick={() => {
                        navigate("/explore-all", {
                          state: { value: 2, label: "Binance" },
                        });
                      }}
                    >
                      Binance Smart Chain
                    </div>
                    <div
                      className="custom-blockchain-link"
                      onClick={() => {
                        navigate("/explore-all", {
                          state: { value: 3, label: "Cronos" },
                        });
                      }}
                    >
                      Cronos
                    </div>
                    <div
                      className="custom-blockchain-link"
                      onClick={() => {
                        navigate("/explore-all", {
                          state: { value: 4, label: "Fantom" },
                        });
                      }}
                    >
                      Fantom
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="pt-5 banner-card-area">
              <span className="gradient-image">
                {" "}
                <img
                  src={gradientImage}
                  className="img-fluid"
                  alt="Arow image"
                />{" "}
              </span>
              <CustomCard />
              <p className="collectible">Featured Collectible</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  nft: state.nft,
  error: state.error,
});

export default connect(mapStateToProps, {
  beforeNFT,
  searchNft,
})(Banner);
