/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Scrollup = () => {
    return (
        <div id="scroll-to-top" className="scroll-to-top" data-toggle="tooltip" title="Arrow">
            <a data-target="#" className="smooth-anchor">
                <i className="fas fa-arrow-up" />
            </a>
        </div>
    );
};

export default Scrollup;