import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FullPageLoader from "../full-page-loader/full-page-loader";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { ENV } from "../../config/config";
import { Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { beforeOrder, getOrders, editOrder } from "./order.action";
import { useDebugValue } from "react";

const Order = (props) => {
  const [loader, setLoader] = useState(false);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    props.getOrders();
  }, [props.orders.upsertOrderAuth]);
  useEffect(() => {
    if (props.orders.getOrderAuth) {
      console.log("props.", props.order);
      setLoader(false);
    }
  }, [props.orders.getOrderAuth]);

  const onPageChange = async (page) => {
    getOrders({ page });
  };
  const getOrders = (filter) => {
    setLoader(true);
    const body = ENV.objectToQueryString(filter);
    props.listTrade(body);
  };
  return (
    <section className="activity-area load-more trading-history-area">
      {loader && <FullPageLoader />}
      <div className="container">
        {/* intro */}
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-7">
            <div className="intro text-center">
              <h3 className="mt-3 mb-0">Orders</h3>
            </div>
          </div>
        </div>

        {/* filters */}
        <div className="my-4">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group date-ranger-flex d-flex">
                  <div className="pt-3 pr-3">
                    {/* <DateRangePicker onApply={handleEvent}>
                  <Button variant="primary">
                    {dispDate.startDate
                      ? dispDate.startDate + " To " + dispDate.endDate
                      : "Select Date"}
                  </Button>
                </DateRangePicker> */}
                    {/* {dispDate.startDate ? (
                  <span className="pl-3">
                    <Button variant="primary">Reset</Button>
                  </span>
                ) : (
                  ""
                )} */}
                  </div>
                  <div>
                    {/* <h4>Total Volume: {totalVol.toFixed(4)} $</h4> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* rankings */}
        <div className="row items">
          <div className="col-12 table-responsive">
            <table className="table table-borderless table-dark">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Type</th>
                  <th scope="col">Volume</th>
                </tr>
              </thead>
              <tbody>
                {/* {
                        tradeList && tradeList.length ?
                            tradeList.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">{pagination && ((pagination.limit * pagination.page) - pagination.limit) + index + 1}</th>
                                        <td>{moment(item.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                        <td>{item.type}</td>
                                        <td>{item.price.amount} {item.price.currency} (${parseFloat(item.priceInDollars).toFixed(5)})</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan="4" className="text-center">No Data Found</td></tr>
                    } */}
              </tbody>
            </table>
            {pagination && pagination.total > 0 && (
              <Pagination
                defaultCurrent={1}
                pageSize // items per page
                current={pagination.page} // current active page
                total={pagination.pages} // total pages
                onChange={onPageChange}
                locale={localeInfo}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  orders: state.orders,
});

export default connect(mapStateToProps, { beforeOrder, getOrders, editOrder })(
  Order
);
