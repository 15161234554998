/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Explore from "../explore/explore-all";
import Follow from "../follow/follow";
import { getUserById, beforeUser, followToggle } from "./../user/user.action";
import FilterSearch from "../filter-search/filtersearch";
import { ENV } from "./../../config/config";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import ActivityTable from "../activity-table/activity-table";
import { Tabs, Tab, Container, Row, Col } from 'react-bootstrap'
import AboutSidebar from "../about-sidebar/about-sidebar";
import AboutNfts from "../about-nfts/about-nfts";
import checked from '../../assets/images/tik.png';
import EthIcon from '../../assets/images/ethireum-icon.png';
import Ethereum from '../../assets/images/arow-icon.png';
import Binance from '../../assets/images/binancelogo.png';
import Fantom from '../../assets/images/fantomlogo.png';
import Cronos from '../../assets/images/cronoslogo.png';
import { useWeb3React } from '@web3-react/core';
const { chainsConfigs } = ENV;

let chainsVsIcons = {
	5: Ethereum,
	97: Binance,
	338: Cronos,
	4002: Fantom,
	1: Ethereum,
	56: Binance,
	25: Cronos,
	250: Fantom
}


const Author = (props) => {

	const [authorProfile, setAuthorProfile] = useState(null);
	const [authorLoader, setAuthorLoader] = useState(true); // collections loader
	const [nftsLoader, setNftsLoader] = useState(true); // NFTs loader
	const [nftFilter, setNftFitler] = useState(null);
	const [Filter, setFilter] = useState(false);
	// const [chainId, setChainId] = useState('');
	const [key, setKey] = useState('about');
	const [authorId, setAuthorId] = useState('');
	const [collectedNfts, setCollectedNfts] = useState(0); // count for collected NFTs
	const [createdNfts, setCreatedNfts] = useState(0); // count for created NFTs
	const [isFollow, setIsFollow] = useState(false);
	const [ownId, setOwnId] = useState(false);
	const [authorUpdated, setAuthorUpdated] = useState(false);
	const [filters, setFilters] = useState({
		creation: false,
		offers: false,
		bids: false,
		acceptOffer: false,
		acceptBid: false,
		listings: false,
		sales: false,
		cancelListing: false
	});

	const [followersCount, setFollowersCount] = useState(0);
	const [followingsCount, setFollowingsCount] = useState(0)
	const [userId, setUserId] = useState(null)

	const params = useParams();
	const navigate = useNavigate()
	const { chainId } = useWeb3React();

	useEffect(() => {
		const { _id } = ENV.getUserKeys();
		setOwnId(_id)
		// getChainId().then(setChainId)
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (params) {
			if (params.authorId) {
				if (authorId !== params.authorId) {
					window.scroll(0, 0)
					const { authorId } = params
					setAuthorId(authorId)

					// get default states
					reset()

					// get author details
					props.getUserById(authorId, 1);
				}
			}
			else
				navigate("/artists")
		}
	}, [params])

	const reset = () => {
		setAuthorProfile(null);
		setAuthorLoader(true);
		setNftsLoader(true);
		setNftFitler(null);
		setFilter(false);
		// setChainId('');
		setKey('about');
		setCollectedNfts(0);
		setCreatedNfts(0);
	}

	useEffect(() => {
		if (props.user.individualUserAuth) {
			setAuthorProfile(props.user.individualUser);
			props.beforeUser(); // NEED TO FIND OUT
			setAuthorLoader(false);
		}
	}, [props.user.individualUserAuth]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (props.user.individualUser) {
			if (props.user.individualUser.isFollow === true) {
				setIsFollow(true)
				setFollowersCount(followersCount + 1)

			} else {
				setIsFollow(false)
				if (followersCount > 0)
					setFollowersCount(followersCount - 1)
			}

			if (props.user.individualUser.followersCount) {
				setFollowersCount(props.user.individualUser.followersCount)
			} else {
				setFollowersCount(0)
			}
			if (props.user.individualUser.followingsCount) {
				setFollowingsCount(props.user.individualUser.followingsCount)
			} else {
				setFollowingsCount(0)
			}
		}
	}, [props.user.individualUser])
	useEffect(() => {
		if (authorProfile && authorProfile.fromAuthor) {
			setAuthorUpdated(true);
		}
	}, [authorProfile])

	const getNftsLoader = (loader) => {
		setNftsLoader(loader);
	};

	const getCollectedNfts = (count) => {
		setCollectedNfts(count);
	};

	const getCreatedNfts = (count) => {
		setCreatedNfts(count);
	};

	const applyFilters = (filter) => {
		if (filter) {
			setNftsLoader(true)
			setNftFitler(filter)
		}
	}

	const handleFollowClick = (e) => {
		const data = {
			userId: authorProfile._id
		}
		props.followToggle(data)
	}

	const copy = () => {
		var copyText = document.getElementById("author-add-field");
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value);
		toast.success("Address Copied To Clipboard.")
	}

	const showInExplorer = async () => {
		const url = chainsConfigs[chainId].explorer;
		window.open(`${url}address/${authorProfile.address}`, '_blank')
		return null
	}

	const handleMessageButton = () => {
		navigate("/live-chat", { state: authorProfile })
	}

	return (
		<section
			className="author-area explore-area popular-collections-area"
			style={{ padding: "0px" }}
		>
			{(authorLoader || nftsLoader) && <FullPageLoader />}
			<ReactTooltip />
			{
				authorProfile &&

				<div className="">
					<div className="d-flex author-detail-container">
						<div className="author-preview author-card">
							<div className="seller">
								<div className="seller-thumb avatar-lg-author-container">
									<img
										className="img-fluid"
										src={authorProfile.profileImageLocal ? authorProfile.profileImageLocal : ENV.userDefaultImg}
										alt="User Profile"
									/>
								</div>
							</div>
						</div>
						<div className="card-caption p-0 text-center">
							<div className="card-body">
								<div className="about-banner-data d-flex justify-content-center align-items-center">
									<h5 className="mb-3">{authorProfile?.fullName}</h5>
									{authorProfile?.adminVerified > 0 && <span className='banner-checked-1 ml-1 pt-2'><img className="img-fluid" src={checked} alt="" /></span>}
								</div>
								<div className='about-data-two d-flex justify-content-center align-items-center'>
									{authorProfile?.username && <span className="purple-span">@{authorProfile?.username}</span>}
									<div className="input-group">
										<input type="text" className="form-control cursor-pointer" id="author-add-field" data-effect="float" data-tip={authorProfile.address} placeholder="0x000000000000000000" readOnly value={ENV.formatAddress(authorProfile.address)}
											onClick={showInExplorer}
										/>
										<span className='ethirem-icon'><img className="img-fluid" src={chainsVsIcons[Number(chainId)]} alt="" /></span>
										<div className="input-group-append"
											style={{
												position: "absolute",
												height: "100%",
												top: "0",
												right: "0",
												borderRadius: "100px",
												overflow: "hidden",
											}}
											onClick={() => ENV.copy(authorProfile.address)}
										>
											<button style={{
												marginRight: "5px",
												padding: "12px 16px",
												backgroundColor: "transparent",
												color: "#fff",
												border: "0",
												outline: "none",
											}}><i className="icon-docs" data-effect="float" data-tip="Copy" /></button>
										</div>
									</div>
								</div>
								<div className="d-flex align-items-center justify-content-center author-fans">
									<div className="follower"><span className="count">{followersCount}</span> <span>followers</span></div>
									<div className="following"><span className="count">{followingsCount}</span> <span>following</span></div>

								</div>
								<div className="d-flex d-inline-block align-items-center justify-content-center custom-author-btns">
									{
										authorProfile?._id === ownId &&
										<div className="follow-button">
											<button className='gradient-button btn ' onClick={() => navigate('/profile')} style={{ 'marginLeft': '20px' }}>Edit Profile</button>
										</div>
									}
									{
										authorProfile?._id !== ownId &&
										<>
											<div className="follow-button">
												<button className='gradient-button btn ' onClick={handleFollowClick} style={{ 'marginLeft': '20px' }}>{isFollow ? 'Following' : 'Follow'}</button>
											</div>
											<div className="message-button" onClick={handleMessageButton}>
												<button className='gradient-button btn' >Message</button>
											</div>
										</>
									}
								</div>
								{
									props.followText &&
									<a className="btn btn-bordered-white btn-smaller">{props.followText}</a>
								}
							</div>
						</div>

						{<div className={`d-flex collection-wrapper ${Filter ? 'active' : ''}`}>

							<div className="nfts-collection-wrapper custom-container author-items w-100">
								{/* tabs */}
								{<Tabs
									id="controlled-tab"
									activeKey={key}
									onSelect={(k) => setKey(k)}
									className="mb-3"
								>
									<Tab eventKey="about" title="About">
										<Row>
											<Col lg={3} col={12} md={12}>
												<AboutSidebar authorProfile={authorProfile} />
											</Col>
											<Col lg={9} col={12} md={12}>
												{key === 'about' && authorUpdated && <AboutNfts
													creatorId=
													{
														authorProfile._id
														// params.authorId
													} //if we pass authorProfile._id it will cause issue
													setKey={setKey} keyValue="created" setLoader={getNftsLoader} />}
											</Col>
										</Row>
									</Tab>
									<Tab eventKey="created" title="Created">
										<Row>
											<Col md={12}>
												{key === 'created' && authorUpdated && <Explore creatorId={authorProfile._id} setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} />}

											</Col>
										</Row>
									</Tab>
									<Tab eventKey="collected" title="Collected">
										<Row>
											<Col md={12}>
												{key === 'collected' && <Explore collectorId={authorProfile._id} setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} />}

											</Col>
										</Row>
									</Tab>
									<Tab eventKey="onSale" title="On Sale">
										<Row>
											<Col md={12}>
												{key === 'onSale' && authorUpdated && <Explore onSale={true} creatorId={authorProfile._id} setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} />}

											</Col>
										</Row>
									</Tab>
									<Tab eventKey="favourited" title="Favourited">
										<Row>
											<Col md={12}>
												{key === 'favourited' && authorUpdated && <Explore isFavourite={true} creatorId={authorProfile._id} setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} />}
											</Col>
										</Row>
									</Tab>
									{authorProfile._id === ownId && authorUpdated && <Tab eventKey="draft" title="Draft">
										<Row>
											<Col md={12}>
												{key === 'draft' && authorUpdated && <Explore creatorId={authorProfile._id} setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} draft={true} />}
											</Col>
										</Row>
									</Tab>}
									<Tab eventKey="followers" title="Followers">
										<Row>
											<Col md={12}>
												{key === 'followers' && authorUpdated && <Follow creatorId={authorProfile._id} action='followers' setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} />}
											</Col>
										</Row>
									</Tab>
									<Tab eventKey="followings" title="Followings">
										<Row>
											<Col md={12}>
												{key === 'followings' && authorUpdated && <Follow creatorId={authorProfile._id} action='followings' setLoader={getNftsLoader} setCreatedNfts={getCreatedNfts} filter={nftFilter} />}
											</Col>
										</Row>
									</Tab>
									<Tab eventKey="activity" title="Activity">
										<Row>
											<Col lg={3} >
												<button className="side-toggle-btn " onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button>
												<FilterSearch
													applyFilters={applyFilters}
													items={false}
													activity={true}
													filters={filters}
													setFilters={setFilters}
												/>
											</Col>
											<Col lg={9} >
												{key === 'activity' && <ActivityTable authorId={authorProfile._id} setLoader={setAuthorLoader} filters={filters} />}
											</Col>
										</Row>
									</Tab>
								</Tabs>}
							</div>
						</div>}
					</div>
				</div>
			}
		</section>
	);
};

const mapStateToProps = (state) => ({
	error: state.error,
	user: state.user
});

export default connect(mapStateToProps, { getUserById, beforeUser, followToggle })(Author);
