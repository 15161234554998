import { BEFORE_APP, SET_RATES } from './redux/types';

const initialState = {
    rate: null,
    rateAuth: false,
    wbnbRate: null,
    wbnbRateAuth: false,
    croRate: null,
    croRateAuth: false,
    wcroRate: null,
    wcroRateAuth: false,
    ethRate: null,
    ethRateAuth: false,
    wethRate: null,
    wethRateAuth: false,
    fantomRate: null,
    fantomRateAuth: false,
    wftmRate: null,
    wftmRateAuth: false,
    pixulRate: null,
    pixulRateAuth: false,

    allRates: null,
    allRatesAuth:false,

}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RATES:
            const {
                // X to USD
                BNBtoUSD,
                ETHtoUSD,
                FTMtoUSD,
                CROtoUSD,
                // WX to USD
                WBNBtoUSD,
                WETHtoUSD,
                WFTMtoUSD,
                WCROtoUSD,
                // WX to X
                WBNBtoBNB,
                WETHtoETH,
                WFTMtoFTM,
                WCROtoCRO,
                // X to WX
                BNBtoWBNB,
                ETHtoWETH,
                FTMtoWFTM,
                CROtoWCRO,

                PIXULtoUSD
            } = action.payload
            return {
                ...state,

                pixulRate: PIXULtoUSD,
                pixulRateAuth: true,

                // X to USD
                rate: BNBtoUSD,
                rateAuth: true,

                ethRate: ETHtoUSD,
                ethRateAuth: true,

                fantomRate: FTMtoUSD,
                fantomRateAuth: true,

                croRate: CROtoUSD,
                croRateAuth: true,

                // WX to USD
                wbnbRate: WBNBtoUSD,
                wbnbRateAuth: true,

                wethRate: WETHtoUSD,
                wethRateAuth: true,

                wftmRate: WFTMtoUSD,
                wftmRateAuth: true,

                wcroRate: WCROtoUSD,
                wcroRateAuth: true,

                allRates: action.payload,
                allRatesAuth:true,
            }
        case BEFORE_APP:
            return {
                ...state,
                rate: null,
                rateAuth: false,
                wbnbRate: null,
                wbnbRateAuth: false,
                croRate: null,
                croRateAuth: false,
                wcroRate: null,
                wcroRateAuth: false,
                ethRate: null,
                ethRateAuth: false,
                wethRate: null,
                wethRateAuth: false,
                fantomRate: null,
                fantomRateAuth: false,
                wftmRate: null,
                wftmRateAuth: false,
                pixulRate: null,
                pixulRateAuth: false,

                allRates: null,
                allRatesAuth:false,
            }
        default:
            return {
                ...state
            }
    }
}