import { useEffect } from 'react'
import { ENV } from '../../config/config'
const { appName } = ENV
const About = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-7">
                     <div className="intro text-center intro-works">
                         <h3 className="mt-3 mb-0">{"About"}</h3>
                        <p>{"Explanations for using the platform"}</p>
                    </div>
                </div>
            </div>
            <div className="p-3 pt-1">
                <h5>Set up your wallet</h5>
                <p> Once you’ve set up your wallet via MetaMask, connect it to {appName} by clicking the NFT Marketplace in the top right corner. Note. We support only MetaMask for now.</p>
            </div>
            <div className="p-3">
                <h5>Create your collection</h5>
                <p>Click Create New and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.</p>
            </div>
            <div className="p-3">
                <h5>Add your NFTs</h5>
                <p>Upload your work (image), add a title and description, and customize your NFTs with properties, stats, and unlockable content.</p>
            </div>
            <div className="p-3 pb-5">
                <h5>List them for sale</h5>
                <p>Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!</p>
            </div>
        </div>
    )
}

export default About
