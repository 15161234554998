import { SET_NOTIFICATIONS, SET_UNREAD_COUNT, UPDATE_UNREAD_COUNT, UPDATE_NOTIFICATIONS, BEFORE_FOLLOWING, BEFORE_FOLLOWER, SET_SEND_EMAIL, SET_VERIFY_EMAIL, UPDATE_NOTI_COUNT, SET_NOTIFICATIONS_COUNT, SET_FOLLOWINGS, SET_FOLLOWERS, BEFORE_USER, SET_USER, SET_CREATORS, TOP_SELLERS, SET_INDIVIDUAL_USER, GET_USER, SET_BANNER, USER_TRADE, SET_FOLLOW, REGISTER_First } from '../../redux/types';

const initialState = {
    followersAuth:false,
    followingsAuth:false,
    notificationsAuth: false,
    userData: null,
    userAuth: false,
    creators: null,
    creatorsAuth: false,
    topSellersAuth: false,
    individualUserAuth: false,
    individualUser: null,
    bannerAuth: false,
    banner: null,
    userTrade: null,
    userTradeAuth: false,
    follow: false,
    followers: [],
    followings: [],
    count: null,
    pagination: null,
    registerFirst: false,
    notifications: [],
    notiPagination: null,
    notiCountAuth: false,
    notiCount: null,
    sendEmailAuth: false,
    sendEmail: false,
    verifyEmailAuth: false,
    verifyEmail: false
}

export default function userRed(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                userData: action.payload,
                userAuth: true
            }
        case REGISTER_First: {
            return {
                ...state,
                registerFirst: true
            }
        }
        case GET_USER:
            return {
                ...state
            }
        case SET_BANNER:
            return {
                ...state,
                bannerAuth: true,
                banner: action.payload
            }
        case SET_CREATORS:
            return {
                ...state,
                creators: action.payload,
                creatorsAuth: true
            }
        case TOP_SELLERS:
            return {
                ...state,
                sellers: action.payload,
                topSellersAuth: true
            }
        case SET_INDIVIDUAL_USER:
            return {
                ...state,
                individualUser: action.payload,
                individualUserAuth: true
            }
        case USER_TRADE:
            return {
                ...state,
                userTrade: action.payload,
                userTradeAuth: true
            }
        case  SET_FOLLOW:
            return{
                ...state,
                individualUser: action.payload,
                individualUserAuth: true
            }
        case SET_FOLLOWERS:
            return {
                ...state,
                followers: [...state.followers,...action.payload.followers],
                followersAuth:true,
                count: action.payload.count,
                pagination: action.payload.pagination
            }
        case SET_FOLLOWINGS:
            return {
                ...state,
                followings: [...state.followings,...action.payload.followings],
                followingsAuth:true,
                count: action.payload.count,
                pagination: action.payload.pagination
            }
        case SET_NOTIFICATIONS:
            let previousNotifications = [];
            if(state.notifications) {
                previousNotifications = [...state.notifications];
            }
            return {
                ...state,
                notifications: [...previousNotifications,...action.payload.notifications],
                notificationsAuth:true,
                notiPagination: action.payload.pagination
            }
        case SET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                notiCount: action.payload,
                notiCountAuth:true
            }
        case SET_UNREAD_COUNT:
            return {
                ...state,
                unReadCountAuth: true,
                unReadCount: action.payload
            }
        case BEFORE_USER:
            return {
                ...state,
                userData: null,
                userAuth: false,
                creators: null,
                creatorsAuth: false,
                topSellersAuth: false,
                individualUserAuth: false,
                individualUser: null,
                bannerAuth: false,
                banner: null,
                userTrade: null,
                userTradeAuth: false,
                follow: false,
                notifications: [],
                count: null,
                pagination: null,
                registerFirst: false,
                followersAuth:false,
                followingsAuth:false,
                notificationsAuth: false,
                notiPagination: null,
                notiCountAuth: false,
                notiCount: null,
                sendEmailAuth: false,
                sendEmail: false,
                verifyEmailAuth: false,
                verifyEmail: false
            }
        case BEFORE_FOLLOWER:
            return {
                followers: [],
                followersAuth: false,
            }
        case BEFORE_FOLLOWING:
            return {
                ...state,
                followings: [],
                followingsAuth: false,
            }
        case UPDATE_NOTI_COUNT:
            return {
                notiCountAuth: false,
                notiCount: null
            }
        case UPDATE_NOTIFICATIONS:
            return {
                notificationsAuth:false,
            }
        case UPDATE_UNREAD_COUNT:
            return {
                unReadCountAuth: false,
                unReadCount: null
            }
        case SET_SEND_EMAIL:
            return {
                sendEmailAuth: true,
                sendEmail: true
            }
        case SET_VERIFY_EMAIL:
            return {
                verifyEmailAuth: true,
                verifyEmail: true
            }
        default:
            return {
                ...state
            }
    }
}