import { BEFORE_APP, SET_RATES, GET_ERRORS } from './redux/types';
import { emptyError } from './redux/shared/error/error.action';
import { ENV } from './config/config';

export const beforeApp = () => {
    return {
        type: BEFORE_APP
    }
};

export const getTokensConvertedPrices = () => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}tools/price-conversion`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: SET_RATES,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}