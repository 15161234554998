import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { beforeHome, getMostSold } from "../../homepage/home.action";
import { ENV } from "../../../config/config";
import FullPageLoader from "../../../components/full-page-loader/full-page-loader";
import CustomHeading from "../custom-heading/CustomHeading";
import { Container, Row, Card } from "react-bootstrap";
import Ethereum from "../../../assets/images/arow-icon.png";
import Binance from "../../../assets/images/binancelogo.png";
import Fantom from "../../../assets/images/fantomlogo.png";
import Cronos from "../../../assets/images/cronoslogo.png";
import tikIcon from "../../../assets/images/tik.png";
import SmallChecked from "../../../assets/images/tik1.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../trending-nfts/trending-nft.css";
const itemPlaceholderImg = ENV.userDefaultImg;

const TrendingNft = (props) => {
  const [trending, setTrending] = useState([]);
  const [length, setLength] = useState(1);
  const filter = { mostSold: true };

  useEffect(() => {
    getMostSold(filter);
  }, []);

  useEffect(() => {
    if (props.home.mostSoldAuth) {
      setTrending(props.home.mostSold.nfts);
      if (props.home.mostSold.nfts.length > 0)
        setLength(1 / props.home.mostSold.nfts.length);
      props.beforeHome();
    }
  }, [props.home.mostSoldAuth]);

  const getMostSold = (filter) => {
    const qs = ENV.objectToQueryString(filter);
    props.getMostSold(qs);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {trending && trending.length ? (
        <section className="trending">
          <Container fluid className="custom-container">
            <CustomHeading heading="Trending" collection="NFTs" />
            <Carousel responsive={responsive} showDots={true}>
              {trending.map((e, index) => {
                return (
                  <Card key={index} className="trending-nft-card">
                    <Link
                      to={`/item-details/${window.btoa(
                        e.sellhistories?.nftId
                      )}`}
                    >
                      <Card.Img
                        className="trending-images"
                        variant="top"
                        src={e.imageLocal ? e.imageLocal : itemPlaceholderImg}
                        data-zoom="https://assets.imgix.net/dog.png?w=1200"
                      />
                    </Link>
                    <Card.Body>
                      <div className="mettoz ">
                        <Link
                          className="text-white"
                          to={`/artist/${e?.owner?.username}`}
                        >
                          {e?.owner?.username}
                        </Link>
                        <span className=" tik-icon">
                          {e.owner?.adminVerified > 0 && SmallChecked ? (
                            <img
                              className="tik-icon img-fluid"
                              src={SmallChecked}
                              alt="card"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      {e.tokenId >= 0 && (
                        <div className="d-flex value-area">
                          <span className="flex-grow-1 text-white">
                            {e.name}
                          </span>

                          {e.autoIncrementId && (
                            <span className="trending-value">
                              {e.autoIncrementId}/{e.collNFTsCount}
                            </span>
                          )}
                        </div>
                      )}
                      <div className=" price-area">
                        <div className="d-flex price-content">
                          <span className="price">Price</span>
                          <span className="highest-bid">Highest Bid</span>
                        </div>
                        <div className="d-flex eth-content">
                          <span className=" eth flex-grow-1">
                            {e.sellhistories?.price?.amount}{" "}
                            {e.sellhistories?.price?.currency}
                          </span>
                          <span className="eth ">
                            {e.lastPrice?.price} {e.lastPrice?.currency}
                          </span>
                          {e.sellhistories?.price?.currency === "ETH" ? (
                            <img
                              src={Ethereum}
                              className="img-fluid "
                              alt="Arow"
                            />
                          ) : null}
                          {e.sellhistories?.price?.currency === "BNB" ? (
                            <img
                              src={Binance}
                              className="img-fluid "
                              alt="Arow"
                            />
                          ) : null}
                          {e.sellhistories?.price?.currency === "FTM" ? (
                            <img
                              src={Fantom}
                              className="img-fluid "
                              alt="Arow"
                            />
                          ) : null}
                          {e.sellhistories?.price?.currency === "CRO" ? (
                            <img
                              src={Cronos}
                              className="img-fluid "
                              alt="Arow"
                            />
                          ) : null}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </Carousel>
          </Container>
        </section>
      ) : (
        <section className="trending">
          <Container fluid className="custom-container">
            <CustomHeading heading="Trending" collection="NFTs" />
            <div className="no-data border">
              <p className="text-center">No Record Found</p>
            </div>
          </Container>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { beforeHome, getMostSold })(
  TrendingNft
);
