import React, { useState, useEffect, useRef } from "react"
import FullPageLoader from "../../components/full-page-loader/full-page-loader"
import { connect } from "react-redux"
import { beforeCategory, getCategory } from "./categories.action"
import Collections from '../collections/collections'
import { useParams, useNavigate } from "react-router-dom"
import { ENV } from "../../config/config"
const { collectionFeaturedImg } = ENV

const ExploreCategory = (props) => {
    const [category, setCategory] = useState(null)
    const [collectionsLoader, setColLoader] = useState(true) // collections loader
    const collectionsRef = useRef(null)
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params) {
            window.scroll(0, 0)
            const { slug } = params
            if (slug)
                props.getCategory(slug)
            else
                navigate('/collections')
        }
    }, [params])

    // when category data is received
    useEffect(() => {
        if (props.category.getCategory) {
            const { category } = props.category?.category
            setCategory(category)
            props.beforeCategory()
            getCatCollections(category._id)
        }
    }, [props.category.getCategory])

    useEffect(() => {
        // when an error is received
        if (props.error && props.error.invalidCat)
            navigate("/collections")
    }, [props.error && props.error.invalidCat])

    const getColLoader = (loader) => {
        setColLoader(loader)
    }

    const getCatCollections = (catId) => {
        collectionsRef.current?.getCatCollections(catId)
    }

    return (
        <section className="author-area explore-area popular-collections-area p-0">
            {collectionsLoader && <FullPageLoader />}
            {category && (
                <div>
                    <div className="d-flex collection-detail-container">
                        {/* <div className="collection-preview collection-card">
                            <div className="seller">
                                <div className="seller-thumb avatar-lg-cover-logo">
                                    <img
                                        className="rounded-circle"
                                        src={category?.image || collectionFeaturedImg}
                                        alt="Category Image"
                                        style={{ height: "110px" }}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="card-caption p-0 text-center">
                            <div className="card-body pt-0 pb-0">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <h5 className="mt-4 text-break">Explore {category?.name}</h5>
                                    <p className="mt-3 mb-3">
                                        {category?.description || "- no description found -"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {category?._id && <Collections setLoader={getColLoader} ref={collectionsRef} exploreCat={category?._id} />}
        </section>
    )
}

const mapStateToProps = (state) => ({
    error: state.error,
    category: state.category,
})

export default connect(mapStateToProps, { beforeCategory, getCategory })(ExploreCategory)