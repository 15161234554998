import React, { Suspense, useEffect, useState } from 'react'
import routes from './routes'
import { PrivateRoute } from './privateRoute'
import { useNavigate, useParams, Route, Routes } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import cookieImage from '../src/assets/images/cookiesicon.svg'
import WebGLFluid from 'webgl-fluid'
import Getter from './Getter';
import {ENV} from './config/config'
import './App.css';

function App(props) {
  const navigate = useNavigate()
  const params = useParams()
  const [cookie, setCookie] = useState(true);
  useEffect(() => {
    console.log("on navigate wahoo")
    if (!localStorage.getItem("theme"))
      localStorage.setItem("theme", "dark")
    else {
      const selectedTheme = localStorage.getItem("theme")
      const mode = selectedTheme == "light" ? 'light-mode' : 'dark-mode'
      console.log("Selected Theme", mode)
      document.body.classList.toggle(mode);
      console.log("i am run when ever route changes", localStorage.getItem("theme"))
    }
  }, [])
  const cookieHandler = () => {
    setCookie(false)
  }
  useEffect(() => {
    const canvas = document.querySelector("canvas");
    canvas.width = document.body.clientWidth;
    canvas.height = document.body.clientHeight;
    WebGLFluid(document.querySelector('canvas'), {
      // TRANSPARENT: true,
      IMMEDIATE: true,
      TRIGGER: 'hover',
      SIM_RESOLUTION: 328,
      DYE_RESOLUTION: 500,
      CAPTURE_RESOLUTION: 12,
      DENSITY_DISSIPATION: 2,
      VELOCITY_DISSIPATION: 0.2,
      PRESSURE: 0.8,
      PRESSURE_ITERATIONS: 5,
      CURL: 100,
      SPLAT_RADIUS: 0.03,
      SPLAT_FORCE: 10000,
      SHADING: false,
      COLORFUL: true,
      COLOR_UPDATE_SPEED: 20,
      PAUSED: false,
      BACK_COLOR: { r: 7, g: 8, b: 41 },
      TRANSPARENT: true,
      BLOOM: false,
      BLOOM_ITERATIONS: 8,
      BLOOM_RESOLUTION: 256,
      BLOOM_INTENSITY: 0,
      BLOOM_THRESHOLD: 0.6,
      BLOOM_SOFT_KNEE: 0.7,
      SUNRAYS: false,
      SUNRAYS_RESOLUTION: 0.001,
      SUNRAYS_WEIGHT: 0.001,
    })
  }, [])
  return (
    <div className="App">
      <canvas style={{ position: "absolute" }} />
      <Getter />
      <Routes>
        {
          routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <route.layout {...props} title={route.title} customBg={route.customBg}>
                    <route.component {...props} navigate={navigate} params={params} />
                  </route.layout>
                }
              />
            )
          })
        }
      </Routes>
      {cookie && <CookieConsent
        disableStyles={true}
        location={"bottom"}
        buttonClasses="btn btn-danger accept-cookies-btn"
        buttonText="Accept Cookies"
        containerClasses="custom-cookie-alert col-lg-12 col-sm-12 d-flex align-items-center justify-content-between"
      >
        <div className='cookies-image'><img src={cookieImage} className=" img-fluid" alt='Coookie-icon' /></div>
        <div> <span className='about-cookies'> <b className='notice'>Cookies Notice:</b> We use cookies to give you the best online experience.Please let us know if you agree to all of these cookies.<a href={`${ENV.domainURL}privacy-and-terms`} target="_blank" className='cookies-privacy-link'>View Privacy policy</a></span>{" "}</div>
      </CookieConsent>}
    </div>
  );
}

export default App;
