import { BEFORE_FAQ, GET_FAQS, GET_FAQ } from '../../redux/types';

const initialState = {
    faqs: null,
    faqsAuth: false,
    faq: null,
    faqAuth: false
}

export default function faqRed(state = initialState, action) {
    switch (action.type) {
        case GET_FAQS:
            return {
                ...state,
                faqs: action.payload,
                faqsAuth: true
            }
        case GET_FAQ:
            return {
                ...state,
                faq: action.payload,
                faqAuth: true
            }
        case BEFORE_FAQ:
            return {
                ...state,
                faqs: null,
                faqsAuth: false,
                faq: null,
                faqAuth: false
            }
        default:
            return {
                ...state
            }
    }
}