import { GET_ORDERS, BEFORE_ORDERS, UPSERT_ORDER } from "../../redux/types";
const initialState = {
  orders: null,
  order: null,
  upsertOrderAuth: false,
  getOrderAuth: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        getOrderAuth: true,
      };
    case BEFORE_ORDERS:
      return {
        ...state,
        orders: null,
        order: null,
        upsertOrderAuth: false,
        getOrderAuth: false,
      };
    case UPSERT_ORDER:
      return {
        ...state,
        upsertOrderAuth: true,
        order: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
