import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Redirect = (props) =>{

    const { state } = useLocation();
    const navigate = useNavigate();

    const isValidUrl = (userInput) => {
        var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);
        if (res == null)
            return false;
        else
            return true;
    }

    useEffect(() => {
        if(state){
            const link = state.value
            const label = state.label

            if(label === 'twitter'){
                let url = "https://www.twitter.com/"+link;
                if(isValidUrl(url)){
                    window.location.replace(url);
                }else{
                    navigate('/');
                    toast.error("Redirect url is not valid.")
                }
            }else{
                var thisRegex = new RegExp("((http|https)://)(www.)?");
                if(!thisRegex.test(link)){
                    let url = "http://"+link;
                    
                    if(isValidUrl(url)){
                        window.location.replace(url);
                    }else{
                        navigate('/');
                        toast.error("Redirect url is not valid.")
                    }
                }else{
                    if(isValidUrl(link)){
                        window.location.replace(link);
                    }else{
                        navigate('/');
                        toast.error("Redirect url is not valid.")
                    }
                }
            }
        }else{
            navigate('/');
            toast.error("Redirect url is not valid.")
        }
    })
    
    return (
        <section>
            <div className='redirecting-text text-center'>
                <h3>Redirecting...</h3>
                <p>Please wait ! while we connect you to your desire link.</p>
            </div>
        </section>
    );
}


export default Redirect;