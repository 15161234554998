import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css'
import $ from 'jquery'
import SimpleReactValidator from 'simple-react-validator'
import { verifyEmail, beforeUser } from './../user/user.action';
import { signRequest } from './../../utils/web3';
import { ENV } from './../../config/config';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from "../full-page-loader/full-page-loader";
import { Col, Row } from 'react-bootstrap'
import AuthorImg from '../../assets/images/author-img.png';

const VerifyEmail = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        props.verifyEmail({token: window.location.pathname.split('/')[2]})
    }, [])

    useEffect(() => {
      if(props.user.verifyEmailAuth){
        navigate('/')
      }
    }, [props.user.verifyEmailAuth])

    useEffect(() => {
        if(props.error){
          navigate('/')
        }
      }, [props.error])

    return (
        <section>
        </section>
    )    
}

const mapStateToProps = state => ({
    user: state.user,
    error: state.error
});

export default connect(mapStateToProps, { verifyEmail, beforeUser })(VerifyEmail);