import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import management from '../../assets/images/user-data.jpg';
import law from '../../assets/images/law.jpg';
import contact from '../../assets/images/communication.jpg';
import transactions from '../../assets/images/transactions.jpg';
import security from '../../assets/images/security-data.jpg';
import data from '../../assets/images/data.jpg';

const PrivacyTerms = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="explore-area privacy-and-terms pt-3">
      <div className="custom-container">
        <div class="intro">
          <div class="intro-content flex-fill text-center">
            <h3 class="my-3">Privacy Policy</h3>
          </div>
        </div>
        <div className="p-3 pt-5">
          <span className="mt-0 mb-1">
            Last Updated: <span className="privacy-date">January 10, 2023</span>
          </span>
        </div>
        <div className="px-md-3 px-0 py-2 pt-3">
          <p>
            <b>Mutoplace</b> (referred to herein as "Mutoplace," "we," "us," or "our") will manage, monitor, and use the personal data collected with the consent of the individuals using this system.
          </p>
        </div>
        <div className="px-md-3 px-0 py-2">
          <p>
            This Privacy Policy, in agreement with our Terms, applies to your use of the Mutoplace platform. As you access our platform and use our services, you consent to collect, store, manage, use and disclose your provided information. We do not collect personal information except what you provide us knowingly and explicitly.
          </p>
        </div>
        <div className="px-md-3 px-0 py-2">
          <p>
            Mutoplace is committed to protecting your online privacy, ensuring that your personally identifiable information is managed, used, and/or disclosed according to this privacy policy. So, review it carefully, and if you disagree with any of its clauses, we suggest you quit accessing and use this platform immediately.
          </p>
        </div>
        <div className="px-md-3 px-0 py-2 pt-3">
          <h5>Reasons for Collecting User Data:</h5>
          <p>Mutoplace pertains to all lawful and fair means of collecting users' personal information through this platform and may use for the following reasons. One or more reasons for collecting users’ data may apply simultaneously.</p>
        </div>
        <div className="py-3 px-5 response-paragraph ">
          <ul>
            <li>To verify the user’s identity for cyber security purposes.</li>
            <li>To seamlessly provide our services, such as managing user accounts and processing their requests.</li>
            <li>To update the user about technical progress and upgrades of Mutoplace.</li>
            <li>To contact the user regarding the administration and management of his Mutoplace account.</li>
            <li>To communicate with the user by sending them newsletters through email or in any other form for any reason (the latest products/services we think a user might be interested in.)</li>
            <li>To take appropriate and quick action if we suspect a user is performing illegal action on/through this platform or serious misconduct that breaches this privacy policy or is otherwise related to our platform’s functions and activities.</li>
            <li>To keep the platform adhered to the regulatory and other legal obligations.</li>
          </ul>
        </div>
        <div className="px-md-3 px-0 py-2 pt-3">
          <p>You can use the platform anonymously, using a pseudonym, unless we find it infeasible to deal with users we cannot identify or if we need to comply with the law restricting us from engaging with unidentifiable individuals</p>
          <p>Additionally, suppose a user does not provide Mutoplace with the personally identifiable information we request. In that case, we may not be able to process the account creation/registration and deliver our services or respond to user-generated complaints and inquiries.</p>
          <p>We may collect personal information from users through this platform, including their names, email addresses, and phone numbers. We may use this information to verify the user's identity and track their activities on the website.</p>
        </div>
        <div className="px-md-3 px-0 py-2 pt-3">
          <h5>Types of Data We Collect:</h5>
          <p>The user data we may collect through this platform includes.</p>
        </div>
        <div className="py-3 px-5 response-paragraph ">
          <ul>
            <li>Personally identifiable information (PII) includes name, address, nationality, email ID, and national ID card.</li>
            <li>Telegram username and non-custodial wallet of the user.</li>
            <li>Mutoplace platform may automatically collect some of the user’s information as soon as he access and start using our services. It includes.</li>
            <li>The smart contract is stored in the user’s non-custodial wallet address.</li>
            <li>Transaction details of the user, such as purchasing of crypto assets.</li>
            <li>Technical data when a user accesses the platform, such as IP address operating system, time zone, current location, etc.</li>
            <li>Information about each user visit, including URL (Uniform Resource Locator) clickstream to, though, and from this platform (including time and date.)</li>
            <li>Products or NFTs a user searched for or just viewed; download errors, page response times, page interaction data (scrolling, mouse-overs, clicks, etc.), time spent on particular pages, and methods used to browse the target page.</li>
            <li>Data regarding the provision of services through Mutoplace we might get from any other sources.</li>
          </ul>
        </div>
        <div className="px-md-3 px-0 py-xxl-5 py-md-4 py-3 pt-3">
          <Row className="">
            <Col xl={6} lg={5}>
              <div className="privacy-image d-flex align-items-center h-100">
                <div className="privacy-image-in">
                  <img className="img-fluid" src={data} alt="" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <div className="privacy-data d-flex align-items-center h-100">
                <div className="privacy-in-data">
                  <h5>Collection of User Data:</h5>
                  <p>We collect and process the data when a user.</p>
                  <ul>
                    <li>Views and uses the services we provide through Mutoplace.</li>
                    <li>Register for an account with us.</li>
                    <li>Connects his wallet to the platform.</li>
                    <li>Complete a form voluntarily, participate in a survey, or contact us for customer support.</li>
                    <li>Request information from us, such as marketing materials or product information.</li>
                    <li>Interact with us on social media platforms.</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-md-3 px-0 py-xxl-5 py-md-4 py-3 pt-3">
          <Row className="flex-md-row-reverse">
            <Col xl={6} lg={5}>
              <div className="privacy-image d-flex align-items-center h-100">
                <div className="privacy-image-in">
                  <img className="img-fluid" src={management} alt="" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <div className="privacy-data d-flex align-items-center h-100">
                <div className="privacy-in-data">
                  <h5>Management of User Data:</h5>
                  <p>Mutoplace takes all reasonable and legally compliant measures to ensure that the user’s PII is stored and managed in a secure environment that can only be accessed by authorized personnel.</p>
                  <p>Mutoplace destroys, permanently removes or de-identifies PII from its database when it is no longer required to be used by the system (for any purpose permitted.)</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-md-3 px-0 py-xxl-5 py-md-4 py-3 pt-3">
          <Row className="">
            <Col xl={6} lg={5}>
              <div className="privacy-image d-flex align-items-center h-100">
                <div className="privacy-image-in">
                  <img className="img-fluid" src={security} alt="" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <div className="privacy-data d-flex align-items-center h-100">
                <div className="privacy-in-data">
                  <h5>Security of User Data:</h5>
                  <p>We care for your online security while using Mutoplace. Still, we cannot guarantee that it is entirely secure because no method of data transmission over the internet is 100% secure. Therefore, you will be liable for any information you share with us through Mutoplace.</p>
                  <p>Always log out of your account when you're done using Mutoplace, especially if you share your computer with someone else. You're responsible for the security of your computer, mobile device, or any other device you use to access our platform.</p>
                  <p>However, we always strive to implement commercially acceptable online protection methods to secure personal data from unauthorized access, loss, misuse, abuse, and disclosure. We deploy password-protected databases, systems, and security technology. Mutoplace employees, contractors, and agents are liable for maintaining the confidentiality of the users' personal information. In addition, we comply with state laws in the event of security risk or data breach.</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-md-3 px-0 py-xxl-5 py-md-4 py-3 pt-3">
          <Row className="flex-md-row-reverse">
            <Col xl={6} lg={5}>
              <div className="privacy-image d-flex align-items-center h-100">
                <div className="privacy-image-in">
                  <img className="img-fluid" src={law} alt="" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <div className="privacy-data d-flex align-items-center h-100">
                <div className="privacy-in-data">
                  <h5>Law Enforcement:</h5>
                  <p>Mutoplace may disclose and share users’ personal information under specific conditions; for example, in response to lawful requests from a state court or any government agency. </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-md-3 px-0 py-xxl-5 py-md-4 py-3 pt-3">
          <Row className="">
            <Col xl={6} lg={5}>
              <div className="privacy-image d-flex align-items-center h-100">
                <div className="privacy-image-in">
                  <img className="img-fluid" src={transactions} alt="" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <div className="privacy-data d-flex align-items-center h-100">
                <div className="privacy-in-data">
                  <h5>Business Transactions:</h5>
                  <p>Mutoplace in case of any business merger or acquisition by another company or when it goes through an asset sale to another organization may be required to include the Personal Data of users among the assets transferred. The user will be notified about any such business transaction and transfer of his information under a different brand and Privacy Policy.</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-md-3 px-0 py-xxl-5 py-md-4 py-3 pt-3">
          <Row className="flex-md-row-reverse">
            <Col xl={6} lg={5}>
              <div className="privacy-image d-flex align-items-center h-100">
                <div className="privacy-image-in">
                  <img className="img-fluid" src={contact} alt="" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <div className="privacy-data d-flex align-items-center h-100">
                <div className="privacy-in-data">
                  <h5>Contact Mutoplace:</h5>
                  <p>You can send us an email for any inquiries or complaints about platform access and your personal data management and security.</p>
                  <div className="d-flex align-items-center">
                    <p className="my-3"><b>Email:</b></p>
                    <p className="my-3 ml-2"><a href="mailto:inquiries@Mutoplace.com">inquiries@metamuto.com</a></p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default PrivacyTerms;
