import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { io } from "socket.io-client";
import ChatContainer from "./ChatContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Contacts from "./Contacts";
import Welcome from "./Welcome";
import { connect } from 'react-redux';
import { ENV } from '../../config/config';
import { getUserById, getCreators } from '../conversation/chat.action';
import FullPageLoader from '../full-page-loader/full-page-loader'
import $ from 'jquery'
import './chat.css';

const Chat = (props) => {

  const { state } = useLocation();
  const userId = ENV.getUserKeys()?._id ? ENV.getUserKeys()?._id : undefined
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [notiMsg, setNotiMsg] = useState([]);
  const [notiSign, setNotiSign] = useState([]);
  const [loader, setLoader] = useState(true);

  const togglehandler = () => {
    if ($(".chat-toggle").hasClass("chat-toggle")) {
      $(".chat-toggle").addClass("responsive-toggle")
      $(".responsive-toggle").toggle();
      $(".chat-maincontainer").css({ "grid-template-columns": "100%" });
    }
    else {
      $(".chat-toggle").removeClass("responsive-toggle")
      $(".chat-maincontainer").css({ "grid-template-columns": "50% 50%" });
    }
  }


  useEffect(() => {
    if (state) {
      setCurrentChat(state);
    }
  }, [state]);

  useEffect(() => {
    props.getUserById(userId)
  }, []);

  useEffect(() => {
    if (props.chat.individualUserAuth) {
      setCurrentUser(props.chat.individualUser)
      localStorage.setItem(process.env.REACT_APP_LOCALHOST_KEY, JSON.stringify(props.chat.individualUser))
    }
  }, [props.chat.individualUserAuth]);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(ENV.baseUrl, {
        transports: ["websocket"]
      });
      socket.current.emit("add-user", currentUser._id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      props.getCreators();
    }
  }, [currentUser]);

  useEffect(() => {
    if (props.chat.chatlistAuth) {
      if (state) {
        const match = props.chat.chatlist.creators.find((contact) => contact._id === state._id);
        if (match) {
          setContacts(props.chat.chatlist.creators);
        }
        else {
          setContacts([...props.chat.chatlist.creators, state]);
        }
      } else {
        setContacts(props.chat.chatlist.creators);
      }
      setLoader(false)
    }
  }, [props.chat.chatlistAuth])

  const handleChatChange = (chat) => {
    socket.current.removeListener('msg-recieve')
    setCurrentChat(chat);
    if(notiSign.length > 0){
      let newData = notiSign.filter(item=> item.from !== chat._id);
      if(newData){
        setNotiSign(newData)
      }
    }
  };

  const updateMessage = (data, action) => {
    if(action){
      if(notiMsg.length > 0){
        let newData = notiMsg.filter(item => item.from !== data.from);
        if(newData){
          setNotiMsg([...newData, data])
        }else{
          setNotiMsg([...notiMsg, data])
        }
      }else{
        setNotiMsg([...notiMsg, data])
      }
    }
  }

  const updateNotification = (data, action) => {
    if(action){
      if(notiSign.length > 0){
        let newData = notiSign.filter(item => item.from !== data.from);
        if(newData){
          setNotiSign([...newData, data])
        }else{
          setNotiSign([...notiSign, data])
        }
      }else{
        setNotiSign([...notiSign, data])
      }
    }
  }



  return (
    <>
      {
        loader ?
          <FullPageLoader />
          :
          <div className="chat-container position-relative">
            <div className="container-fluid">
            <button className="btn  toggle-btn" onClick={togglehandler}><FontAwesomeIcon icon={faComment} /></button>
              <div className="row">
                <div className="col-lg-12">
           

            {currentUser === undefined ? (
              <Welcome currentUser={currentUser} />
            ) : (
              <div className="chat-maincontainer">
                {<div className="chat-toggle">

                  <Contacts notiSign={notiSign} notiMsg={notiMsg} currentUser={currentUser} contacts={contacts} currentChat={currentChat} changeChat={handleChatChange} />

                </div>}
                {currentChat === undefined ? (
                  <Welcome currentUser={currentUser} />
                ) : (
                  <ChatContainer updateMessage={updateMessage} updateNotification={updateNotification} currentUser={currentUser} currentChat={currentChat} socket={socket} />
                )}
              </div>
            )}
            </div>
            </div>
            </div>
          </div>
      }
    </>
  );
}

const mapStateToProps = state => ({
  chat: state.chat
});

export default connect(mapStateToProps, { getUserById, getCreators })(Chat);