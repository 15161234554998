import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomHeadingOne from "../custom-heading-one/CustomHeadingOne";
import learnMoreImage from "../../../assets/images/mrk.png";
import CustomButton from "../custom-button/CustomButton";
import YouTube from 'react-youtube';
import '../learn-more/learnmore.css'

function LearnMore() {

  const opts = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      loop: true,
      showinfo: 0,
      mute: true,
    }
  }

  return (
    <>
      <section className="learn-more">
        <Container fluid className="custom-container">
          <Row>
            <Col md={12} className="text-center">
              <div className="learn-more-heading"><CustomHeadingOne heading="Learn more about Mutoplace" /></div>
            </Col>
            <Col md={12}>
              {/* <div><img src={learnMoreImage} className="card-img" alt="card" /></div> */}
              <div className="learn-more-video">
                {/* <iframe 
                  src="https://www.youtube.com/embed/FzScJ-b5MYc?showinfo=0&autoplay=1&controls=0&loop=1&rel=0" allow="autoplay"> 
                </iframe> */}
                <YouTube videoId="FzScJ-b5MYc" opts={opts} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default LearnMore;
