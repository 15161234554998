import React, { useState, useEffect, useRef } from 'react';
import FullPageLoader from '../full-page-loader/full-page-loader';
import { connect } from 'react-redux';
import { beforeCategory, getCategories } from '../categories/categories.action';
import Collections from './collections';
import Select from 'react-select';
import { Tabs, Tab, Row, Col } from "react-bootstrap"
import { ENV } from '../../config/config';

const initData = {
    pre_heading: "Collections",
    heading: "Explore Collections",
    content: "Create and manage collections of unique NFTs to share and sell.",
    // btnText: "Create New"
}

const AllColections = (props) => {
    const [selectedOption, setSelectedOption] = useState({ value: 1, label: 'Ethereum' });
    const [categories, setCategories] = useState(null)
    const [categoriesLoader, setCatLoader] = useState(true) // categories loader
    const [collectionsLoader, setColLoader] = useState(true) // collections loader
    const collectionsRef = useRef(null)
    useEffect(() => {
        window.scroll(0, 0)
        props.getCategories()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let options = JSON.parse(localStorage.getItem('selectedChain'));
        if (options)
            setSelectedOption(options)
    }, [])

    // when categories data is received
    useEffect(() => {
        if (props.category.getAuth) {
            const { categories } = props.category
            const allCat = { active: true, name: 'all' }
            setCategories([allCat, ...categories])
            props.beforeCategory()
            setCatLoader(false)
        }
    }, [props.category.getAuth]) // eslint-disable-line react-hooks/exhaustive-deps

    const getColLoader = (loader) => {
        setColLoader(loader)
    }

    const getCatCollections = (catId) => {
        collectionsRef.current?.getCatCollections(catId)
    }



    const changeSetSelectedOption = (options) => {
        localStorage.setItem('selectedChain', JSON.stringify(options))
        setSelectedOption(options)
    }

    return (
        <>
            {(categoriesLoader || collectionsLoader) && <FullPageLoader />}
            <section className="explore-area pb-4 collection-page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="intro">
                                <div className="intro-content flex-fill text-center">
                                    <h3 className="my-3">{initData.heading}</h3>
                                </div>
                                <div className="blockchain-section d-flex justify-content-center align-items-center">
                                    <span className="bockchain-tag">Blockchain</span>
                                    <span className="blockchain-select">
                                        <Select
                                            value={selectedOption}
                                            onChange={changeSetSelectedOption}
                                            options={ENV.ChainOptions}
                                            isSearchable={false}
                                            classNamePrefix="elumnt-select"
                                            
                                            
                                            
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-12">
                            <div className="explore-menu btn-group-toggle d-flex justify-content-start flex-wrap" data-toggle="buttons">
                                {
                                    categories && categories.length > 0 &&
                                    categories.map((item, index) => {
                                        return (
                                            <label onClick={() => getCatCollections(item._id)} key={`cat_${index}`} className={`btn ${item.active ? 'active' : ''} text-uppercase p-2 d-flex align-items-center`}>
                                                <input type="radio" defaultValue={item.name} defaultChecked className="explore-btn" />
                                                <span>{item.name}</span>
                                            </label>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Collections
                    blockChain={selectedOption.value} guestCollections={true} setLoader={getColLoader} ref={collectionsRef} />
            </section>
        </>
    );
}

const mapStateToProps = state => ({
    error: state.error,
    category: state.category
});

export default connect(mapStateToProps, { beforeCategory, getCategories })(AllColections);
