import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const ActivityGraph = ({ graph }) => {
    const [series, setSeries] = useState(null)
    const [options, setOptions] = useState(null)
    const [graphOptions, setGraphOptions] = useState({
        series: [{
            name: 'TEAM A',
            type: 'column',
            data: [23, 11, 22, 12, 12, 12, 12, 12, 12]
        }
        ],
        legend: {
            show: false
        },
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                }
            },
            legend: {
                show: false
            },
            colors: ["#f5c0d6", "#fa627a"],

            stroke: {
                width: [0, 2, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%'
                }
            },

            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },

            labels: [
                "04/08/2022",
            ],

            markers: {
                size: 0
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'dd/MM',
                }
            },
            yaxis: {
                title: {
                    text: 'Points',
                },
                min: 0
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " points";
                        }
                        return y;

                    }
                }
            }
        },
    })
    useEffect(() => {
        let _options = {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                },
                // legend: {
                //     show: false,
                // },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                }
            },
            legend: {
                show: false
            },
            colors: ["#f5c0d6", "#fa627a"],

            stroke: {
                width: [0, 2, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%'
                }
            },

            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },

            labels: [

                "04/08/2022",
                //  "04/11/2022",
                //  "04/13/2022",
                //  "04/14/2022",
                //  "04/15/2022",
                //  "04/20/2022",
                //  "06/28/2022",
                //  "06/29/2022",
                //  "06/30/2022",


                // '08/04/2022',
                // '11/04/2022',
                // '13/04/2022',
                // '14/04/2022',
                // '15/04/2022',
                // '20/04/2022',
                // '28/06/2022',
                // '29/06/2022',
                // '30/06/2022'
            ],

            // labels: [
            //     // '08/04/2022',
            //     // '11/04/2022',
            //     // '12/04/2022',
            //     //'01-01-2003', '01-02-2003', '01-04-2003'

            //     // ,'02/01/2003', '03/01/2003', '04/01/2003',
            //     //  '05/01/2003', '06/01/2003', '07-01-2003',
            //     // '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
            // ],
            markers: {
                size: 0
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'dd/MM',
                }
            },
            yaxis: {
                title: {
                    text: 'Points',
                },
                min: 0
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " points";
                        }
                        return y;

                    }
                }
            }
        };
        let _series = [{
            name: 'TEAM A',
            type: 'column',
            data: [23, 11, 22, 12, 12, 12, 12, 12, 12]
        },
            // {
            //     name: 'TEAM B',
            //     type: 'line',
            //     data: [30, 25, 26, 30, 21, 23, 25, 20, 25, 36, 30]
            // }
        ];

        if (graph) {
            _series[0].data = graph.counts
            _options.labels = graph.dates
            setOptions(_options)
            setSeries(_series)
        }
    }, [graph])
    return (
        <div id="chart">
            {setSeries && options && <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350} />}
        </div>
    )
}
export default ActivityGraph