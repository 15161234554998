import Web3 from "web3";
import randomstring from "randomstring";
import { toast } from "react-toastify";
import marketplaceAbi from "./../utils/abis/marketplace.json";

import elumnt1155ByteCode from "./../utils/bytoCodes/elumnt1155.json";
import elumnt1155Abi from "./../utils/abis/elumnt1155.json";

import elumnt721ByteCode from "./../utils/bytoCodes/elumnt721.json";
import elumnt721Abi from "./../utils/abis/elumnt721.json";

import elumnt721SourceCode from "./../utils/sourceCode/elumnt721.sol";
import elumnt1155SourceCode from "./../utils/sourceCode/elumnt1155.sol";

import erc721Abi from "./../utils/abis/erc721.json";
import erc1155Abi from "./../utils/abis/erc1155.json";
import erc20Abi from "./../utils/abis/erc20.json";
import { ENV } from "./../config/config";
import store from "./../store";
import { axiosSyncPost, axiosVerifyContract } from "./../utils/functions";
import { REDIRECT_TO_WALLET } from "./../redux/types";
import fs from "fs";
let Contract = require("web3-eth-contract");
const Web3Utils = require("web3-utils");
const { chainsConfigs, ethMainRPCURL } = ENV;
let chainIds = Object.keys(chainsConfigs);
let marketplaceAddresses = {};
let requiredChainIds = [];
let contractVerifyApis = [];
for (let x = 0; x < chainIds.length; x++) {
  let chainId = parseInt(chainIds[x]);
  marketplaceAddresses[chainId] = chainsConfigs[chainId].marketplaceAddress;
  contractVerifyApis[chainId] = chainsConfigs[chainId].api;
  requiredChainIds.push(chainId);
}
const defaultChainId = ENV.chainId;

export const web3DeployContract = async (tokenStandard = 1, data = []) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 3");
    return false;
  }

  try {
    const connectedAddress = await connectMetamask(web3);
    const chainId = await web3.eth.getChainId();

    const ABI = tokenStandard === 1 ? elumnt721Abi : elumnt1155Abi;
    const bytecode =
      tokenStandard === 1
        ? elumnt721ByteCode.object
        : elumnt1155ByteCode.object;
    const sourceCode =
      tokenStandard === 1 ? elumnt721SourceCode : elumnt1155SourceCode;
    const contractname = tokenStandard === 1 ? "Elumnt721" : "Elumnt1155";
    console.log("contractname", contractname);
    console.log("data", data);
    const encodeArguments =
      tokenStandard === 1
        ? await web3.eth.abi
            .encodeParameters(["string", "string"], data)
            .substring(2)
        : "";

    // console.log("sourceCodesourceCodesourceCodesourceCode sourceCode", sourceCode)
    let contract = new web3.eth.Contract(ABI);
    // data.push(marketplaceAddresses[chainId]);
    let address = null;
    await contract
      .deploy({
        data: bytecode,
        arguments: data,
      })
      .send({
        from: connectedAddress,
        //  gas: 2000000
      })
      .on("receipt", (receipt) => {
        try {
          console.log(
            "Contract Address****:",
            receipt.contractAddress,
            contractVerifyApis[chainId]
          );
          const { url, key } = contractVerifyApis[chainId];
          // Contract Address will be returned here
          address = receipt.contractAddress;

          fetch(sourceCode)
            .then((r) => r.text())
            .then((text) => {
              console.log("text decoded:", text);

              let payload = {};
              payload["apikey"] = key;
              payload["module"] = "contract";
              payload["action"] = "verifysourcecode";
              payload["sourceCode"] = text;
              payload["contractaddress"] = address;
              payload["codeformat"] = "solidity-single-file";
              payload["contractname"] = contractname;
              payload["compilerversion"] = "v0.8.7+commit.e28d00a7";
              payload["optimizationused"] = 1;
              payload["runs"] = 200;
              payload["licenseType"] = 3;
              payload["constructorArguements"] = encodeArguments; ///
              axiosVerifyContract(url, payload);
            });
        } catch (e) {
          console.log("EEEEEEEEEEEEEEEEe", e);
        }
      });

    return address;
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};

export const multiChainBalance = async (app) => {
  try {
    const chainsIds = Object.keys(chainsConfigs);
    let chainsConfigsLocal = { ...chainsConfigs };
    for (let x = 0; x < chainsIds.length; x++) {
      if ([1, 5].includes(Number(chainsIds[x]))) {
        chainsConfigsLocal[chainsIds[x]].rate = app.ethRate;
      } else if ([97, 56].includes(Number(chainsIds[x]))) {
        chainsConfigsLocal[chainsIds[x]].rate = app.rate;
      } else if ([338, 25].includes(Number(chainsIds[x]))) {
        chainsConfigsLocal[chainsIds[x]].rate = app.croRate;
      } else if ([4002, 250].includes(Number(chainsIds[x]))) {
        chainsConfigsLocal[chainsIds[x]].rate = app.fantomRate;
      }
    }
    let totalAmounInUsd = 0;
    const web3 = await getWeb3();
    if (!web3) {
      toast.error("No web3 instance found 1");
      return false;
    }

    const connectedAddress = await connectMetamask(web3);
    let balance = await Promise.all(
      chainsIds.map(async (chain) => {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(chainsConfigsLocal[chain].rpcUrl)
        );
        let userBalance = await web3.eth.getBalance(connectedAddress);
        if (userBalance) userBalance = Web3Utils.fromWei(userBalance, "ether");
        console.log(
          "userBalance, chainsConfigsLocal[chain].rate",
          totalAmounInUsd,
          userBalance,
          chainsConfigsLocal[chain].rate
        );
        let amountInUsd = ENV.convertRateToUsd(
          userBalance,
          chainsConfigsLocal[chain].rate
        );
        totalAmounInUsd += parseFloat(amountInUsd);
        return {
          symbol: chainsConfigsLocal[chain].nativeCurrency.symbol,
          userBalance: userBalance ? parseFloat(userBalance).toFixed(4) : 0,
          amountInUsd: amountInUsd ? parseFloat(amountInUsd).toFixed(4) : 0,
        };
      })
    );

    return {
      balance: balance,
      totalAmounInUsd: totalAmounInUsd
        ? parseFloat(totalAmounInUsd).toFixed(4)
        : 0,
    };
  } catch (E) {
    console.log(E);
  }
};

export const switchBlockChain = async (_chainId) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No wallet found");
    return;
  }
  let currentChainId = await web3.eth.getChainId();
  if (currentChainId !== _chainId) {
    //if network exist in metamast but but we want switch

    await window.library.provider
      .request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: Web3Utils.toHex(_chainId),
          },
        ],
      })
      .then(() => console.log("network has been set"))
      .catch(async (e) => {
        if (e.code === 4902) {
          //if network not set then
          const networkDetails = {
            chainId: `0x${Number(_chainId).toString(16)}`,
            chainName: chainsConfigs[_chainId].networkName,
            nativeCurrency: chainsConfigs[_chainId].nativeCurrency,
            rpcUrls: [chainsConfigs[_chainId].rpcUrl],
            blockExplorerUrls: [chainsConfigs[_chainId].explorer],
          };
          await window.library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkDetails],
          });

          //blockChain Options
          let options = ENV.ChainOptions.find((item) =>
            item.chainIds.includes(Number(_chainId))
          );
          localStorage.setItem("selectedChain", JSON.stringify(options));
        } else {
          console.log("could not set network", e);
        }
      });
  }
};

const call = (method, params) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    method(...params)
      .call()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const send = (method, params, from, value = 0) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    method(...params)
      .send({ from, value })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const methods = {
  call,
  send,
};

const getWeb3Interval = async () => {
  let maxTime = 5000;
  let interval = 500;
  let spentTime = 0;
  return new Promise(async (resolve, reject) => {
    let web3CheckingInterval = setInterval(() => {
      if (window.walletPO) {
        clearInterval(web3CheckingInterval);
        resolve(true);
      } else if (spentTime >= maxTime) {
        clearInterval(web3CheckingInterval);
        resolve(false);
      }
      spentTime += interval;
    }, interval);
  });
};
export const getWeb3 = async () => {
  console.log("gettting web3");
  if (!window.walletPO) {
    let isWeb3SetUp = await getWeb3Interval();
    if (isWeb3SetUp && window.walletPO) {
      return window.walletPO;
    } else {
      console.log("logging out from here");
      // store.dispatch(redirectToWallet());
      // let theme = localStorage.getItem("theme");
      // localStorage.clear();
      // localStorage.setItem("theme", theme);
    }
  } else {
    return window.walletPO;
  }
};
export const connectMetamask = async (web3 = null) => {
  try {
    const { address } = ENV.getUserKeys("address");
    if (address) return address;
  } catch (e) {
    return false;
  }
};

export const signRequest = async () => {
  const web3 = await getWeb3();
  const accounts = await web3.eth.getAccounts();
  const address = accounts[0];
  const signature = await handleSignMessage(address);
  return signature;
};
export const getPercentagesWeb3 = async (value, blockChain) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 2");
    return false;
  }
  try {
    const pixulBalance = await checkBalance(ENV.pixulToken);
    const chainSettings = await getChainSettings(blockChain);
    const { pixulLimit, percentForLess, percentForMore } = chainSettings;
    const serviceFee =
      pixulBalance >= pixulLimit
        ? (parseFloat(percentForMore) * parseFloat(value)) / 100
        : (parseFloat(percentForLess) * parseFloat(value)) / 100;
    return serviceFee?.toFixed(5);
  } catch (e) {
    return false;
  }
};
export const mint = async (nft, _changeSellingStatusNnftData) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 3");
    return false;
  }

  try {
    const connectedAddress = await connectMetamask(web3);
    const chainId = await web3.eth.getChainId();
    const contractAbi = marketplaceAbi;
    const tokenContract = new web3.eth.Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );
    const weiPrice = Web3Utils.toWei(`${nft.amount}`, "ether");
    const { hash, nonce, encodeKey } = await createHash(
      connectedAddress,
      weiPrice
    );
    const signature = await handleSignMessageWithHash(hash, connectedAddress);

    let payload = {
      hash,
      nonce,
      encodeKey,
      weiPrice,
      signature,
    };

    //isFree Minting ON
    //isFree Minting OFF
    //Reject one Tx Cancell other pop-up
    console.log(
      "result",
      _changeSellingStatusNnftData,
      _changeSellingStatusNnftData.nftId,
      _changeSellingStatusNnftData.tokenStandard
    );
    let result = await changeSellingStatusWeb3(
      _changeSellingStatusNnftData,
      _changeSellingStatusNnftData.nftId,
      _changeSellingStatusNnftData.tokenStandard
    );
    if (!result) return false;

    console.log("change selling -status web3", result);
    let res = true;
    if (!nft.isFreeMinting) {
      res = await createDataWeb3(
        nft,
        payload,
        _changeSellingStatusNnftData.tokenStandard
      );
    }

    console.log("Res", res);
    // if (res.txHash) {
    //     console.log("change selling status", _changeSellingStatusNnftData, _changeSellingStatusNnftData._id, _changeSellingStatusNnftData.tokenStandard)
    // }
    return { changeSelllingStatus: result, minting: res };
  } catch (e) {
    console.log("yes  errrorrrrr", e);
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const createDataWeb3 = async (nft, payload, tokenStandard = 1) => {
  console.log("nft, payload, tokenStandard = 1", nft, payload, tokenStandard);
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 4");
    return false;
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    const contractAbi = marketplaceAbi;
    const tokenContract = new web3.eth.Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );

    const { nonce, encodeKey, weiPrice, signature } = payload;

    const create721Data = {
      metadata: nft.metaData,
      owner: connectedAddress,
      nft: nft.address,
      amount: weiPrice,
      encodeKey,
      nonce,
      signature,
    };

    const create1155Data = {
      owner: connectedAddress,
      nft: nft.address,
      amount: weiPrice,
      totalQuantity: nft.copies,
      encodeKey,
      nonce,
      signature,
    };

    const requestData = tokenStandard === 1 ? create721Data : create1155Data;
    console.log("requestData", requestData);
    let txDetails = await methods.send(
      tokenContract.methods[tokenStandard === 1 ? "create721" : "create1155"],
      [requestData],
      connectedAddress
    );

    const txHash = txDetails.transactionHash;
    const { returnValues } = txDetails.events.CreatedNFT;

    return {
      tokenId: returnValues.tokenId,
      txHash,
      chainId,
      acceptSign: signature,
    };
  } catch (e) {
    console.log("eEEEeeee00", e);
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const cancelSellingWeb3 = async (_nftData, _id) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 5");
    return false;
  }
  try {
    const connectedAddress = await connectMetamask(web3);

    const weiPrice = web3.utils.toWei(_nftData.price, "ether");
    const { hash } = await createHash(connectedAddress, weiPrice);
    const signature = await handleSignMessageWithHash(hash, connectedAddress);
    return signature;
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const changeSellingStatusWeb3 = async (_nftData, _id, tokenStandard) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 6");
    return false;
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    const tokenAddress = _nftData.nft;
    console.log("Status call 01");
    if (_nftData.tokenId > 0) {
      const validOwner = await isValidOwner(
        connectedAddress,
        tokenAddress,
        _nftData.tokenId,
        tokenStandard,
        _nftData.copies
      );
      if (!validOwner) {
        toast.error(
          "Unable to complete the listing, you don't seem to be the owner. Metadata will be refreshed for this NFT"
        );
        let payloadData = {
          nftId: _id,
          tokenId: _nftData.tokenId,
          address: _nftData.nft,
        };
        axiosSyncPost("nfts/update-metadata", payloadData);
        return false;
      }
    }

    console.log(
      "connectedAddress, tokenStandard, marketplaceAddresses[chainId], tokenAddress",
      connectedAddress,
      tokenStandard,
      marketplaceAddresses[chainId],
      tokenAddress
    );
    let isApprovedForAll = await isApprovedForAllWeb3(
      connectedAddress,
      tokenStandard,
      marketplaceAddresses[chainId],
      tokenAddress
    );

    if (!isApprovedForAll) {
      isApprovedForAll = await setApprovalForAllWeb3(
        connectedAddress,
        tokenStandard,
        marketplaceAddresses[chainId],
        true,
        tokenAddress
      );
      if (!isApprovedForAll) {
        toast.error("Unable to complete the listing");
        return false;
      }
    }
    console.log("Status call 03");

    return true;
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const buyNowWeb3 = async (_nftData, tokenStandard = 1) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 7");
    return { success: false };
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    if (_nftData.currency !== 1) {
      const cAddress = _nftData.cAddress;
      const approvedAmount = await isApproved(
        marketplaceAddresses[chainId],
        connectedAddress,
        cAddress
      );
      const priceInWei = Web3Utils.toWei(`${_nftData.amount}`, "ether");

      if (parseFloat(priceInWei) > parseFloat(approvedAmount)) {
        const gotApproval = await getApproval(
          marketplaceAddresses[chainId],
          ENV.amountToApprove,
          cAddress
        );

        if (!gotApproval) {
          return false;
        }
      }
    }

    if (_nftData.tokenId > 0) {
      const validOwner = await isValidOwner(
        _nftData.owner,
        _nftData.nft,
        _nftData.tokenId,
        tokenStandard,
        _nftData.copies
      ); //get user-add
      if (!validOwner) {
        toast.error(
          "Unable to complete the listing, you don't seem to be the owner. Metadata will be refreshed for this NFT"
        );
        let payloadData = {
          nftId: _nftData.nftDbId,
          tokenId: _nftData.tokenId,
          address: _nftData.nft,
        };
        axiosSyncPost("nfts/update-metadata", payloadData);
        return { success: false };
      }
    }
    delete _nftData.nftDbId;
    delete _nftData.cAddress;

    const contractAbi = marketplaceAbi;
    const tokenContract = new web3.eth.Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );
    const amount = Web3Utils.toWei(`${_nftData.amount}`, "ether");
    const weiPrice = _nftData.currency == 1 ? amount : 0;
    const { hash, nonce, encodeKey } = await createHash(
      connectedAddress,
      amount
    );
    const signature = await handleSignMessageWithHash(hash, connectedAddress);
    const pixulBalance = await checkBalance(ENV.pixulToken);

    // address erc20token;
    // uint8 currency;

    let requestData = {
      ..._nftData,
      signature,
      amount,
      encodeKey,
      nonce,
      pixel: Number(pixulBalance),
    };
    console.log("REQuest DAta", requestData, tokenContract);

    // string metadata;
    // uint256 pixel;
    // uint256 tokenId;
    // address owner;
    // address creator;
    // address nft;
    // bytes signature;
    // uint256 amount;
    // uint256 percent;
    // uint256 royalty;
    // uint256 collectionId;
    // string encodeKey;
    // uint256 nonce;

    delete requestData.collectionId;
    delete requestData.pixel;
    // delete requestData.currency
    const txDetails = await methods.send(
      tokenContract.methods[tokenStandard === 1 ? "buy721" : "buy1155"],
      [requestData],
      connectedAddress,
      weiPrice //in case of bnb
    );

    console.log("Ttx details", txDetails);
    const txHash = txDetails.transactionHash;
    const creatorEarningWei = Web3Utils.fromWei(
      txDetails?.events?.NftTransferred?.returnValues?.creatorEarning,
      "ether"
    );
    const creatorEarning = creatorEarningWei || 0;
    console.log("Ttx details 2", creatorEarningWei, creatorEarning);

    let response = {
      chainId,
      newOwnerAddress: connectedAddress,
      txHash,
      creatorEarning,
      tokenId: txDetails?.events?.NftTransferred?.returnValues?.tokenId,
      buySign: signature,
    };

    return { success: true, data: response };
  } catch (e) {
    console.log("EEEEEEEEEEEEE", e);
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return { success: false };
  }
};
export const acceptOfferBidWeb3 = async (_nftData, tokenStandard = 1) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 8");
    return false;
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    const contractAbi = marketplaceAbi;
    const tokenContract = new web3.eth.Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );

    const weiPrice = Web3Utils.toWei(`${_nftData.amount}`, "ether");
    const { hash, nonce, encodeKey } = await createHash(
      connectedAddress,
      weiPrice
    );
    const signature = await handleSignMessageWithHash(hash, connectedAddress);
    const pixulBalance = await checkBalance(ENV.pixulToken);

    const requestData = {
      ..._nftData,
      signature,
      encodeKey,
      nonce,
      amount: weiPrice,
      pixel: Number(pixulBalance),
    };
    // string metadata;
    // uint256 tokenId;
    // address newOwner;
    // address creator;
    // address nft;
    // bytes signature;
    // uint256 amount;
    // uint256 percent;
    // uint256 royalty;
    // string encodeKey;
    // uint256 nonce;
    // address erc20token;

    // {
    //     "erc20token": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    //     "metadata": "https://gateway.ipfs.io/ipfs/QmcV34nN1JEBj4sfxzESiiFuWPWhAop9KyGFeUSqqz37kN",
    //     "tokenId": 10,
    //     "newOwner": "0x2F37315Fa0063B8fDB060f9F7bF3d912db09e57d",
    //     "nft": "0xC14677D1b504Ab35c0F670ec8d51943207651490",
    //     "amount": "100000000000000",
    //     "percent": 200,
    //     "creator": "0x72D6C956F9b04C2d10A8220ab8F736F50aB76C8b",
    //     "signature": "0x6c726805f3ec1d96df8ef079f653eeccbfce9a32e1aafdd42c47f8d3b4946b6a4deefe3a5131c2d22e86d6eaf858253f2fb32c65e470becca793fed0bfa6cb671b",
    //     "encodeKey": "LnLgspXfgsjvATXBdLnq",
    //     "nonce": 1668770377614
    // }

    // {
    //     "erc20token": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    //     "metadata": "https://gateway.ipfs.io/ipfs/QmUtJZaFneaVPqaqxoXebGd8cbvcf7cjxxKdkkKpwmgQuY",
    //     "tokenId": 9,
    //     "newOwner": "0x2F37315Fa0063B8fDB060f9F7bF3d912db09e57d",
    //     "nft": "0xC14677D1b504Ab35c0F670ec8d51943207651490",
    //     "amount": "100000000000000",
    //     "percent": 200,
    //     "royalty": 1000,
    //     "creator": "0x72D6C956F9b04C2d10A8220ab8F736F50aB76C8b",
    //     "signature": "0x502827c522627577d5709adaf6675de82ea27d79f38d8fc2e9264563a3e6ba78020d42e4380425458901d0634f93130f69eefd79e289478b0cc0653f87d596a31c",
    //     "encodeKey": "cMcvrPpmbQGVrThwjfsC",
    //     "nonce": 1668769117774
    // }

    delete requestData.collectionId;
    delete requestData.copies;
    delete requestData.pixel;

    console.log(requestData);
    // return;

    let txDetails = await methods.send(
      tokenContract.methods[
        tokenStandard === 1 ? "acceptOfferBid721" : "acceptOfferBid1155"
      ],
      [requestData],
      connectedAddress
    );

    const txHash = txDetails.transactionHash;

    console.log("txHashhhhh", txHash);
    const { returnValues } = txDetails.events.BidOfferAccepted;

    return {
      tokenId: returnValues.tokenId,
      txHash,
      chainId,
      creatorEarning: returnValues.creatorEarning,
      acceptSign: signature,
    };
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};

export const makeOfferWeb3 = async (_nftData) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 9");
    return false;
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    const cAddress = _nftData.cAddress;
    const approvedAmount = await isApproved(
      marketplaceAddresses[chainId],
      connectedAddress,
      cAddress
    );
    const weiPrice = Web3Utils.toWei(`${_nftData.price}`, "ether");

    if (parseInt(weiPrice) > parseInt(approvedAmount)) {
      const gotApproval = await getApproval(
        marketplaceAddresses[chainId],
        ENV.amountToApprove,
        cAddress
      );
      if (!gotApproval) {
        return false;
      }
    }
    return true;
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const placeBidWeb3 = async (_nftData) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 10");
    return false;
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    const cAddress = _nftData.cAddress;
    const approvedAmount = await isApproved(
      marketplaceAddresses[chainId],
      connectedAddress,
      cAddress
    );
    const weiPrice = Web3Utils.toWei(`${_nftData.price}`, "ether");

    if (parseInt(weiPrice) > parseInt(approvedAmount)) {
      const gotApproval = await getApproval(
        marketplaceAddresses[chainId],
        ENV.amountToApprove,
        cAddress
      );
      if (!gotApproval) {
        return false;
      }
    }
    return true;
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const cancelOfferBidWeb3 = async (_nftData) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 11");
    return false;
  }
  try {
    const chainId = await web3.eth.getChainId();
    const connectedAddress = await connectMetamask(web3);
    const contractAbi = marketplaceAbi;
    const tokenContract = new web3.eth.Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );
    const price = 0;
    const { hash, nonce, encodeKey } = await createHash(
      connectedAddress,
      price
    );
    const signature = await handleSignMessageWithHash(hash, connectedAddress);

    return true;
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};

export const transferNFTWeb3 = async (
  _nftData,
  transferAccount,
  tokenStandard = 1
) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 12");
    return false;
  }
  const chainId = await web3.eth.getChainId();
  const isTransferAddressValid = Web3Utils.isAddress(transferAccount);
  if (!isTransferAddressValid) {
    toast.error("Please enter valid Transfer wallet address");
    return false;
  }
  try {
    const connectedAddress = await connectMetamask(web3);
    const tokenAddress = _nftData.nft;

    if (_nftData.tokenId > 0) {
      const validOwner = await isValidOwner(
        connectedAddress,
        tokenAddress,
        _nftData.tokenId,
        tokenStandard,
        _nftData._nftData
      );

      if (!validOwner) {
        toast.error(
          "Unable to complete the listing, you don't seem to be the owner. Metadata will be refreshed for this NFT"
        );
        let payloadData = {
          nftId: _nftData._id,
          tokenId: _nftData.tokenId,
          address: _nftData.nft,
        };
        axiosSyncPost("nfts/update-metadata", payloadData);
        return false;
      }
    }
    delete _nftData._id;
    let isApprovedForAll = await isApprovedForAllWeb3(
      connectedAddress,
      marketplaceAddresses[chainId],
      tokenAddress
    );
    if (!isApprovedForAll) {
      isApprovedForAll = await setApprovalForAllWeb3(
        connectedAddress,
        marketplaceAddresses[chainId],
        true,
        tokenAddress
      );
      if (!isApprovedForAll) {
        toast.error("Unable to complete the listing");
        return false;
      }
    }
    const contractAbi = marketplaceAbi;
    const tokenContract = new web3.eth.Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );

    const weiPrice = Web3Utils.toWei(`${_nftData.amount}`, "ether");
    const { hash, nonce, encodeKey } = await createHash(
      connectedAddress,
      weiPrice
    );
    const signature = await handleSignMessageWithHash(hash, connectedAddress);
    let requestData = {
      ..._nftData,
      signature: signature,
      amount: weiPrice,
      encodeKey,
      nonce,
    };

    const transferNFT = await methods.send(
      tokenContract.methods.transferForFree,
      [requestData],
      connectedAddress
    );
    return { ...transferNFT, chainId };
  } catch (e) {
    const eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};

export const getApproval = async (guy, amount, contractAddress) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 13");
    return false;
  }
  try {
    const connectedAddress = await connectMetamask(web3);
    const tokenContract = new web3.eth.Contract(erc20Abi, contractAddress);
    await methods.send(
      tokenContract.methods.approve,
      [guy, amount],
      connectedAddress
    );

    return true;
  } catch (e) {
    let eMessage = e.message.split("{")[0] || "";
    toast.error(eMessage);
    return false;
  }
};
export const isApproved = async (guy, connectedAddress, contractAddress) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 14");
    return false;
  }
  try {
    const tokenContract = new web3.eth.Contract(erc20Abi, contractAddress);
    const myNewData = await methods.call(tokenContract.methods.allowance, [
      connectedAddress,
      guy,
    ]);

    return myNewData;
  } catch (e) {
    return 0;
  }
};
export const checkBalance = async (cAddress) => {
  const web3 = await getWeb3();
  if (!web3) {
    return false;
  }
  try {
    const connectedAddress = await connectMetamask(web3);
    const tokenContract = new web3.eth.Contract(erc20Abi, cAddress);
    let userBalance = await methods.call(tokenContract.methods.balanceOf, [
      connectedAddress,
    ]);
    if (userBalance) userBalance = Web3Utils.fromWei(userBalance, "ether");
    return userBalance;
  } catch (e) {
    return 0;
  }
};
export const checkPixulBalance = async (cAddress) => {
  let web3 = await getWeb3();
  if (!web3) {
    return false;
  }
  try {
    const contractAbi = erc20Abi;
    await Contract.setProvider(new Web3(ethMainRPCURL));

    web3 = new Web3(new Web3.providers.HttpProvider(ethMainRPCURL));
    let tokenContract = new Contract(contractAbi, cAddress);
    const connectedAddress = await connectMetamask(web3);
    let userBalance = await methods.call(tokenContract.methods.balanceOf, [
      connectedAddress,
    ]);
    if (userBalance) userBalance = Web3Utils.fromWei(userBalance, "ether");
    return userBalance;
  } catch (e) {
    return 0;
  }
};
export const isValidOwner = async (
  owner,
  contractAddress,
  tokenId,
  tokenStandard = 1,
  copies = 1
) => {
  const web3 = await getWeb3();
  const connectedAddress = await connectMetamask(web3);

  if (!web3) {
    toast.error("No web3 instance found 16");
    return false;
  }
  try {
    const tokenContract = new web3.eth.Contract(
      tokenStandard === 1 ? erc721Abi : erc1155Abi,
      contractAddress
    );
    if (tokenStandard === 1) {
      const nftOwner = await methods.call(tokenContract.methods.ownerOf, [
        tokenId,
      ]);
      return nftOwner === owner;
    } else {
      const balance = await methods.call(tokenContract.methods.balanceOf, [
        owner,
        tokenId,
      ]);
      return balance >= copies;
    }
  } catch (e) {
    return false;
  }
};
export const isApprovedForAllWeb3 = async (
  owner,
  tokenStandard,
  operator,
  contractAddress
) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 17");
    return false;
  }
  try {
    const tokenContract = new web3.eth.Contract(
      tokenStandard === 1 ? erc721Abi : erc1155Abi,
      contractAddress
    );
    const isApprovedForAll = await methods.call(
      tokenContract.methods.isApprovedForAll,
      [owner, operator]
    );

    return isApprovedForAll;
  } catch (e) {
    return 0;
  }
};
export const setApprovalForAllWeb3 = async (
  connectedAddress,
  tokenStandard,
  operator,
  value,
  contractAddress
) => {
  const web3 = await getWeb3();
  if (!web3) {
    toast.error("No web3 instance found 18");
    return false;
  }
  try {
    const tokenContract = new web3.eth.Contract(
      tokenStandard === 1 ? erc721Abi : erc1155Abi,
      contractAddress
    );
    await methods.send(
      tokenContract.methods.setApprovalForAll,
      [operator, value],
      connectedAddress
    );

    return true;
  } catch (e) {
    return 0;
  }
};

export const getChainId = async () => {
  try {
    let web3 = await getWeb3();
    return await web3.eth.getChainId();
  } catch (e) {
    return defaultChainId;
  }
};

export const getChainSettings = async (chainNumber) => {
  let web3 = await getWeb3();
  if (!web3) {
    return false;
  }
  try {
    const contractAbi = marketplaceAbi;
    const chainsIds = Object.keys(chainsConfigs);
    const chainId = chainsIds.find((chain) => chain == chainNumber);
    const rpc = chainsConfigs[chainId].rpcUrl;
    await Contract.setProvider(new Web3(rpc));

    web3 = new Web3(new Web3.providers.HttpProvider(rpc));
    let tokenContract = new Contract(
      contractAbi,
      marketplaceAddresses[chainId]
    );
    const percentage = await tokenContract.methods
      .checkPixelLimitWithPercent()
      .call();
    return percentage;
  } catch (e) {
    return false;
  }
};

const handleSignMessage = (address) => {
  if (!address) return;

  return new Promise(async (resolve, reject) => {
    const web3 = await getWeb3();
    web3.eth.personal.sign(
      Web3Utils.fromUtf8(
        `${ENV.appName} uses this cryptographic signature in place of a password, verifying that you are the owner of this address.`
      ),
      address,
      (err, signature) => {
        if (err) return reject(err);
        return resolve(signature);
      }
    );
  });
};
const handleSignMessageWithHash = async (hash, wallet) => {
  const signature = await window.library.provider.request({
    method: "personal_sign",
    params: [hash, wallet],
  });
  let completedSignature = signature;
  if (signature.slice(-2) === "00") {
    completedSignature = signature.slice(0, -2) + "1b";
  } else if (signature.slice(-2) === "01") {
    completedSignature = signature.slice(0, -2) + "1c";
  }
  return completedSignature;
};
const createHash = async (wallet, _amount) => {
  const encodeKey = getEncodeKey();
  const nonce = Date.now();
  const web3 = await getWeb3();
  const hash = await Web3Utils.soliditySha3(wallet, _amount, encodeKey, nonce);
  return { hash, nonce, encodeKey };
};
const getEncodeKey = () => {
  return randomstring.generate({
    length: 20,
    charset: "alphabetic",
  });
};

// redirect user to connect wallet
const redirectToWallet = () => {
  return {
    type: REDIRECT_TO_WALLET,
    payload: true,
  };
};
export const getAddressWithChain = async () => {
  try {
    const web3 = await getWeb3();
    if (!web3) {
      // toast.error("No web3 instance found 21");
      return false;
    }
    const connectedAddress = await connectMetamask(web3);
    const selectedNetwork = await getChainId(web3);
    return {
      connectedAddress,
      selectedNetwork,
    };
  } catch (e) {
    return {
      connectedAddress: "N/A",
    };
  }
};
