import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { ENV } from '../../config/config';
const { defaultChainId, chainsConfigs } = ENV;
const POLLING_INTERVAL = 15000;
let chains =[];
let chainIds = Object.keys(chainsConfigs);
let networks={};
for(let x=0; x < chainIds.length; x++) {
  let chainId = parseInt(chainIds[x]);
  networks[chainId] = chainsConfigs[chainId].rpcUrl;
  chains.push(chainId);
}
export const injected = new InjectedConnector({
  supportedChainIds: chains,
});
export const walletconnect = new WalletConnectConnector({
  rpc: networks,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});
export const walletlink = new WalletLinkConnector({
  url: networks[defaultChainId],
  appName: 'Elumnt.com',
  qrcode: true,
});
const connectors = {
  injected: {
    provider: injected,
    name: 'MetaMask',
  },
  walletConnect: {
    provider: walletconnect,
    name: 'Wallet Connect',
  },
  coinbase: {
    provider: walletlink,
    name: 'Coinbase',
  }
};

export default connectors;
