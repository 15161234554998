import { toast } from "react-toastify";
import { emptyError } from "../../redux/shared/error/error.action";
import { ENV } from "./../../config/config";
import {
  GET_ORDERS,
  BEFORE_ORDERS,
  UPSERT_ORDER,
  GET_ERRORS,
} from "../../redux/types";
export const beforeOrder = () => {
  return {
    type: BEFORE_ORDERS,
  };
};

export const getOrders =
  (body = null) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}orders/list`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "appilcation/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // toast.success(data.message);
          dispatch({
            type: GET_ORDERS,
            payload: data.data,
          });
        } else {
          toast.error(data.message);
          dispatch({ type: GET_ERRORS, payload: data.data });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) toast.error(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
export const editOrder =
  (body = null) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}orders/edit`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "appilcation/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          dispatch({
            type: UPSERT_ORDER,
            payload: data.data,
          });
        } else {
          toast.error(data.message);
          dispatch({
            type: GET_ERRORS,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) toast.error(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
