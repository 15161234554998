import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import {
  updateProfile,
  setIndividualUserData,
  sendEmail,
  beforeUser,
  getUserById,
  setBanner,
} from "./../user/user.action";
import { signRequest } from "./../../utils/web3";
import { ENV } from "./../../config/config";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../full-page-loader/full-page-loader";
import { Col, Row } from "react-bootstrap";
// import AuthorImg from '../../assets/images/author-img.png';
// '/img/avatarplaceholder.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Profile = (props) => {
  const key = ENV.getUserKeys("_id");
  const userId = ENV.getUserKeys()?._id ? ENV.getUserKeys()?._id : undefined;
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState("");
  const [connectedAddress, setConnectedAddress] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [banner, setBannerImage] = useState("");
  const [address, setAddress] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const [description, setDescription] = useState("");
  const [personalPFLink, setPersonalPFLink] = useState(""); //personal portfolio link
  const [twitterLink, setTwitterLink] = useState("");
  const [brandedURLLink, setBrandedURLLink] = useState("");
  const [vineLink, setVineLink] = useState("");
  const [submitCheck, setSubmitCheck] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [prevEmail, setPrevEmail] = useState("");

  const [msg, setMsg] = useState({
    personalPFLink: "",
    twitterLink: "",
    brandedURLLink: "",
    vineLink: "",
  });

  let validator = new SimpleReactValidator({
    autoForceUpdate: this,
    messages: {
      required: "This field is required.", // will override all messages
    },
  });
  const isValidUrl = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  useEffect(() => {
    if (userId !== undefined) setLoader(true);
    props.getUserById(userId);

    if (key._id) {
      let user = ENV.getUserKeys("");
      props.setIndividualUserData(user);
      setConnectedAddress(user.address ? user.address : "");
      setAddress(user.address ? user.address : "");
      setDescription(user.description ? user.description : "");
      setPersonalPFLink(user.personalPFLink ? user.personalPFLink : "");
      setBrandedURLLink(user.username ? user.username : ""); // for making readonly author profile ur
      setTwitterLink(user.twitterLink ? user.twitterLink : "");
      setVineLink(user.vineLink ? user.vineLink : "");
      setUsername(user.username ? user.username : "");
      setFullName(user.fullName ? user.fullName : "");
      setImageUrl(user.profileImage ? user.profileImage : "");
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (props.user.individualUserAuth) {
      props.beforeUser();
      setEmail(
        props.user.individualUser.email ? props.user.individualUser.email : ""
      );
      setPrevEmail(
        props.user.individualUser.email ? props.user.individualUser.email : ""
      );
      setIsVerify(props.user.individualUser.isVerify === 1 ? true : false);
      setLoader(false);
    }
  }, [props.user.individualUserAuth]);

  useEffect(() => {
    if (props.user.userAuth) {
      props.beforeUser();
      setLoader(false);
    }
  }, [props.user.userAuth]);

  useEffect(() => {
    if (props.error) {
      setErrors(props.error.message);
      goToTop();
    }
  }, [props.error]);

  const goToTop = () => {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  };

  const onFileChange = (e) => {
    let file = e.target.files[0];
    let fileURL = "";
    if (file) {
      if (file.type.includes("image")) {
        fileURL = URL.createObjectURL(file);
      } else {
        file = {};
        fileURL = "";
      }
      setImageUrl(fileURL);
      setImage(file);
    }
  };

  const onBannerChange = (e) => {
    let file = e.target.files[0];
    let fileURL = "";
    if (file) {
      if (file.type.includes("image")) {
        fileURL = URL.createObjectURL(file);
      } else {
        file = {};
        fileURL = "";
      }
      setBannerImage(file);
      props.setBanner(fileURL);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setSubmitCheck(true);
    setErrors("");
    setIsSubmitted(true);
    let isFormValid = validator.allValid() ? true : false;
    if (isFormValid) {
      let msgData = {
        personalPFLink: "",
        twitterLink: "",
        brandedURLLink: "",
        vineLink: "",
      };
      if (personalPFLink) {
        if (!isValidUrl(personalPFLink)) {
          msgData.personalPFLink = "Link Is Invalid.";
          goToTop();
        } else {
          msgData.personalPFLink = "";
        }
      }
      // if (twitterLink) {
      //     if (!isValidUrl(twitterLink)) {
      //         msgData.twitterLink = 'Link Is Invalid.'
      //         goToTop();
      //     }
      //     else {
      //         msgData.twitterLink = ''
      //     }
      // }
      // if (brandedURLLink) {
      //     if (!isValidUrl(brandedURLLink)) {
      //         msgData.brandedURLLink = 'Link Is Invalid.'
      //         goToTop();
      //     }
      //     else {
      //         msgData.brandedURLLink = ''
      //     }
      // }

      setMsg(msgData);

      let check = true;

      for (const property in msgData) {
        if (msgData[property] !== "") {
          check = false;
        }
      }

      if (check) {
        try {
          let signature = await signRequest();
          setLoader(true);
          var formData = new FormData();
          if (image) formData.append("profileImage", image);
          formData.append("description", description);
          formData.append("personalPFLink", personalPFLink);
          formData.append("twitterLink", twitterLink);
          formData.append("brandedURLLink", brandedURLLink);
          formData.append("email", email);
          formData.append("fullName", fullName);
          // if (username)
          //     formData.append('username', username)
          formData.append("signature", signature);
          props.updateProfile(formData);
          setBannerImage("");
          setImage("");
          navigate(`/artist/${username}`);
        } catch (e) {}
      }
    } else {
      validator.showMessages();
      setErrors("Please fill all required fields in valid format.");
      goToTop();
    }
  };

  const emailValidate = () => {
    let input = email ? email : "";
    let message = "";
    let isValid = true;

    if (typeof input !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input)) {
        isValid = false;
        message = "Please enter valid email address.";
      }
    }

    setEmailError(message);

    return isValid;
  };

  const handleOnChange = (event) => {
    setEmail(event.target.value);
    emailValidate();
  };

  const submitEmailVerification = async (e) => {
    e.preventDefault();

    if (emailValidate) verifyEmail();
  };

  const verifyEmail = () => {
    if (email !== "" && emailValidate()) props.sendEmail({ email: email });
  };

  return (
    <section className="author-area edit-profile-section">
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="container-fluid profile-container">
          <form id="create-nft">
            {isSubmitted && errors && (
              <div className="row pb-2">
                <div className="col-12">
                  <span id="create-nft-err" className="text-danger">
                    {errors}
                  </span>
                </div>
              </div>
            )}
            <Row>
              <Col md={6}>
                <div className="intro">
                  <div className="intro-content">
                    <h3 className="mt-3 mb-0">Edit profile</h3>
                    <p>
                      Set your preferred display name, create your branded
                      profile URL and customize other personal settings.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="profile-picture d-flex align-items-center">
                  <div className="profile-img">
                    <img
                      src={imageUrl || ENV.userDefaultImg}
                      className="img-fluid"
                    />
                  </div>
                  <div className="profile-img-data ">
                    <p className="my-4">
                      Recommended image size is 300x300 pixels. GIFS are
                      compactible upto a max of 5mb.
                    </p>

                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="image"
                        accept=".png,.jpeg,.jpg,.gif,image/gif"
                        onChange={(e) => onFileChange(e)}
                        name="image"
                      />
                      <label
                        id="nft-imasge-label"
                        className="custom-file-label login-btn"
                        htmlFor="image"
                      >
                        {image && image.name ? "Choose File" : "Choose File"}
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-group name-input">
                  <label>Display name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullName"
                    placeholder="Enter display name"
                    required="required"
                    onKeyUp={(e) => setFullName(e.target.value)}
                    defaultValue={fullName}
                  />
                  <span className="text-danger">
                    {validator.message("fullName", fullName, "required")}
                  </span>
                </div>
                <div className="form-group bio-input">
                  <label>Biography</label>
                  <textarea
                    className="form-control"
                    name="description"
                    placeholder="Tell about yourself in a few words"
                    cols={30}
                    rows={3}
                    onKeyUp={(e) => setDescription(e.target.value)}
                    defaultValue={description}
                  />
                </div>
                <div className="form-group portfolio-input">
                  <label>Profile Portfolio</label>
                  <input
                    type="text"
                    className="form-control"
                    name="PersonalPortfolio"
                    placeholder="https://"
                    cols={30}
                    rows={3}
                    onKeyUp={(e) => {
                      setPersonalPFLink(e.target.value);
                      if (submitCheck) {
                        if (personalPFLink) {
                          if (!isValidUrl(personalPFLink)) {
                            setMsg({
                              ...msg,
                              personalPFLink: "Link Is Invalid.",
                            });
                          } else {
                            setMsg({ ...msg, personalPFLink: "" });
                          }
                        }
                      }
                    }}
                    onChange={(e) => {
                      setPersonalPFLink(e.target.value);
                      if (submitCheck) {
                        if (personalPFLink) {
                          if (!isValidUrl(personalPFLink)) {
                            setMsg({
                              ...msg,
                              personalPFLink: "Link Is Invalid.",
                            });
                          } else {
                            setMsg({ ...msg, personalPFLink: "" });
                          }
                        }
                      }
                    }}
                    defaultValue={personalPFLink}
                  />
                  {personalPFLink ? (
                    <label
                      className={`text-danger pl-1 ${
                        msg.personalPFLink ? `` : `d-none`
                      }`}
                    >
                      {msg.personalPFLink}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md={6}>
                <label>Branded URL</label>
                <div className="input-group branded-input">
                  <span className="input-group-text" id="basic-addon1">
                    {window.location.host}/artist/{brandedURLLink}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    name="brandedURL"
                    // placeholder="enter your custom url"
                    cols={30}
                    rows={3}
                    aria-describedby="basic-addon1"
                    onKeyUp={(e) => {
                      setBrandedURLLink(e.target.value);
                      // if (submitCheck) {
                      //     if (brandedURLLink) {
                      //         if (!isValidUrl(brandedURLLink)) {
                      //             setMsg({ ...msg, brandedURLLink: 'Link Is Invalid.' })
                      //         }
                      //         else {
                      //             setMsg({ ...msg, brandedURLLink: '' })
                      //         }
                      //     }
                      // }
                    }}
                    onChange={(e) => {
                      setBrandedURLLink(e.target.value);
                      // if (submitCheck) {
                      //     if (brandedURLLink) {
                      //         if (!isValidUrl(brandedURLLink)) {
                      //             setMsg({ ...msg, brandedURLLink: 'Link Is Invalid.' })
                      //         }
                      //         else {
                      //             setMsg({ ...msg, brandedURLLink: '' })
                      //         }
                      //     }
                      // }
                    }}
                    //  defaultValue={brandedURLLink}
                  />
                </div>
                {brandedURLLink ? (
                  <label
                    className={`text-danger pl-1 ${
                      msg.brandedURLLink ? `` : `d-none`
                    }`}
                  >
                    {msg.brandedURLLink}
                  </label>
                ) : (
                  ""
                )}

                <label>Connect your Twitter</label>
                <span className="input-detail">
                  Link your Twitter account to gain more exposure and trust
                  within the platform
                </span>
                <div className="input-group  twitter-input">
                  <span className="input-group-text ">@</span>
                  <input
                    type="text"
                    className="form-control"
                    name="twitterLink"
                    placeholder="Enter your Twitter username"
                    cols={30}
                    rows={3}
                    onKeyUp={(e) => {
                      // setTwitterLink(e.target.value)
                      // if (submitCheck) {
                      //     if (twitterLink) {
                      //         if (!isValidUrl(twitterLink)) {
                      //             setMsg({ ...msg, twitterLink: 'Link Is Invalid.' })
                      //         }
                      //         else {
                      //             setMsg({ ...msg, twitterLink: '' })
                      //         }
                      //     }
                      // }
                    }}
                    onChange={(e) => {
                      setTwitterLink(e.target.value);
                      // if (submitCheck) {
                      //     if (twitterLink) {
                      //         if (!isValidUrl(twitterLink)) {
                      //             setMsg({ ...msg, twitterLink: 'Link Is Invalid.' })
                      //         }
                      //         else {
                      //             setMsg({ ...msg, twitterLink: '' })
                      //         }
                      //     }
                      // }
                    }}
                    defaultValue={twitterLink}
                  />
                  {/* <span className="input-group-text custom-input-group-text "><a href="">Link</a></span> */}
                </div>
                {twitterLink ? (
                  <label
                    className={`text-danger pl-1 ${
                      msg.twitterLink ? `` : `d-none`
                    }`}
                  >
                    {msg.twitterLink}
                  </label>
                ) : (
                  ""
                )}

                <label>Email notifications</label>
                <span className="input-detail">
                  Connect your email to receive notifications
                </span>
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    required="required"
                    onChange={handleOnChange}
                    value={email}
                    aria-describedby="basic-addon2"
                  />
                  {!isVerify || prevEmail !== email ? (
                    <span
                      className="input-group-text custom-input-group-text"
                      id="basic-addon2"
                      onClick={submitEmailVerification}
                    >
                      Confirm
                    </span>
                  ) : (
                    <span className="input-group-text custom-input-group-text green-icon">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </div>
                <div className="text-danger">{emailError}</div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="submit-btn text-right">
                  <button
                    disabled={loader}
                    className="btn login-btn"
                    type="button"
                    onClick={(e) => submit(e)}
                  >
                    Update Profile
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, {
  updateProfile,
  sendEmail,
  setIndividualUserData,
  getUserById,
  beforeUser,
  setBanner,
})(Profile);
