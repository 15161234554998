import { BEFORE_USER, SET_CHAT_LIST, SET_INDIVIDUAL_USER } from '../../redux/types';

const initialState = {
    chatlist: null,
    chatlistAuth: false,
    individualUserAuth: false,
    individualUser: null,
}

export default function userRed(state = initialState, action) {
    switch (action.type) {
        case SET_CHAT_LIST:
            return {
                ...state,
                chatlist: action.payload,
                chatlistAuth: true
            }
        case SET_INDIVIDUAL_USER:
            return {
                ...state,
                individualUser: action.payload,
                individualUserAuth: true
            }
        case BEFORE_USER:
            return {
                ...state,
                chatlist: null,
                chatlistAuth: false,
                individualUserAuth: false,
                individualUser: null
            }
        default:
            return {
                ...state
            }
    }
}