import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Navbar, Dropdown, ListGroup, Modal, Form } from 'react-bootstrap';
import { Nav, Button } from "react-bootstrap";
import FullPageLoader from '../full-page-loader/full-page-loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCopy, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useWeb3React } from '@web3-react/core';
import { ENV } from '../../config/config'
import InfiniteScroll from 'react-infinite-scroll-component';
import { io } from "socket.io-client";
import ChatContainer from "./ChatContainer";
import Contacts from "./Contacts";
import Chat from "./Chat";
import { getUserById, getCreators } from '../conversation/chat.action';
import { getNotifications, updateUnReadCount, beforeUser } from '../user/user.action';
import MiniLoader from '../full-page-loader/mini-loader/MiniLoader';
import moment from "moment";
import './chat.css';


const ChatsModal = (props) => {

    const [loader, setLoader] = useState(true)
    const navigate = useNavigate();
    const socket = useRef();
    const [contacts, setContacts] = useState([]);
    const [currentUser, setCurrentUser] = useState(undefined);
    const [currentChat, setCurrentChat] = useState(undefined);

    useEffect(() => {
        props.getUserById(ENV.getUserKeys()?._id)
    }, []);
      
      useEffect(() => { 
        if(props.chat.individualUserAuth){
          setCurrentUser(props.chat.individualUser)
          localStorage.setItem(process.env.REACT_APP_LOCALHOST_KEY, JSON.stringify(props.chat.individualUser))
        }
      }, [props.chat.individualUserAuth]);
      
      useEffect(() => {
        if (currentUser) {
          socket.current = io(process.env.REACT_APP_BASE_URL);
          socket.current.emit("add-user", currentUser._id);
        }
      }, [currentUser]);
    
      useEffect(() => {
        if (currentUser && props.status) {
           props.getCreators();
        }
      }, [currentUser, props.status]);
    
      useEffect(() => {
        if(props.chat.chatlistAuth){
          setContacts(props.chat.chatlist.creators);
          setLoader(false)
          props.beforeUser()
        }
    
      }, [props.chat.chatlistAuth])

      useEffect(()=>{
        if(contacts && props.status)
          props.updateUnReadCount()
      },[contacts])
    
      const handleChatChange = (chat) => {
        setCurrentChat(chat);
        navigate("/live-chat", {state: chat})
        handleCloseModal();
      };

    const handleCloseModal = () => {
        props.closeModal()
    }
    
    return (
        <Modal  show={props.status} onHide={handleCloseModal} className="menu-modal chat-modal ">
            <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleCloseModal} /></span>
            <Modal.Body>
                <div>
                  <div className='d-flex align-items-center justify-content-between custom-chat-header '>
                    <h4 className='your-messages'>Your Messages</h4>
                    <Link to={`/live-chat/`}>
                      <span className='view-all-messages'>View All Messages</span>
                    </Link>
                  </div>
                  {loader ?
                    <div className='chat-modal-laceholder mt-4'>
                      <MiniLoader />
                    </div>
                    :
                    <>
                      {contacts === undefined ? (
                          <section>
                              <h1>
                              Chats not found !
                              </h1>
                              <h3>Please select a chat to Start messaging.</h3>
                          </section>
                      ) : (
                          <Contacts notiUser={props.notiUser} currentUser={currentUser} contacts={contacts} changeChat={handleChatChange} />
                      )}
                    </>
                  }
                </div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
    chat: state.chat
});
  
export default connect(mapStateToProps, { getUserById, updateUnReadCount, getCreators, beforeUser })(ChatsModal);