import React, { Component } from "react";
import Switch from "react-switch";
import {
  Form,
  InputGroup,
  FloatingLabel,
  Modal,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import NFTPreview from "../nft-preview/nft-preview";
import { axiosPostFormData } from "./../../utils/functions";
import { upsertNFT, beforeNFT } from "./../nfts/nfts.action";
import {
  mint,
  getAddressWithChain,
  switchBlockChain,
  web3DeployContract,
} from "./../../utils/web3";
import { toast } from "react-toastify";
import $, { data } from "jquery";
import SimpleReactValidator from "simple-react-validator";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import {
  beforeCollection,
  getCollections,
  upsertCollection,
} from "../collections/collections.actions";
import { beforeCategory, getCategories } from "../categories/categories.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import nftCategoryIconOne from "../../assets/images/nft-category-1.png";
import nftCategoryIconTwo from "../../assets/images/nft-category-2.png";
import nftCategoryIconThree from "../../assets/images/nft-category-3.png";
import lightNftCategoryIconOne from "../../assets/images/light-pen.png";
import lightNftCategoryIconThree from "../../assets/images/light-timer.png";
import produce from "immer";
import moment from "moment";
import arowwIcon from "../../assets/images/arow-icon.png";
import { ENV } from "../../config/config";
import {
  getSettings,
  beforeSettings,
  getTokenSettings,
} from "../home/footer/footer.action";
import Select from "react-select";

import {
  getChainId,
  checkPixulBalance,
  getChainSettings,
} from "./../../utils/web3";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-toastify/dist/ReactToastify.css";
const { chainsConfigs, numberToChainId } = ENV;
let chainIds = Object.keys(chainsConfigs);
let nftContractAddress721 = {};
let nftContractAddress1155 = {};
for (let x = 0; x < chainIds.length; x++) {
  let chainId = parseInt(chainIds[x]);
  nftContractAddress721[chainId] = chainsConfigs[chainId].NFT721Address;
  nftContractAddress1155[chainId] = chainsConfigs[chainId].NFT1155Address;
}

const { integerNumberValidator, decimalNumberValidator, objectToQueryString } =
  ENV;
const placeholderImg = "";

const currenciesTexts = { 1: "ETH", 2: "BNB", 3: "FTM", 4: "CRO" };

class CreateNFT extends Component {
  constructor(props) {
    console.log("construcor call");
    super(props);
    if (window.location.pathname.split("/")[2] === "ethereum") {
      this.blockChainInUrl = 1;
    }
    if (window.location.pathname.split("/")[2] === "binance") {
      this.blockChainInUrl = 2;
    }
    if (window.location.pathname.split("/")[2] === "fantom") {
      this.blockChainInUrl = 3;
    }
    if (window.location.pathname.split("/")[2] === "cronos") {
      this.blockChainInUrl = 4;
    }

    if (window.location.pathname.split("/")[3] === "singlemint") {
      this.mintTypeCheck = 1;
    }
    if (window.location.pathname.split("/")[3] === "multiplemint") {
      this.mintTypeCheck = 2;
    }

    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.params = new URLSearchParams(window.location.search);
    this.handleCollChange = this.handleCollChange.bind(this);
    this.handleCollSubmit = this.handleCollSubmit.bind(this);
    this.onCollFileChange = this.onCollFileChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.unsetNFTImage = this.unsetNFTImage.bind(this);
    this.unSetCollectionImage = this.unSetCollectionImage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.blockChainId = numberToChainId[this.blockChainInUrl];
    this.defaultSymbol =
      chainsConfigs[
        numberToChainId[this.blockChainInUrl]
      ].nativeCurrency.symbol;

    this.handleDateChange = this.handleDateChange.bind(this);

    this.state = {
      theme: localStorage.getItem("theme"),
      pixulBalanceInUSD: null,
      pixulBalance: null,
      chainSettings: null,
      connectedAddress: null,
      selectedNetwork: null,
      serviceFee: null,
      serviceFeePercent: null,
      chainRate: null,
      serviceFeeInUsd: null,
      earnings: null,
      earningsInUsd: null,
      isSubmitted: false,
      formValid: true,
      loader: true,
      errors: "",
      settings: null,
      tokenSettings: null,
      selectedSettings: {
        value: this.defaultSymbol,
        label: this.defaultSymbol,
      },
      nft: {
        image: "",
        name: "",
        description: "",
        unBlockContent: "",
        currentPrice: "",
        royalty: "",
        size: "",
        copies: "",
        collectionId: "",
        status: 1, // 1 = put on sale, 2 = instant sale price, 3 = unlock purchased
        attributes: true,
        blockChain: this.blockChainInUrl,
        mintType: this.mintTypeCheck,
        isFreeMinting: false,
        isUnlockable: false,
        altText: "",
        draft: false,
        royalty: null,
        address: "",
      },
      collectionURL: "",
      collections: null,
      attributes: [
        {
          trait_type: "",
          value: "",
        },
      ],
      attrCheck: true,
      collectionId: null,
      sellingMethod: 1,
      show: false,
      categories: null,
      collection: {
        name: "",
        description: "",
        sybmol: "",
        logo: "",
        categoryId: null,
        address: "",
        mintType: this.mintTypeCheck,
        blockChain: this.blockChainInUrl,
      },
      fixedPriceConfig: {
        ...ENV.config1,
        price: {
          currency: this.defaultSymbol,
          amount: "",
          blockChain: this.blockChainInUrl,
        },
      },
      timedAuctionConfig: {
        ...ENV.config2,
        startPrice: {
          currency: this.defaultSymbol,
          amount: "",
          blockChain: this.blockChainInUrl,
        },
      },
      openForBidConfig: {
        ...ENV.config3,
        startPrice: {
          currency: this.defaultSymbol,
          amount: "",
          blockChain: this.blockChainInUrl,
        },
      },
      date: null,
      lightTheme: false,
    };

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "This is required", // will override all messages
      },
    });
  }
  handleCollChange(e) {
    const { name, value } = e.target;
    const data = this.state.collection;
    data[name] = value;
    this.setState({ ...this.state, ...data });
  }

  handleChange(value, name) {
    this.onChange({
      target: {
        name: name, //`isFreeMinting`,
        value: value, //true false
      },
    });
  }
  handleClose() {
    const unSetCollection = produce(this.state.collection, (draftState) => {
      draftState.name = "";
      draftState.description = "";
      draftState.logo = "";
      draftState.sybmol = "";
    });
    this.setState({ show: false, collection: unSetCollection });
  }
  handleShow() {
    this.setState({ show: true });
  }

  async componentDidMount() {
    console.log("component did mount call again");
    window.scroll(0, 0);
    this.props.getCategories();
    this.props.getSettings();
    let tokenQA = ENV.objectToQueryString({ chain: this.blockChainInUrl });
    this.props.getTokenSettings(tokenQA);

    const filter = {
      userId: ENV.getUserKeys()?._id,
      // all: true,
      blockChain: this.blockChainInUrl,
      mintType: this.mintTypeCheck,
    };
    let qs = ENV.objectToQueryString(filter);
    qs += `&nonCustom=true`;
    const pixulBalance = await checkPixulBalance(ENV.pixulToken);
    const chainSettings = await getChainSettings(this.blockChainId);

    const pixulBalanceInUSD = this.props.app.pixulRate * pixulBalance;

    this.setState({
      pixulBalanceInUSD,
      pixulBalance,
      chainSettings,
    });

    getChainId().then((chainId) => {
      this.setChainId(chainId, qs);
    });

    const { connectedAddress, selectedNetwork } = await getAddressWithChain();
    if (connectedAddress && selectedNetwork) {
      this.setState({
        connectedAddress,
        selectedNetwork,
      });
    }
    this.checkThemeSelection();
    let bodyElements = document.getElementsByTagName("body");
    let bodyElement = bodyElements[0];
    let thisReference = this;
    bodyElement.addEventListener("click", function (e) {
      setTimeout(function () {
        if (bodyElement.classList.contains("light-mode")) {
          thisReference.setState({
            lightTheme: true,
          });
        } else {
          thisReference.setState({
            lightTheme: false,
          });
        }
      }, 1000);
    });

    // const pixulBalance = await checkPixulBalance(ENV.pixulToken);
    // const chainSettings = await getChainSettings(this.blockChainId);

    // //show current seleted Network with Address
    // const { connectedAddress: latestCAddress, selectedNetwork: latestSelectedNetwork } = await getAddressWithChain()
    // if ((latestCAddress && this.state.connectedAddress !== latestCAddress) || (latestSelectedNetwork && this.state.selectedNetwork != latestSelectedNetwork)) {
    //     this.setState({
    //         connectedAddress: latestCAddress,
    //         selectedNetwork: latestSelectedNetwork,
    //         pixulBalance,
    //         chainSettings
    //     })
    // }
  }

  async componentDidUpdate() {
    console.log(
      "this.props.collection.getAuth-00",
      this.props?.settings?.themeSettings &&
        this.state?.theme !== localStorage.getItem("theme")
    );
    console.log("loader.....", this.state.loader);
    if (
      this.props?.settings?.themeSettings &&
      this.state?.theme !== localStorage.getItem("theme")
    ) {
      console.log("theme set");
      this.setState({ theme: this.props.settings.themeSettings });
    }

    const { _id } = ENV.getUserKeys("_id");
    if (_id) {
      console.log(
        "this.props.collection.getAuth-01",
        this.props.collection.getAuth
      );
      if (this.props.collection.getAuth) {
        const { collections } = this.props.collection;
        this.props.beforeCollection();
        this.setState(
          {
            collections,
          },
          () => {
            const { nft } = this.state;

            this.setState({
              loader: false,
              nft: {
                ...nft,
                collectionId: collections[0]?._id,
                address: collections[0]?.address,
              },
            });
          }
        );
      }

      console.log(
        "this.props.collection.upsertAuth-02",
        this.props.collection.upsertAuth
      );
      //
      if (this.props.collection.upsertAuth) {
        const { collection } = this.props.collection.collection;
        const data = this.state.collections;
        data.push(collection);

        //append the newly Created Collection and empty && close the model
        const unSetCollection = produce(this.state.collection, (draftState) => {
          draftState.name = "";
          draftState.description = "";
          draftState.logo = "";
          draftState.sybmol = "";
        });
        this.setState({
          collections: data,
          show: false,
          collection: unSetCollection,
          loader: false,
        });
        this.props.beforeCollection();
      }
      console.log(
        "this.props?.settings?.settingsAuth && this.props?.settings?.tokenSettingsAuth",
        this.props?.settings?.settingsAuth &&
          this.props?.settings?.tokenSettingsAuth
      );
      if (
        this.props?.settings?.settingsAuth &&
        this.props?.settings?.tokenSettingsAuth
      ) {
        let { settings, tokenSettings } = this.props.settings;
        tokenSettings = tokenSettings.map((item) => {
          return { value: item.symbol, label: item.name, image: item.logo };
        });
        this.props.beforeSettings();
        console.log("setttt", tokenSettings);
        const fixedPriceConfig = this.state.fixedPriceConfig;
        fixedPriceConfig.price.currency = tokenSettings[0].value;

        const timedAuctionConfig = this.state.timedAuctionConfig;
        timedAuctionConfig.startPrice.currency = tokenSettings[0].value;

        this.setState({
          ...this.state,
          settings,
          tokenSettings,
          fixedPriceConfig,
          timedAuctionConfig,
        });

        if (this.state.sellingMethod === 1) {
        } else if (this.state.sellingMethod === 2) {
        }
        // this.setState({ ...this.state, settings, tokenSettings })
      }
      // if (this.props?.settings?.tokenSettingsAuth) {
      //     let { tokenSettings } = this.props.settings;
      //     tokenSettings = tokenSettings.map(item => {
      //         return { value: item._id, label: item.name, image: item.logo }
      //     })
      //     this.setState({ ...this.state, tokenSettings }, () => {
      //     })
      // }
      console.log("this.props.category.getAuth", this.props.category.getAuth);
      if (this.props.category.getAuth) {
        console.log("yes");
        const { categories } = this.props.category;
        if (categories.length > 0)
          this.setState({ ...this.state, categories, loader: false }, () => {
            this.props.beforeCategory();
          });
      } else if (this.props.error) {
        console.log("this.props.error", this.props.error);
        this.setState({ loader: false });
      }
    } else {
      this.props.navigate("/login");
    }

    // const pixulBalance = await checkPixulBalance(ENV.pixulToken);
    // const chainSettings = await getChainSettings(this.blockChainId);

    // //show current seleted Network with Address
    // const { connectedAddress: latestCAddress, selectedNetwork: latestSelectedNetwork } = await getAddressWithChain()
    // if ((latestCAddress && this.state.connectedAddress !== latestCAddress) || (latestSelectedNetwork && this.state.selectedNetwork != latestSelectedNetwork)) {
    //     this.setState({
    //         connectedAddress: latestCAddress,
    //         selectedNetwork: latestSelectedNetwork,
    //         pixulBalance,
    //         chainSettings
    //     })
    // }
  }

  handleDateChange(e, picker) {
    const { name } = e.target;
    const { startDate, endDate } = picker;

    // set start date
    this.onChangeDate({
      target: {
        name: `${name}.startDate`,
        value: startDate,
      },
    });

    // set end date
    this.onChangeDate({
      target: {
        name: `${name}.endDate`,
        value: endDate,
      },
    });
  }

  setChainId = (chainId, qs) => {
    this.props.getCollections(chainId, qs);
  };

  unsetNFTImage() {
    const unSetImage = produce(this.state.nft, (draftState) => {
      draftState.image = "";
    });
    this.setState({ nft: unSetImage });
  }
  unSetCollectionImage() {
    const unSetImage = produce(this.state.collection, (draftState) => {
      draftState.logo = "";
    });
    this.setState({ collection: unSetImage });
  }

  onFileChange(e, name) {
    let file = e.target.files[0];
    let fileId = e.target.id;

    if (file) {
      if (file.size < 104857600) {
        //Bytes equilent to 100MB
        if (file.type.includes("image")) {
          let { nft } = this.state;
          nft = { ...nft, [e.target.name]: file };
          this.setState(
            {
              nft,
            },
            () => {
              if (file) {
                var reader = new FileReader();

                reader.onload = function (e) {
                  $(`#nft-${fileId}`).attr("src", e.target.result);
                  $("#nft-image-label").html("File selected");
                };
                reader.readAsDataURL(file);
              }
            }
          );
        } else {
          $(`#nft-${fileId}`).attr("src", placeholderImg);
          file = {};
        }
      } else {
        toast.error("File size is larger than 100mb.");
      }
    }
  }

  onCollFileChange(e) {
    let file = e.target.files[0];
    let fileId = e.target.id;
    if (file)
      if (file.type.includes("image")) {
        let { collection } = this.state;
        collection = { ...collection, [e.target.name]: file };
        this.setState(
          {
            collection,
          },
          () => {
            if (file) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $(`#nft-${fileId}`).attr("src", e.target.result);
                $("#nft-image-label").html("File selected");
              };
              reader.readAsDataURL(file);
            }
          }
        );
      } else {
        $(`#nft-${fileId}`).attr("src", placeholderImg);
        file = {};
      }
  }

  onChange(e) {
    let { name, value } = e.target;
    let { nft, royaltyMsg } = this.state;
    // if attribute is provided
    if (name === "attributes") value = !nft.attributes ? true : false;

    if (name === "royalty" && value > 100) return;

    if (name === "royalty") {
      if (Number(value) <= 1) {
        this.setState({ royaltyMsg: "creator fee must be more than 1" });
      }
      if (Number(value) > 100) {
        this.setState({ royaltyMsg: "creator fee must be less than 100" });
      } else this.setState({ royaltyMsg: "" });
    }

    if (name === "copies") {
      if (Number(value) <= 1)
        this.setState({ copiesMsg: "copies must be more than 1" });
      else if (Number(value) > 1) this.setState({ copiesMsg: "" });
    }

    nft = { ...nft, [name]: value };
    this.setState({ nft });
  }

  onChangeDate = (e) => {
    const { name, value } = e.target;
    let data = ENV.config2;
    const startTime = moment(value, "HH:mm");
    const endTime = moment(value, "HH:mm");
    if (name === "timedAuctionConfig.duration.startDate" && value) {
      data.duration.startTime = startTime;
      data.duration.startDate = moment(new Date(value));
    }
    if (name === "timedAuctionConfig.duration.endDate" && value) {
      data.duration.endTime = endTime;
      data.duration.endDate = moment(new Date(value));
    }
    this.setState({ timedAuctionConfig: data });
  };
  onAttrChange = (e) => {
    const { name, value } = e.target;
    const { attributes } = this.state;

    const startIndex = name.indexOf("[") + 1;
    const endIndex = name.indexOf("]");
    const index = name.substring(startIndex, endIndex);
    const prop = name.split("].")[1]; // trait_type OR value

    attributes[index][prop] = value;

    if (attributes[index].trait_type && attributes[index].value)
      this.setState({ attrCheck: true });

    this.setState({ attributes });
  };

  removeAttr = (index = -1) => {
    let { attributes } = this.state;
    if (attributes.length > 1 && index > -1) {
      attributes.splice(index, 1);
      this.setState({ attributes });
    }
  };

  addAttr = () => {
    const valid = this.checkValidity();
    if (valid) {
      const { attributes } = this.state;
      const data = { trait_type: "", value: "" };
      attributes.push(data);
      this.setState({ attributes, attrCheck: true });
    } else {
      this.setState({ attrCheck: false });
    }
  };

  checkValidity() {
    const { attributes } = this.state;
    return attributes[attributes.length - 1].trait_type &&
      attributes[attributes.length - 1].value
      ? true
      : false;
  }

  reset = () => {
    const nft = {
      image: "",
      name: "",
      description: "",
      currentPrice: "",
      royalty: "",
      size: "",
      copies: "",
      collectionId: "",
      status: 1, // 1 = put on sale, 2 = instant sale price, 3 = unlock purchased
      attributes: false,
    };
    this.setState({
      nft,
      loader: false,
      attributes: [],
      attrCheck: true,
      isSubmitted: false,
    });
  };

  submit = async (e, draft) => {
    try {
      e.preventDefault();
      this.setState({ isSubmitted: true });
      const {
        sellingMethod,
        fixedPriceConfig,
        copiesMsg,
        royaltyMsg,
        serviceFeePercent,
      } = this.state;
      const nft = { ...this.state.nft };

      let { attrCheck } = this.state;
      let copiesCheck = true;

      if (nft.attributes.length > 0) {
        const attributes = await nft.attributes.filter((attr) => {
          if (attr.trait_type && attr.values) return attr;
        });
        nft.attributes = attributes;
      }

      attrCheck = true; //nft.attributes ? this.checkValidity() : true
      if (Number(nft.mintType) === 2) {
        copiesCheck = Boolean(!copiesMsg);

        if (Number(nft.copies) <= 1) {
          copiesCheck = false;
          this.setState({ copiesMsg: "Copies must be more than 1" });
        }

        // set fixed price config qunatity
        if (copiesCheck) {
          this.setState({
            fixedPriceConfig: {
              ...fixedPriceConfig,
              quantity: Number(nft.copies),
            },
          });
        }
      }

      if (Number(nft.royalty)) {
        if (Number(nft.royalty) <= 1) {
          copiesCheck = false;
          this.setState({ royaltyMsg: "creator fee must be more than 1" });
        }
        if (Number(nft.royalty) > 100) {
          copiesCheck = false;
          this.setState({ royaltyMsg: "creator fee be less than 100" });
        }
      }

      nft.draft = draft;
      nft.serviceFeePercent = serviceFeePercent;
      if (sellingMethod) {
        nft.sellingConfig = JSON.stringify(
          sellingMethod === 1
            ? this.state.fixedPriceConfig
            : sellingMethod === 2
            ? this.state.timedAuctionConfig
            : this.state.openForBidConfig
        );

        nft.sellingMethod = sellingMethod;
      }

      this.setState(
        {
          isSubmitted: true,
          formValid: this.validator.allValid() && attrCheck && copiesCheck,
          attrCheck,
          nft,
          fixedPriceConfig: {
            ...fixedPriceConfig,
            quantity:
              copiesCheck && Number(nft.mintType) === 2
                ? Number(nft.copies)
                : fixedPriceConfig.quantity,
          },
        },
        () => {
          const { formValid } = this.state;

          if (formValid) {
            nft.draft = draft;
            if (sellingMethod) {
              nft.sellingConfig = JSON.stringify(
                sellingMethod === 1
                  ? this.state.fixedPriceConfig
                  : sellingMethod === 2
                  ? this.state.timedAuctionConfig
                  : this.state.openForBidConfig
              );
              nft.sellingMethod = sellingMethod;
            }

            //custom validation for fixedPrice and timedAuction amount input
            if (
              (sellingMethod == 1 &&
                this.state.fixedPriceConfig.price.amount == "") ||
              (sellingMethod == 2 &&
                this.state.timedAuctionConfig.startPrice.amount == "")
            ) {
              return;
            }
            if (!this.state.collections || this.state.collections.length == 0) {
              toast.error("Collection Must be selected");
              return;
            }

            this.setState(
              {
                loader: true,
              },
              async () => {
                var formData = new FormData();
                for (const key in nft)
                  if (nft[key] && key !== "attributes")
                    formData.append(key, nft[key]);

                if (nft.attributes && this.state.attributes.length > 0) {
                  let attributes = this.state.attributes;
                  attributes = attributes.filter(
                    (attr) => attr.trait_type && attr.value
                  );
                  if (attributes.length > 0)
                    formData.append(
                      "attributes",
                      window.btoa(JSON.stringify(attributes))
                    );
                }

                if (sellingMethod === 1) {
                  nft.amount = parseFloat(
                    this.state.fixedPriceConfig.price.amount
                  );
                } else {
                  nft.amount = parseFloat(
                    this.state.timedAuctionConfig.startPrice.amount
                  );
                }

                const _chainId =
                  numberToChainId[parseInt(this.state.nft.blockChain)];
                await switchBlockChain(_chainId);
                //Address For right Chain
                // const setNFTAddress =nft.address // parseInt(this.state.nft.mintType) == 1 ? nftContractAddress721[_chainId] : nftContractAddress1155[_chainId]
                // nft.setNFTAddress = setNFTAddress;

                console.log("nftt", nft);
                formData.append(
                  "currencyToken",
                  this.state.selectedSettings.value
                );
                const res = await axiosPostFormData(
                  "nfts/create",
                  formData,
                  true
                );

                if (!nft.draft) {
                  nft.metaData = res.nft.metaData;
                  let _changeSellingStatusNnftData = {
                    nftId: res.nft._id,
                    tokenId: res.nft.tokenId || 0,
                    nft: nft.address,
                    copies: nft.copies || 0,
                    tokenStandard: Number(nft.mintType),
                  };

                  let mintResp = await mint(nft, _changeSellingStatusNnftData);

                  if (!mintResp.changeSelllingStatus || !mintResp.minting)
                    this.setState({ errors: "Web3 Rejection", loader: false });

                  //When Free MInting ONN
                  if (mintResp.changeSelllingStatus && nft.isFreeMinting) {
                    console.log("When free minting ONN submit");
                    this.reset();
                    toast.success(`NFT Created successfully!`);
                    this.setState({
                      loader: false,
                      collectionId: res.nft.collectionId,
                      collectionURL: res.collectionURL,
                    });
                    this.props.navigate(`/collection/${res.collectionURL}`);
                  } else {
                    console.log("When free minting OFF submit");
                    //When Free MInting OFF
                    if (
                      mintResp.changeSelllingStatus &&
                      mintResp?.minting?.tokenId
                    ) {
                      formData.append("tokenId", mintResp.minting.tokenId);
                      formData.append("_id", res.nft._id);
                      const updatedNFT = await axiosPostFormData(
                        "nfts/update-tokenId",
                        formData,
                        true
                      );
                      if (mintResp.changeSelllingStatus && updatedNFT.success) {
                        this.reset();
                        toast.success(`NFT Created successfully! `);
                        this.setState({
                          loader: false,
                          collectionId: res.nft.collectionId,
                          collectionURL: res.collectionURL,
                        });
                        this.props.navigate(`/collection/${res.collectionURL}`);
                      }
                      this.setState({ errors: res.message, loader: false });
                    }
                  }
                } else {
                  this.reset();
                  toast.success(`NFT Created successfully!`);
                  this.setState({
                    loader: false,
                    collectionId: res.nft.collectionId,
                    collectionURL: res.collectionURL,
                  });
                  this.props.navigate(`/collection/${res.collectionURL}`);
                }
              }
            );
          } else {
            this.validator.showMessages();
            this.setState(
              {
                errors: "Please fill all required fields in valid format.",
                formValid: false,
              },
              () => {
                $("#cNFTSection").scrollTop(0, 0);
              }
            );
          }
        }
      );

      window.scroll(0, 0);
    } catch (e) {
      console.log("e", e);
    }
  };

  handleCollSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loader: true });
    // custom jquery
    $("body.modal-open >:not(.modal").css({ filter: "blur(0px)" });

    const collection = this.state.collection;

    //Create Collection On BlockChain
    //need collection type 721 or 1155 and it will return contract address
    //if 721 name and symbol required e.g [name,sybmol]
    // else empty arr

    const _chainId = numberToChainId[parseInt(this.state.nft.blockChain)];
    await switchBlockChain(_chainId);
    // this.handleClose();
    let data =
      collection.mintType == 1 ? [collection.name, collection.sybmol] : [];
    collection.address = await web3DeployContract(
      parseInt(collection.mintType),
      data
    );
    if (!collection.address) return;
    var formData = new FormData();
    for (const key in collection) {
      if (collection[key]) formData.append(key, collection[key]);
    }
    // const _chainId = numberToChainId[Number(this.blockChainInUrl)]
    // const setNFTAddress = parseInt(this.state.collection.mintType) === 1 ? nftContractAddress721[_chainId] : nftContractAddress1155[_chainId]
    // formData.append('address', setNFTAddress)
    this.props.upsertCollection("collection/create", formData);
    // this.setState({ loader: false });
  };

  handlePrice = (e, field) => {
    const { value } = e.target;
    const { app } = this.props;
    if (value >= 0.00001 || value == 0) {
      const rates = this.props.app.allRates;
      console.log("rates", rates);

      let percentageSettings = this.state.settings.percentageSettings;

      percentageSettings?.sort(function (a, b) {
        return a.maxPixulLimit - b.maxPixulLimit;
      });

      let serviceFee = 0,
        earnings = 0,
        serviceFeeInUsd = 0,
        earningsInUsd = 0,
        chainRate = 0,
        serviceFeePercent = 0;
      const { pixulBalanceInUSD, selectedSettings } = this.state;
      const currentRates = rates[`${selectedSettings?.value}toUSD`];

      if (value && currentRates) {
        const seletecedSetting = percentageSettings.find(
          (percent) => pixulBalanceInUSD <= percent.maxPixulLimit
        );

        serviceFeePercent = seletecedSetting.serviceFeePecentage;
        serviceFee = (serviceFeePercent * parseFloat(value)) / 100;

        serviceFeeInUsd = ENV.convertRateToUsd(serviceFee, currentRates);
        chainRate = ENV.convertChainRate(serviceFeeInUsd, currentRates);

        earnings = parseFloat(value) - parseFloat(serviceFee);
        earningsInUsd = ENV.convertRateToUsd(earnings, currentRates);
      }
      // }
      if (field === "minBid") {
        const data = this.state.timedAuctionConfig;
        data.startPrice.amount = value;
        this.setState({
          openForBidConfig: data,
          serviceFee,
          earnings,
          serviceFeeInUsd,
          earningsInUsd,
          chainRate,
          serviceFeePercent,
        });
      }

      if (field === "fPrice") {
        const data = this.state.fixedPriceConfig;
        data.price.amount = value;
        this.setState({
          fixedPriceConfig: data,
          serviceFee,
          earnings,
          serviceFeeInUsd,
          earningsInUsd,
          chainRate,
          serviceFeePercent,
        });
      }
    }
  };

  isDisabled = () => {
    const { name, logo, sybmol } = this.state.collection;
    if (!name || !logo || !sybmol) return true;
    return false;
  };

  checkThemeSelection = () => {
    let bodyElement = document.getElementsByTagName("body");
    if (bodyElement[0].classList.contains("light-mode")) {
      this.setState({
        lightTheme: true,
      });
    } else {
      this.setState({
        lightTheme: false,
      });
    }
  };

  formatAddress = (address) => {
    return address ? address.substr(0, 7) + "..." + address.substr(-4) : null;
  };

  setNetwork = async (chainId, selectedNetwork) => {
    if (Number(selectedNetwork) !== Number(chainId))
      this.setState({ selectedNetwork: chainId });
  };
  handleCurrencySelection = (selectedSettings) => {
    const fixedPriceConfig = this.state.fixedPriceConfig;
    fixedPriceConfig.price.currency = selectedSettings.value;
    fixedPriceConfig.price.amount = 0;

    const timedAuctionConfig = this.state.timedAuctionConfig;
    timedAuctionConfig.startPrice.amount = 0;

    let e = { target: { value: 0 } };
    this.handlePrice(e, "fPrice");
    // this.handlePrice(e, 'minBid')

    this.setState({ selectedSettings, fixedPriceConfig, timedAuctionConfig });
  };

  render() {
    const {
      selectedNetwork,
      nft,
      errors,
      loader,
      isSubmitted,
      collections,
      attributes,
      attrCheck,
      fixedPriceConfig,
      copiesMsg,
      royaltyMsg,
    } = this.state;
    //set Connected chain
    window.ethereum.on("networkChanged", (chainId) =>
      this.setNetwork(chainId, selectedNetwork)
    );
    // { console.log("setttttttttttings tokenSettings", this.state.tokenSettings) }

    return (
      <>
        {this.state.loader ? (
          <FullPageLoader />
        ) : (
          <section
            id="cNFTSection"
            className=" create-nft-area create-nft-section"
          >
            {console.log("Full page Loader", this.state.loader)}
            <div className=" container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="intro text-center">
                    <div className="intro-content">
                      <h5>Unique. One of a kind.</h5>
                      <h3 className=" mb-0">Create Your NFT</h3>
                      <p>
                        {Number(nft.mintType) === 1 ? "Single" : "Multiple"}{" "}
                        Non-Fungible Token Mint on{" "}
                        {chainsConfigs[numberToChainId[nft.blockChain]].title}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7">
                  <div className="detail-wrapper">
                    <div className="nft-detail">
                      <h6>NFT Details</h6>
                      <p>
                        Enter a fixed price to allow Mutoplace users to
                        instantly purchase your NFT
                      </p>
                    </div>
                    {/* selling method types */}
                    <>
                      {Number(nft?.mintType) === 1 && (
                        <div className="categoriess py-4 d-flex">
                          <button
                            onClick={() => {
                              this.setState({ sellingMethod: 1 });
                            }}
                            className={`nft-category ${
                              this.state.sellingMethod === 1 && "active"
                            }`}
                          >
                            <div className="content">
                              <div className="nft-category-image dark-version-icons">
                                <img src={nftCategoryIconOne} alt="icon" />
                              </div>
                              <div className="nft-category-image light-version-icons">
                                <img src={lightNftCategoryIconOne} alt="icon" />
                              </div>
                              <span>Fixed price</span>
                            </div>
                          </button>
                          <button
                            onClick={() => {
                              this.setState({ sellingMethod: 2 });
                            }}
                            className={`nft-category ${
                              this.state.sellingMethod === 2 && "active"
                            }`}
                          >
                            <div className="content">
                              <div className="nft-category-image dark-version-icons">
                                <img src={nftCategoryIconThree} alt="icon" />
                              </div>
                              <div className="nft-category-image light-version-icons">
                                <img
                                  src={lightNftCategoryIconThree}
                                  alt="icon"
                                />
                              </div>{" "}
                              <span>Timed auction</span>
                            </div>
                          </button>
                        </div>
                      )}
                    </>

                    <>
                      {this.state.tokenSettings?.length && (
                        <div className="create-blockchain-section ">
                          {/* <span className="bockchain-tag">Choose Ethereum OR Ethereum Chain</span> */}
                          <p>Select BlockChain</p>
                          <span className="create-blockchain-select mt-3">
                            <div className="elumnt-select-create-nft-btn">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="circle-check"
                                class="svg-inline--fa fa-circle-check fa-gradient"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                                ></path>
                              </svg>
                            </div>

                            <Select
                              // value={this.state.selectedSettings}
                              defaultValue={this.state.tokenSettings[0]}
                              formatOptionLabel={(country) => (
                                <div className="country-option">
                                  <img
                                    src={country.image}
                                    alt="country-image"
                                  />
                                  <span>{country.label}</span>
                                </div>
                              )}
                              onChange={(selectedSettings) =>
                                this.handleCurrencySelection(selectedSettings)
                              }
                              options={this.state.tokenSettings}
                              isSearchable={false}
                              classNamePrefix="elumnt-select-create-nft"
                            />
                          </span>
                        </div>
                      )}
                    </>

                    {/* category data */}
                    {this.state.sellingMethod === 1 && (
                      <div className="nft-category-data mb-4">
                        <div>
                          <h4>Enter the price</h4>
                          <InputGroup className="mb-4">
                            <Form.Control
                              placeholder="Example: 1.3"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              type="text"
                              onKeyDown={(e) => decimalNumberValidator(e)}
                              name="amount"
                              required
                              value={this.state.fixedPriceConfig.price.amount}
                              onChange={(e) => this.handlePrice(e, "fPrice")}
                            />
                            <InputGroup.Text id="basic-addon2">
                              {this.state.selectedSettings?.value}
                            </InputGroup.Text>
                          </InputGroup>

                          {!this.state.fixedPriceConfig.price.amount &&
                            isSubmitted && (
                              <span className="text-danger mb-4">
                                Price is Required
                              </span>
                            )}
                        </div>
                        {this.state.serviceFee > 0 && (
                          <div className="fees-earning mt-4">
                            <div className="d-flex content custom-border justify-content-between align-items-center">
                              <h5>Mutoplace Service fee</h5>
                              <div className="d-flex align-items-center">
                                <span>
                                  $
                                  {parseFloat(
                                    this.state.serviceFeeInUsd
                                  ).toFixed(10)}
                                </span>
                                <span className="eth-val ml-4">
                                  {parseFloat(this.state.chainRate).toFixed(10)}{" "}
                                  {` ` + this.state.selectedSettings?.value}
                                </span>
                                <span className="eth-val ml-4">
                                  {parseFloat(
                                    this.state.serviceFeePercent
                                  ).toFixed(2)}
                                  %
                                </span>
                              </div>
                            </div>

                            <div className="d-flex content justify-content-between align-items-center">
                              <h5>Your earnings</h5>
                              <div>
                                <span>
                                  $
                                  {parseFloat(this.state.earningsInUsd).toFixed(
                                    10
                                  )}
                                </span>
                                <span className="eth-val ml-4">
                                  {parseFloat(this.state.earnings).toFixed(10)}
                                  {`${this.state.selectedSettings?.value}`}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* category data one*/}
                    {this.state.sellingMethod === 2 && (
                      <div className="nft-category-data mb-4 py-3">
                        <h4>Minimum bid</h4>
                        <InputGroup className="mb-4">
                          <Form.Control
                            placeholder="Enter minimum bid "
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            name="amount"
                            // type='number'
                            value={
                              this.state.timedAuctionConfig.startPrice.amount
                            }
                            type="text"
                            onKeyDown={(e) => decimalNumberValidator(e)}
                            onChange={(e) => this.handlePrice(e, "minBid")}
                          />
                          <InputGroup.Text id="basic-addon2">
                            W
                            {
                              chainsConfigs[
                                numberToChainId[this.state.nft.blockChain]
                              ].nativeCurrency.symbol
                            }
                          </InputGroup.Text>
                        </InputGroup>

                        {!this.state.timedAuctionConfig.startPrice.amount &&
                          isSubmitted && (
                            <span className="text-danger">Bid is Required</span>
                          )}

                        <div className="date-picker">
                          <DateRangePicker
                            initialSettings={ENV.dateRangeInitialSettings}
                            onApply={this.handleDateChange}
                          >
                            <button
                              name="timedAuctionConfig.duration"
                              className="calender-btn"
                              data-toggle="tooltip"
                              title="Date"
                            >
                              <i className="fas fa-calendar-week " />
                              &nbsp;&nbsp;&nbsp;
                              {this.state.timedAuctionConfig.duration.startDate.format(
                                "MMMM D, YYYY"
                              )}{" "}
                              -{" "}
                              {this.state.timedAuctionConfig.duration.endDate.format(
                                "MMMM D, YYYY"
                              )}
                            </button>
                          </DateRangePicker>
                        </div>
                      </div>
                    )}
                    {this.state.sellingMethod === 3 && (
                      <div className="nft-category-data mb-4 py-3">
                        <h4>Minimum bid</h4>
                        <InputGroup className="mb-4">
                          <Form.Control
                            placeholder="Enter minimum bid "
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            name="amount"
                            // type='number'
                            type="text"
                            onKeyDown={(e) => decimalNumberValidator(e)}
                            onChange={(e) => this.handlePrice(e, "minBid")}
                          />
                          <InputGroup.Text id="basic-addon2">
                            w
                            {
                              chainsConfigs[
                                numberToChainId[this.state.nft.blockChain]
                              ].nativeCurrency.symbol
                            }
                          </InputGroup.Text>
                        </InputGroup>

                        {!this.state.timedAuctionConfig.startPrice.amount &&
                          isSubmitted && (
                            <span className="text-danger">Bid is Required</span>
                          )}

                        <div className="date-picker">
                          <DateRangePicker
                            initialSettings={ENV.dateRangeInitialSettings}
                            onApply={this.handleDateChange}
                          >
                            <button
                              name="timedAuctionConfig.duration"
                              className="calender-btn"
                              data-toggle="tooltip"
                              title="Date"
                            >
                              <i className="fas fa-calendar-week " />
                              &nbsp;&nbsp;&nbsp;
                              {this.state.timedAuctionConfig.duration.startDate.format(
                                "MMMM D, YYYY"
                              )}{" "}
                              -{" "}
                              {this.state.timedAuctionConfig.duration.endDate.format(
                                "MMMM D, YYYY"
                              )}
                            </button>
                          </DateRangePicker>
                        </div>
                      </div>
                    )}
                    <div className="choose-collection  nft-category mb-5 pt-5">
                      <div className="nft-detail mb-4">
                        <h6>Choose a collection</h6>
                        <p>Select a collection for your NFT.</p>
                      </div>
                      <div className="d-flex  align-items-center justify-content-lg-start justify-content-md-start justify-content-sm-between flex-wrap responsive-choose-collection">
                        <button
                          className={`nft-category `}
                          onClick={this.handleShow}
                        >
                          <div className="content">
                            <div className="nft-category-image ">
                              <small>+</small>
                            </div>
                            <span>
                              Create Your Own Collection{" "}
                              {nft?.mintType == 1 ? "ERC 721" : "ERC 1155"}
                            </span>
                          </div>
                        </button>
                        <>
                          {this.state.collections?.map((coll, index) => {
                            return (
                              <button
                                key={index}
                                className={`nft-category ${
                                  (coll._id === this.state.nft.collectionId ||
                                    (!this.state.nft.collectionId &&
                                      index === 0)) && //by-default selected firrst collection
                                  "active"
                                }`}
                                onClick={() => {
                                  // this.handleChange(coll._id, 'collectionId');
                                  // this.handleChange(coll.address, 'address');

                                  // set the address of selected collection
                                  if (coll.address) {
                                    const setAddress = produce(
                                      this.state.nft,
                                      (draftState) => {
                                        draftState.collectionId = coll._id;
                                        draftState.address = coll.address;
                                      }
                                    );
                                    this.setState({ nft: setAddress });
                                  }
                                }}
                              >
                                <div className="content">
                                  <div className="choose-colection-image">
                                    <img src={coll?.logo} className="" alt="" />
                                  </div>

                                  <span>{coll.name}</span>
                                </div>
                              </button>
                            );
                          })}
                        </>
                      </div>
                    </div>
                    <div className="free-minting d-flex justify-content-between">
                      <div className="nft-detail mb-4">
                        <h6>Free Minting</h6>
                        <p>Buyer will pay gas fees for minting your NFT</p>
                      </div>
                      <div className="example">
                        <label htmlFor="material-switch">
                          <Switch
                            checked={this.state.nft.isFreeMinting}
                            onChange={(e) =>
                              this.handleChange(e, "isFreeMinting")
                            }
                            onColor="#ef87de"
                            offColor={
                              this.state.lightTheme ? "#ebebeb" : "#7c7b7a"
                            }
                            onHandleColor="#2693e6"
                            handleDiameter={15}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={27}
                            width={70}
                            className="react-switch"
                            id="material-switch"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="custom-input-area mb-3">
                      <Form.Group className="mb-4" controlId="name">
                        <Form.Label className="mb-3">Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Example: “Elumnt PFP NFT #2”"
                          name="name"
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                        <span className="text-danger">
                          {this.validator.message("name", nft.name, "required")}
                        </span>
                      </Form.Group>
                      <div className="form-group">
                        <Form.Label className="mb-3" name="description">
                          Description
                        </Form.Label>
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Example: “The #2 NFT of the Elumnt PFP Collection”"
                          cols={30}
                          rows={4}
                          onChange={(e) => this.onChange(e)}
                          defaultValue={nft.description}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "description",
                            nft.description,
                            "required"
                          )}
                        </span>
                      </div>
                      <Form.Label className="mb-3">Royalties</Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          placeholder="Example:0%,10%,30%,Maximum is 50%"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          name="royalty"
                          value={this.state.nft.royalty}
                          type="number"
                          min={0}
                          max={50}
                          onChange={(e) => this.onChange(e)}
                          defaultValue={nft.royalty}
                          onKeyDown={(e) => integerNumberValidator(e)}
                          required
                        />

                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                      </InputGroup>
                      <div>
                        <span className="text-danger">
                          {this.validator.message(
                            "royalty",
                            nft.royalty,
                            "required"
                          )}
                        </span>
                      </div>
                      <div>
                        {isSubmitted && royaltyMsg && (
                          <span className="text-danger">{royaltyMsg}</span>
                        )}
                      </div>

                      {/* copies / supply */}
                      {Number(nft.mintType) === 2 && (
                        <Form.Group className="mb-4" controlId="copies">
                          <Form.Label className="mb-3">Copies</Form.Label>
                          <Form.Control
                            type="text"
                            name="copies"
                            placeholder="Example: 2 or 3 ..."
                            onChange={(e) => this.onChange(e)}
                            defaultValue={nft.copies}
                            onKeyDown={(e) => integerNumberValidator(e)}
                            required
                          />
                          <span className="text-danger">
                            {this.validator.message(
                              "copies",
                              nft.copies,
                              "required"
                            )}
                          </span>
                          {nft.copies && isSubmitted && copiesMsg && (
                            <span className="text-danger">{copiesMsg}</span>
                          )}
                        </Form.Group>
                      )}

                      <Form.Label>
                        Properties
                        <small className="small-text"> (Optional)</small>
                      </Form.Label>
                      {
                        <>
                          {attributes.map((attr, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row position-relative attribute-row">
                                  <div className="col-lg-6">
                                    <div className="form-group ">
                                      <label className="custom-small-label">
                                        Property Category
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        id={`attributes[${index}].trait_type`}
                                        name={`attributes[${index}].trait_type`}
                                        value={attributes[index].trait_type}
                                        placeholder="ex. Color "
                                        onChange={(e) => this.onAttrChange(e)}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div>
                                      <div className="form-group custom-add-property">
                                        <label className="custom-small-label">
                                          Category Description
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          id={`attributes[${index}].value`}
                                          name={`attributes[${index}].value`}
                                          value={attributes[index].value}
                                          placeholder="ex. Red"
                                          onChange={(e) => this.onAttrChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {index > 0 && (
                                    <span
                                      type="button"
                                      className="btn-action btn-minus"
                                      onClick={() => this.removeAttr(index)}
                                    >
                                      <FontAwesomeIcon icon={faMinus} />
                                    </span>
                                  )}
                                </div>
                                {attributes.length - 1 === index && (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className=" add-new-property">
                                        <button
                                          type="button btn btn-danger "
                                          className="btn-action "
                                          onClick={this.addAttr}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </>
                      }
                    </div>
                    <div className="d-flex justify-content-between pt-4 cratee-nft-btns ">
                      <div className="create-nft-loader-btn ">
                        <button
                          disabled={loader}
                          className="btn "
                          type="button"
                          onClick={(e) => this.submit(e, false)}
                        >
                          Create NFT
                        </button>
                      </div>
                      <div className="save-to-draft-btn ">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={(e) => this.submit(e, true)}
                        >
                          Save to Drafts
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-sm-last order-first">
                  <div className="detail-wrapper-1">
                    {this.state.connectedAddress && (
                      <div className="connect-wallet">
                        <h4>Connected Wallet</h4>
                        <div className="connect d-flex align-items-center ">
                          <div>
                            <img
                              src={`${
                                chainsConfigs[
                                  Number(this.state.selectedNetwork)
                                ].currencyImage
                              }`}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content flex-grow-1">
                            <h3>
                              {ENV.formatAddress(this.state.connectedAddress)}
                            </h3>
                            <p>
                              {
                                ENV.requiredChainName[
                                  Number(this.state.selectedNetwork)
                                ]
                              }
                            </p>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              className="fa-gradient"
                              icon={faCircleCheck}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="nft-priview py-5">
                      <h4>Preview of your NFT</h4>
                      <div
                        className="nft-image"
                        style={this.state.nft.image ? { border: "none" } : null}
                      >
                        <NFTPreview
                          image={this.state.nft.image}
                          name="nft"
                          unsetNFTImage={this.unsetNFTImage}
                          onFileChange={(e, name) => this.onFileChange(e, name)}
                        />
                      </div>
                      <span className="text-danger">
                        {this.validator.message(
                          "image",
                          this.state.nft.image,
                          "required"
                        )}
                      </span>

                      {this.state.nft.image && (
                        <div className="about-nft d-flex  align-items-center justify-content-between pt-4">
                          <div>
                            <h3>
                              {this.state?.collections.find(
                                (item) =>
                                  item._id == this.state?.nft?.collectionId
                              )?.name || this.state?.collections[0]?.name}
                            </h3>
                            <p>
                              {this.state.nft?.name
                                ? this.state.nft?.name
                                : "untitled"}{" "}
                            </p>
                          </div>
                          <div className="nft-category-data ">
                            <InputGroup>
                              <Form.Control
                                placeholder="Price: "
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className="mini-control"
                                readOnly
                              />
                              <InputGroup.Text
                                className="text-light"
                                id="basic-addon2"
                              >
                                {
                                  this.state.sellingMethod === 1
                                    ? "" +
                                      this.state.fixedPriceConfig.price.amount +
                                      " " +
                                      this.state.selectedSettings?.value
                                    : this.state.sellingMethod === 2
                                    ? "Not For Sale" //this.state.timedAuctionConfig.startPrice.amount :
                                    : "Open For Bids" //this.state.openForBidConfig.startPrice.amount
                                }{" "}
                                {""}
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="free-minting d-flex justify-content-between mb-3">
                      <div className="nft-detail mb-4">
                        <h6>Unlockable Content</h6>
                        <p>
                          Content will be unlocked after a successful
                          transaction
                        </p>
                      </div>
                      <div className="example">
                        <Switch
                          checked={this.state.nft.isUnlockable}
                          onChange={(e) => this.handleChange(e, "isUnlockable")}
                          onColor="#ef87de"
                          offColor={
                            this.state.lightTheme ? "#ebebeb" : "#7c7b7a"
                          }
                          onHandleColor="#2693e6"
                          handleDiameter={15}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={27}
                          width={70}
                          className="react-switch"
                          id="material-switch"
                        />
                      </div>
                    </div>
                    {this.state.nft.isUnlockable && (
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        className="custom-text-area"
                      >
                        <Form.Control
                          name="unBlockContent"
                          onChange={this.onChange}
                          as="textarea"
                          placeholder="Enter text only such as a code to redeem, 
                                            a digital key, a link to a file, or a password..."
                          style={{ height: "165px" }}
                        />
                      </FloatingLabel>
                    )}
                  </div>

                  <Modal
                    show={this.state.show}
                    dialogClassName="modal-10w custom-modal"
                    onHide={this.handleClose}
                  >
                    <div
                      className="collection-close-btn"
                      onClick={this.handleClose}
                    >
                      <span>x</span>
                    </div>
                    <Modal.Header>
                      <Modal.Title className="text-center">
                        <h2>Create a collection</h2>
                        <p>{nft?.mintType == 1 ? "ERC 721" : "ERC 1155"}</p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form onSubmit={this.handleCollSubmit}>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                          <Form.Label className="mb-3">
                            Display name<span> (required)</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            onChange={this.handleCollChange}
                            placeholder="Enter collection name"
                          />
                        </Form.Group>
                        <div>
                          <p className="cannot-be-changed">
                            Name cannot be changed in the future
                          </p>
                        </div>
                        <Form.Label className="mb-3">
                          Logo<span> (required)</span>
                        </Form.Label>
                        <div className="d-flex align-items-center justify-content-between upload-image-area mb-5">
                          <div className="upload-img">
                            {this.state.collection.logo ? (
                              <>
                                <div
                                  className="close-btnn"
                                  onClick={this.unSetCollectionImage}
                                >
                                  x
                                </div>
                                <img
                                  id="col-image"
                                  src={URL.createObjectURL(
                                    this.state.collection.logo
                                  )}
                                  alt="alt text"
                                  className="img-fluid"
                                />
                              </>
                            ) : (
                              <label>
                                {" "}
                                +
                                <input
                                  type="file"
                                  name="logo"
                                  onChange={this.onCollFileChange}
                                />
                              </label>
                            )}
                          </div>
                          <div>
                            <p>
                              Recommended sizes are 300x300 or 500x500. Gifs are
                              compatible. Maximum size: 5mb.
                            </p>
                          </div>
                        </div>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label className="mb-3">
                            Category<span> (required)</span>
                          </Form.Label>
                          <select
                            className="form-control"
                            id="category"
                            name="categoryId"
                            onChange={this.handleCollChange}
                          >
                            <option value="" selected disabled hidden>
                              Select Category
                            </option>
                            {this.state.categories &&
                              this.state.categories.map((category, index) => {
                                return (
                                  <option key={index} value={category._id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                          </select>
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label className="mb-3">
                            Token Symbol<span> (required)</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="sybmol"
                            onChange={this.handleCollChange}
                            placeholder="Enter token symbol"
                          />
                        </Form.Group>
                        <div className="form-group">
                          <Form.Label className="mb-4">
                            Description
                            <span className="optional"> (optional)</span>
                          </Form.Label>
                          <textarea
                            className="form-control"
                            name="description"
                            placeholder="Describe what the collection will contain,or history about the collection"
                            cols={30}
                            rows={4}
                            onChange={this.handleCollChange}
                          />
                          <span className="text-danger"></span>
                        </div>
                        <Form.Label className="mb-4">
                          Unique Collection URL
                          <small className="small-text"> (Optional)</small>
                        </Form.Label>
                        <InputGroup className="mb-5">
                          <InputGroup.Text id="basic-addon1">
                            https://mutoplace.metamuto.com/{" "}
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Enter Unique URL"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon1"
                            name="url"
                            onChange={this.handleCollChange}
                          />
                        </InputGroup>
                        <div className="create-modal-collection-btn">
                          <button disabled={this.isDisabled()} type="submit">
                            Create Collection
                          </button>
                        </div>
                      </Form>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  error: state.error,
  nft: state.nft,
  collection: state.collection,
  category: state.category,
  settings: state.settings,
});
export default connect(mapStateToProps, {
  upsertCollection,
  beforeCollection,
  getCollections,
  upsertNFT,
  beforeNFT,
  beforeCategory,
  getCategories,
  getSettings,
  beforeSettings,
  getTokenSettings,
})(CreateNFT);
